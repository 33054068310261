import { Link } from "react-router-dom";
import { paths } from "../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faFolder
} from '@fortawesome/free-solid-svg-icons';
import {copyPropertyIdOrThrow, copyPropertyOrThrow} from "../../../services/formUtils";

export const getStoreTableColumns = (
  {onRemoveClick}
) => [
  {
      Header: 'CADENA DE TIENDAS',
      accessor: 'storeGroup.name'
  },
  {
      Header: 'NOMBRE',
      accessor: 'name'
  },
  {
      Header: 'DIRECCIÓN',
      accessor: 'address'
  },
  {
      Header: 'GERENTE/CONTACTO',
      accessor: 'manager'
  },
  {
      Header: 'NOMBRE DEL VISUAL',
      accessor: 'visualName'
  },
  {
    Header: 'TELÉFONO DEL VISUAL',
    accessor: 'visualPhone'
  },
  {
    Header: 'COSTO INSTALACIÓN',
    accessor: 'installationAmount'
  },
  {
    Header: 'ACTIVO',
    accessor: store =>
    <span>
      {store.active ? 'Si' : 'No'}
    </span>
  },
  {
      Header: 'ACCIONES',
      accessor: store => 
      <span
        className='ActionSee d-flex justify-content-center align-items-center' 
      >
        <Link
          to={ paths.storeMaterials.replace(":id", store.id) }
          className="m-2"
          title="Materiales"
        >
          <FontAwesomeIcon className="materials-link" icon={faFolder}/>
        </Link>
        <Link
          to={ paths.storeFormEdit.replace(":id", store.id) }
          className="m-2"
          title="Editar"
        >
          <FontAwesomeIcon icon={faEdit}/>
        </Link>
        <span
          onClick={() => {
            onRemoveClick(store.id)
          }} 
          className="m-2 error cursor-pointer"
          title="Eliminar"
        >
          <FontAwesomeIcon icon={faTrash}/>
        </span>
      </span>
  }
];

export const getStoreClientTableColumns = (
  {onRemoveClick}
) => [
  {
      Header: 'CADENA DE TIENDAS',
      accessor: 'storeGroup.name'
  },
  {
      Header: 'NOMBRE',
      accessor: 'name'
  },
  {
      Header: 'DIRECCIÓN',
      accessor: 'address'
  },
  {
      Header: 'GERENTE/CONTACTO',
      accessor: 'manager'
  },
  {
      Header: 'NOMBRE DEL VISUAL',
      accessor: 'visualName'
  },
  {
    Header: 'TELÉFONO DEL VISUAL',
    accessor: 'visualPhone'
  },
  {
    Header: 'ACTIVO',
    accessor: store =>
    <span>
      {store.active ? 'Si' : 'No'}
    </span>
  },
  {
      Header: 'ACCIONES',
      accessor: store => 
      <span
        className='ActionSee d-flex justify-content-center align-items-center' 
      >
        <Link
          to={ paths.storeMaterials.replace(":id", store.id) }
          className="m-2"
          title="Materiales"
        >
          <FontAwesomeIcon className="materials-link" icon={faFolder}/>
        </Link>
      </span>
  }
];

export const storeSGroups = [
  'store_read',
  'store_read_store_group',
  'store_group_read'
];

export const storeClientSGroups = [
  'store_read_id',
  'store_read_name',
  'store_read_address',
  'store_read_manager',
  'store_read_active',
  'store_read_visual_name',
  'store_read_visual_phone',
  'store_read_store_group',
  'store_group_read'
];

export const getStoreMaterialsTableColumns = (
  {onRemoveClick, onEdit, editingId}
) => [
  {
      Header: 'ID',
      accessor: 'id'
  },
  {
      Header: 'Material',
      accessor: material =>
        <span>
          {material.catMaterialType.name}    
        </span>
  },
  {
      Header: 'Descripción',
      accessor: material =>
        <span>
          {material.catMaterialType.description}
        </span>
  },
  {
      Header: 'Medidas',
      accessor: 'sizes'
  },
  {
    Header: 'Activo',
    accessor: material =>
    <span>
      {material.active ? 'Si' : 'No'}
    </span>
  },
  {
      Header: 'Acciones',
      accessor: material => 
      <span
        className='ActionSee d-flex justify-content-center align-items-center' 
      >
          <span
            onClick={() => {
              onRemoveClick(material.id)
            }} 
            className="m-2 error cursor-pointer"
          >
            <FontAwesomeIcon icon={faTrash}/>
          </span>
          <span
            onClick={() => {
              onEdit(material.id)
            }} 
            className={material.id === editingId ? 'm-2 cursor-pointer edit-icon-selected' : 'm-2 cursor-pointer'}
          >
            <FontAwesomeIcon icon={faEdit}/>
          </span>
      </span>
  }
];

export const storeMaterialSGroups = [
  'material_read',
  'material_read_cat_material_type',
  'cat_material_type_read',
  'material_read_store'
];

export const prepareStoreMaterialToServer = (form, active, materialType, store, materialEdit) => {
  const material = {};
  copyPropertyIdOrThrow( form, material, "materialType", "Por favor selecciona un tipo de material");
  copyPropertyOrThrow(form, material, 'sizes', 'Las medidas son requeridas');
  copyPropertyIdOrThrow( {active}, material, "active", "Por favor selecciona si está activo");
  if (active === '1') {
    material.active = true;
  } else {
    material.active = false;
  }
  material.store = store.id;
  material.catMaterialType = form.materialType;
  
  return material;
}