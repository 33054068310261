import React, {useEffect, useContext, useCallback, useState} from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getNotifier } from '../../../../services/notifier';
import { ApiContext } from '../../../../services/api/api-config';
import  useFormState from '../../../../hooks/useFormState';
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle';
import Card from '../../../../components/Dashboard/Card/Card';
import TextInput from '../../../../components/forms/TextInput/TextInput';
import Button from '../../../../components/molecules/Button/Button';
import { paths } from '../../../../services/routes/appRoutes';
import { campaignSGroups } from '../CampaignUtils';
import SearchBarDateFilter from '../../../../components/SearchBar/filters/SearchBarDateFilter/SearchBarDateFilter';
import "./CampaignForm.scss";
import Modal from '../../../../components/Modal/Modal';
import { SecurityContext } from '../../../../services/SecurityManager';

export default function CampaignForm() {
  const { id } = useParams();
  const api = useContext(ApiContext);
  const navigate = useNavigate();
  const { form, setForm, bindInput, bindSimple } = useFormState({});
  const [status, setStatus] = useState('')
  const [showModalContinue, setShowModalContinue] = useState(false);
  const [campaignId, setCampaignId] = useState(0);
  const loadingId = `Grower.form.${id}`;
  const security = useContext(SecurityContext);
  const [invoiced, setInvoiced] = useState(false)

  const me = useSelector(({api}) => api.me);

  const onClientRole = security.role === 'ROLE_CLIENT';

  useEffect(() => {
    if (id) {
      api.campaigns.get(
        {
          id,
          params: {sGroups: campaignSGroups}
        }
      )
        .then(async(campaign) => {
          setForm( campaign );
          setStatus(campaign.status);
          setInvoiced(campaign.invoiced ? '1' : '0');
        })
        .catch(error => getNotifier().error(error.detail || error.message));
    }
  }, [api, id, setForm]);

  /**
   * Handle create or update material status
   */
  const handleCreateOrUpdateCampaign = useCallback(() => {
    const {id} = form;
    const method = id ? 'update' : 'create';
    let campaign = null;

    try {
      campaign = form;
      campaign.status = status;
      campaign.user = me.id;
      campaign.invoiced = invoiced === '1' ? true : false;
    } catch (error) {
      return getNotifier().error(error.message || error.detail);            
    }
    
    api.campaigns[method]({ id, params: campaign })
      .then(campaign => {
        getNotifier().success(
          id ? 'Actualizado correctamente' : 'Agregado correctamente'
        );
        setCampaignId(campaign.id);
        setShowModalContinue(true);
      }
    )
      .catch(error => getNotifier().error(error.message || error.detail));
  }, [api.campaigns, form, invoiced, me.id, status]);

  /**
   * Handle change status
   */
  const handleChange = useCallback((value) => {
    setStatus(value);
  }, []);

  const handleBack = useCallback(() => navigate(paths.campaign), [navigate]);

  const handleContinue = useCallback(() => {
    setShowModalContinue(false);
    navigate(paths.campaignDetails.replace(":id", campaignId));
  }, [campaignId, navigate]);

  const loading = useSelector(s => !!s.loadingIds[loadingId]);

  return (
    <div className="CampaignForm dashboard-container">
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <SectionTitle>
            { !id ? 'AGREGAR CAMPAÑA' : 'EDITAR CAMPAÑA' }
          </SectionTitle>
          <div className='row'>
            <Card className='OrderStep1'>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Nombre"
                    placeholder=""
                    {...bindInput('name')}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-6 margin-top-mobile'>
                  <SearchBarDateFilter
                    className="mt-0"
                    label="Fecha de publicación"
                    name='date'
                    {...bindSimple('publishDate')}
                  />
                </div>
                <div className='col-6 margin-top-mobile'>
                  <select
                    className="form-select"
                    value={status || ''}
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder="seleccionar"
                  >
                    <option
                      className='font-bold'
                      key={0}
                      value=""
                    >
                      Seleccionar Estatus
                    </option>
                    <option
                      className='font-bold'
                      key={1}
                      value="Campaña programada"
                    >
                      Campaña programada
                    </option>
                    <option
                      className='font-bold'
                      key={2}
                      value="Campaña en proceso de propuesta creativa"
                    >
                      Campaña en proceso de propuesta creativa
                    </option>
                    <option
                      className='font-bold'
                      key={3}
                      value="Campaña en revisión por parte del cliente"
                    >
                      Campaña en revisión por parte del cliente
                    </option>
                    <option
                      className='font-bold'
                      key={4}
                      value="Campaña con presupuesto aprobado"
                    >
                      Campaña con presupuesto aprobado
                    </option>
                    <option
                      className='font-bold'
                      key={5}
                      value="Campaña en producción"
                    >
                      Campaña en producción
                    </option>
                    <option
                      className='font-bold'
                      key={6}
                      value="Campaña en proceso de cierre"
                    >
                      Campaña en proceso de cierre
                    </option>
                    {/* <option
                      className='font-bold'
                      key={7}
                      value="Campaña pendiente de folio"
                    >
                      Campaña pendiente de folio
                    </option>
                    <option
                      className='font-bold'
                      key={8}
                      value="Campaña facturada"
                      {...bindInput('name')}
                    >
                      Campaña facturada
                    </option> */}
                  </select>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Master"
                    placeholder=""
                    {...bindInput('master')}
                  />
                </div>
              </div>
              {
                !onClientRole &&
                <div>
                  <label htmlFor="">
                    Estatus financiero
                  </label>
                  <select
                    value={invoiced}
                    onChange={(e) => setInvoiced(e.target.value)}
                    className="form-select"
                  >
                    <option value="0">
                      Campaña pendiente de folio
                    </option>
                    <option value="1">
                      campaña facturada
                    </option>
                  </select>
                </div>
              }
              <div className='d-flex justify-content-center align-items-center buttons-container'>
                <Button
                  className={'btn-primary btn-outline'}
                  onClick={() => window.location.href = paths.campaign }
                  design={'clear'}
                >
                  Cancelar
                </Button>
                <Button 
                  className={`btn-primary btnNextStep`}
                  onClick={handleCreateOrUpdateCampaign}
                  disabled={loading}
                >
                  { id ? 'Actualizar' : 'Agregar' }
                </Button>
              </div>
            </Card>
          </div>
        </div>
        {
          showModalContinue &&
          <Modal
            title="Continuar a los detalles de la campaña"
            mainButtonText="Continuar"
            mainButtonColor='success'
            mainButtonAction={handleContinue}
            secondaryButtonText={'Más tarde'}
            secondaryButtonAction={() => {
                setShowModalContinue(false);
                handleBack();
            }}
            onClose={() => {
              setShowModalContinue(false);
              handleBack();
            }}
          >
            <div className='GrowerChangeStatusButtons__modal-content'>
              <div className='GrowerChangeStatusButtons__modal-content-title'>
                ¿Quieres seleccionar las tiendas y materiales de la campaña?
              </div>
            </div>
          </Modal>
        }
      </div>
    </div>
  )
}
