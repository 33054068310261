import React, { useContext, useCallback, useState } from 'react';
import "./ForgotPassword.scss";
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {paths} from "../../../services/routes/notLoggedRoutes";
import {Link} from "react-router-dom";
import HalfScreenLayout from "../../../components/layout/HalfScreenLayout/HalfScreenLayout";
import background from './../../../assets/images/backgrounds/Intersectlogin.jpg';
import {useTranslation} from "react-i18next";
import TextInput from "../../../components/forms/TextInput/TextInput";
import Button from "../../../components/molecules/Button/Button";
import logoPumaMostacho from '../../../assets/images/logos/logo_puma_mstch.png';

const ForgotPassword = () => {

  const api = useContext(ApiContext);
  const {t} = useTranslation();

  const {form, handleInputChange} = useFormState({});
  const [showMessage, setShowMessage] = useState();

  const sendRequest = useCallback((e) => {
      e.preventDefault();
      if (!form.email) {
        return;
      }
          
      api.users.passwordRecovery({params: {email: form.email}}).then(() => {
        setShowMessage('Te llegará un correo para recuperar tu contraseña al email que nos indicaste.');
      }).catch(() => {
        setShowMessage('El correo que ingresaste no es válido');
      });
  },[api, form]);

  return (
    <HalfScreenLayout
        className='ForgotPassword'
        rightBackground={background}
    >
      {
        [
          <div className='form-container text-center'>
            <div className='row'>
              <div>
                <img src={logoPumaMostacho} alt="" className='logo-img'/>
              </div>
            </div>
            {
              showMessage && 
              <div className="registerResponse mb-4 text-success">
                <h4>
                  {showMessage}
                </h4>
              </div>
            }
            <div className="login-signin">
              <div className="mb-20 text-center">
                <h1 className='title'>
                  Genera una nueva contraseña
                </h1>
                <div className="subtitle">
                  Ingresa tu correo, te enviaremos un enlace para reestablecer tu contraseña
                </div>
              </div>
              <form
                className="form"
                onSubmit={sendRequest}
              >
                <div className="text-left">
                  <TextInput
                    label={ t("Email") }
                    placeholder={ t("Enter-email-here") }
                    onChange={handleInputChange('email')}
                    value={form.email || ''} 
                    className={'TextInput--small'}
                    autoComplete="off"
                  />
                  <div className="text-right">
                    <Link
                      to={paths.login}
                      className='forgot-section'
                    >
                      Iniciar sesión
                    </Link>
                  </div>
                </div>
                <div className='but-container'>
                  <Button
                    className={'submit'}
                    type='submit'
                    disabled={!form.email}
                    design="secondary"
                  >
                    Enviar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ]
      }
    </HalfScreenLayout>
  );
}
 
export default ForgotPassword;