import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ApiContext } from '../../../../../../services/api/api-config';
import { getNotifier } from '../../../../../../services/notifier';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faClose,
    faTrash,
    faEye,
    faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import "./StoresByGroup.scss"
import StoreMaterialList from '../StoreMaterialList/StoreMaterialList';
import Modal from '../../../../../../components/Modal/Modal';
import Button from '../../../../../../components/molecules/Button/Button';
import TideEntitySelect from '../../../../../../components/utility/TideEntitySelect/TideEntitySelect';
import _ from "lodash";
import { SecurityContext } from '../../../../../../services/SecurityManager';

export default function StoresByGroup({storeGroup, hasDetails, campaignId, removeStoreGroup}) {
  const [stores, setStores] = useState([])
  const [showDetails, setShowDetails] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [first, setFirst] = useState(true)

  const api = useContext(ApiContext);
  const security = useContext(SecurityContext);

  // ROLE_VENDOR
  const onVendorRole = security.isVendor();
  const onClientRole = security.isClient();

  /**
   * Get stores by store group
   */
  const getStoresByGroup = useCallback(() => {
    if (!hasDetails) {
      api.stores.get(
        {
          params: {
            "storeGroup.id": storeGroup.id,
            active: true,
            sGroups: [
              'store_read',
              'store_read_store_group',
              'store_group_read',
              'store_read_materials',
              'material_read'
            ]
          }
        }
      ).then(responseStores => {
        const storeList = [];
        responseStores.forEach(element => {
          let materialList = [];
          const newStore = {
            id: element.id,
            name: element.name,
            storeGroup: element.storeGroup,
            store: element,
            selected: true,
            materials: materialList,
            datetime: new Date().getTime(),
            show: false,
            storeMaterials: element.store ? element.store.materials.length : 0
          };
          storeList.push(
            newStore
          );
        });
        setStores(storeList);
      })
      .catch(
        error => getNotifier().error(error.detail || error.message)
      );  
    } else {
      api.campaignDetails.get({
        params: {
          'campaign.id': campaignId,
          'store.storeGroup.id': storeGroup.id,
          sGroups: [
            'campaign_detail_read',
            'campaign_detail_read_store',
            'store_read',
            'store_read_store_group',
            'store_group_read',
            'store_read_materials',
            'material_read'
          ]
        }
      }).then(responseDetails => {
        let storesList = [];
        responseDetails.forEach(detail => {
          storesList.push(
            {
              id: detail.store.id,
              name: detail.store.name,
              storeGroup: detail.store.storeGroup,
              store: detail.store,
              selected: true,
              materials: detail.materials,
              datetime: new Date().getTime(),
              show: false,
              storeMaterials: detail.store.materials.length
            }
          );
        });
        setStores(storesList);
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.campaignDetails, api.stores, campaignId, hasDetails, storeGroup.id]);

  /**
   * Handle click arrow to show or hide details
   */
  const handleClickArrow = useCallback(() => {
    setShowDetails(showDetails ? false: true);
  }, [showDetails]);

  /**
   * Delete store in campaign
   */
  const deleteStoreInCampaign = useCallback(() => {
    api.campaignDetails.get({
      params: {
        'campaign.id': campaignId,
        'store.id': idToDelete
      }
    }).then(responseDetails => {
      if (responseDetails.length > 0 ) {
        api.campaignDetails.delete({id: responseDetails[0].id}).then(() => {
          getNotifier().success('Tienda eliminada correctamente');
        }).catch(
          error => getNotifier().error(error.detail || error.message)
        );
      }
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
    setIdToDelete(0);
  }, [api.campaignDetails, campaignId, idToDelete]);

  /**
   * Remove Store in list
   */
  const removeStore = useCallback(() => {
    let currentStores = [...stores];
    const theIndex = currentStores.findIndex(theStore => theStore.id === idToDelete );
    currentStores.splice(theIndex, 1);
    setStores(currentStores);
    deleteStoreInCampaign();
  }, [deleteStoreInCampaign, idToDelete, stores]);

  /**
   * Handle remove store click
   */
  const handleRemoveStore = useCallback((storeId) => {
    setIdToDelete(storeId);
  }, []);

  /**
   * Handle select store
   */
  const handleSelectStore = useCallback((value) => {
    let currentStores = [...stores];
    const find = currentStores.find((theStore) => theStore.id === value.id);
    if (!find) {
      currentStores.push({
        id: value.id,
        name: value.name,
        selected: true,
        store: value,
        storeGroup: storeGroup,
        datetime: new Date().getDate(),
        materials: []
      });
      setStores(currentStores)
    }
  }, [storeGroup, stores]);

  const handleStoreShow = useCallback((storeId) => {
    let theStores = [...stores];
    const theIndex = theStores.findIndex(element => element.id === storeId );
    theStores[theIndex].show = !theStores[theIndex].show;
    setStores(theStores);
  }, [stores]);

  const handleAddMaterial = useCallback((store, materials) => {
    let theStores = [...stores];
    const theIndex = theStores.findIndex(element => element.id === store );
    theStores[theIndex].materials = materials;
    setStores(theStores);
  }, [stores]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getStoresByGroup();
    }
  }, [first, getStoresByGroup, hasDetails, storeGroup, stores, stores.length])
  
  return (
    <div className='StoresByGroup'>
      <div className={showDetails ? 'd-flex justify-content-between active' : 'd-flex justify-content-between'}>
        <div className={showDetails ? 'mt-2' : ''}>
          {storeGroup.name}
        </div>
        {
          !showDetails &&
          <div>
            {stores.length} Tiendas
          </div>
        }
        {
          showDetails &&
          <div>
            <TideEntitySelect
              entity='stores'
              className='stores-select'
              placeholder='Agregar Tienda'
              preload
              onChange={handleSelectStore}
              autoSelect={true}
              filterBy="search"
              additionalFilters={{'storeGroup.id': storeGroup.id}}
            />
          </div>
        }
        <div className='d-flex justify-content-between'>
          <div className='mr-4'>
            {
              !onVendorRole && !onClientRole &&
              <span className={showDetails ? 'trash-icon trash-icon-active' : 'trash-icon trash-icon-inactive'}>
                <FontAwesomeIcon
                  icon={faTrash}
                  className="cursor-pointer"
                  onClick={() => removeStoreGroup(storeGroup)}
                />
              </span>
            }
          </div>
          <div
            className='arrow-icon cursor-pointer'
            onClick={handleClickArrow}
          >
            {
              !showDetails &&
              <FontAwesomeIcon
                icon={faArrowDown}
                className="info-icon "
              />
            }
            {
              showDetails &&
              <FontAwesomeIcon
                icon={faArrowUp}
                className="info-icon cursor-pointer mr-2"
              />
            }
          </div>
        </div>
      </div>
      {
        showDetails &&
        <div className='row'>
          {
            _.map(stores, (store) => (
              <div
                className="col-12 col-lg-4 gap-4 mt-2"
                key={store.id}
              >
                <div className='store-card'>
                  <div className='w-full d-flex justify-content-between'>
                    <div className='d-flex flex-row'>
                      <div>
                        <FontAwesomeIcon
                          icon={faClose}
                          className='menu-icon-close cursor-pointer'
                          onClick={() => handleRemoveStore(store.id)}
                        />
                      </div>
                      <div className='d-flex flex-column'>
                        <div>
                          <label className={store.selected ? "store-name store-selected" : "store-name"} htmlFor={store.id}>
                            {store.name}
                          </label>
                        </div>
                        <div className='materials-info'>
                          {store.materials.length} Materiales seleccionados de {store.storeMaterials}
                        </div>
                      </div>
                    </div>
                    {
                      store.show &&
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        className='menu-icon cursor-pointer float-right'
                        onClick={() => handleStoreShow(store.id)}
                      />
                    }
                    {
                      !store.show &&
                      <FontAwesomeIcon
                        icon={faEye}
                        className='menu-icon cursor-pointer float-right'
                        onClick={() => handleStoreShow(store.id)}
                      />
                    }
                  </div>
                  
                  {
                    store.show &&
                    <StoreMaterialList
                      storeId={store.id}
                      materialsList={store.materials}
                      load={store.datetime}
                      hasDetails={hasDetails}
                      campaignId={campaignId}
                      handleAddMaterial={handleAddMaterial}
                    ></StoreMaterialList>
                  }
                </div>
              </div>
            ) )
          }
        </div>
      }
      {
        idToDelete > 0 &&
        <Modal
          title="Eliminar Tienda"
          onClose={() => setIdToDelete(0)}
        >
          <div className='GrowerChangeStatusButtons__modal-content'>
            <div className='GrowerChangeStatusButtons__modal-content-title'>
              ¿Está seguro de Eliminar la Tienda?
              <div className='mt-4'>
                <Button 
                  className={`btn-primary btnNextStep`}
                  onClick={removeStore}
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </div>
  )
}
