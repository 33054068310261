import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Card from '../../../../components/Dashboard/Card/Card'
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle'
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu'
import TopBar from '../../../../components/Dashboard/TopBar/TopBar'
import { SearchTextbox } from '../../../../components/forms/SearchTextbox/SearchTextbox'
import Modal from '../../../../components/Modal/Modal'
import TideReactTable from '../../../../components/utility/TideReactTable/TideReactTable'
import useTideTable from '../../../../components/utility/TideReactTable/useTideTable'
import useFormState from '../../../../hooks/useFormState'
import "./VendorWorkOrder.scss"
import { getVendorWorkOrderColumns, vendorWorkOrderGroups } from './VendorWorkOrderUtils'
import MaterialsTable from './components/MaterialsTable/MaterialsTable'
import Button from '../../../../components/molecules/Button/Button'
import { ApiContext } from '../../../../services/api/api-config';

export default function VendorWorkOrder() {

  const {form, setForm} = useFormState({name: ''});
  const [modalMaterials, setModalMaterials] = useState(null)

  const me = useSelector(({api}) => api.me);
  const api = useContext(ApiContext);

  /**
   * Request filters
   */
  const requestFilters = useMemo(() => ({
    sGroups: vendorWorkOrderGroups,
    search: form.name,
    'catVendor.id': me.vendor.id,
    'order[id]': 'desc'
  }), [form.name, me.vendor.id]);

  const showMaterials = useCallback((vendorWorkOrder) => {
    setModalMaterials(vendorWorkOrder);
  }, []);

  const reportLink = (vendorWorkOrder) => {
    return api.vendorWorkOrders.getReport({id: vendorWorkOrder.campaign.id});
  };

  const vendorWorkOrderColumns = useMemo(() => getVendorWorkOrderColumns({showMaterials}), [showMaterials]);

  /**
   * Create campaigns tide table
   */
  const table = useTideTable({
    entity: 'vendorWorkOrders',
    columns: vendorWorkOrderColumns,
    requestFilters,
  });

  return (
    <div className='VendorWorkOrder'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              ÓRDENES DE TRABAJO
            </SectionTitle>
          </div>
          <div className="row mt-3">
            <Card>
              <div className='d-flex justify-content-between align-items-center gap-3 mt-3 mb-3'>
                <SearchTextbox
                  placeholder="Búsqueda"
                  value={form.name}
                  onChange={e => setForm({name: e.target.value})}
                />
              </div>
              <div>
                {
                  table.tableProps &&
                  <TideReactTable 
                    {...table.tableProps}
                    className="users-table"
                  />
                }
              </div>
              {
                modalMaterials &&
                <Modal
                  title="Materiales a instalar"
                  onClose={() => setModalMaterials(null)}
                  large={true}
                  className="table"
                >
                  <div className='GrowerChangeStatusButtons__modal-content'>
                    <div className='GrowerChangeStatusButtons__modal-content-title'>
                      <MaterialsTable
                        vendorWorkOrder={modalMaterials}
                      />
                    </div>
                  </div>
                    <a
                      href={reportLink(modalMaterials)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        type='button'
                        id="generate-report"
                      >
                        Generar Reporte
                      </Button>
                    </a>
                </Modal>
              }
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
