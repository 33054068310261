import React, { useCallback, useContext, useEffect, useState } from 'react'
import MultipleFileUpload, { PreviewImage } from '../../../../../../components/forms/MultipleFileUpload/MultipleFileUpload';
import Button from '../../../../../../components/molecules/Button/Button';
import useFormState from '../../../../../../hooks/useFormState';
import { ApiContext } from '../../../../../../services/api/api-config';
import { getNotifier } from '../../../../../../services/notifier';
import { evidenceMaterialStatus } from '../../../../Evidences/EvidenceStoreUtils';
import _ from "lodash";
import "./MaterialEvidences.scss"
import TextInput from '../../../../../../components/forms/TextInput/TextInput';
import moment from 'moment';
import { generalDatesHoursFormat } from '../../../../../../services/generalConfig';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload
} from '@fortawesome/free-solid-svg-icons';

const imageFileTypes = ['JPG', 'PNG', 'GIF'];
const maxFileSizeInMB = 50;

export default function MaterialEvidences(
  {vendorWorkOrder, materialSelected, onClose}
) {

  const api = useContext(ApiContext);
  const [first, setFirst] = useState(true)
  const { form, setForm, bindInput} = useFormState({pictures: []});
  const [arrayPictures, setArrayPictures] = useState([]);
  const [currentPictures, setCurrentPictures] = useState([]);

  const getEvidences = useCallback(() => {
    api.evidence.get({
      params: {
        'campaign.id': vendorWorkOrder.campaign.id,
        'store.id': vendorWorkOrder.store.id,
        'material.id': materialSelected.id,
        sGroups: [
          'evidence_read',
          'evidence_read_app_file',
          'app_file_read',
          'read_created_date'
        ]
      }
    }).then(response => {
      setCurrentPictures(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.evidence, materialSelected.id, vendorWorkOrder.campaign.id, vendorWorkOrder.store.id]);

  /**
   * Handle on change pictures
   * 
   */
  const onChangePictures = useCallback((newFiles) => {
    setForm({...form, pictures: newFiles});
    setArrayPictures(newFiles);
  }, [form, setForm]);

  /**
   * Delete added file
   * 
   */
  const onDelete = useCallback((index) => {
    const newPictures = arrayPictures.filter((picture, i) => i !== index);
    setForm({...form, pictures: newPictures});
    setArrayPictures(newPictures);
  }, [arrayPictures, form, setForm]);

  /**
   * Update material status
   */
  const updateMaterialStatus = useCallback((materialId, status) => {
    api.materials.update({
      id: materialId,
      params: {
        status
      }
    }).then(response => {
      getNotifier().success('Agregado correctamente');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.materials]);

  /**
   * Upload files added
   */
  const uploadFiles = useCallback((file) => {
    api.evidence.create({
      params: {
        campaign: vendorWorkOrder.campaign.id,
        store: vendorWorkOrder.store.id,
        material: materialSelected.id,
        status: form.status,
        comments: form.comments
      },
      files: file
    }).then(response => {
      updateMaterialStatus(materialSelected.id, form.status);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.evidence, form.comments, form.status, materialSelected.id, updateMaterialStatus, vendorWorkOrder.campaign.id, vendorWorkOrder.store.id]);

  /**
   * handle save files
   */
  const saveFiles = useCallback(() => {
    if (form.pictures.length === 0) {
      getNotifier().error('No ha agregado ningún archivo');
      return;
    }
    for (const picture of form.pictures) {
      uploadFiles(picture);
    }
    onClose();
  }, [form.pictures, onClose, uploadFiles]);

  /**
   * Handle fields change
   */
  const handleChange = useCallback((value) => {
    setForm({...form, status: value});
  }, [form, setForm]);

  function downloadFile(fileId, originalName) {
    const downloadUrl = api.appFiles.getDownloadUrl(
      {id: fileId}
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', originalName);
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    if (first) {
      setFirst(false);
      getEvidences();
    }
  }, [first, getEvidences])
  
  return (
    <div className='MaterialEvidences'>
      <div className='row'>
        <div className='col-12'>
          <div>
            <div>Tipos de archivos: {imageFileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}</div>
            <div>Tamaño máximo: {maxFileSizeInMB} MB</div>
          </div>
          <MultipleFileUpload
            fileTypes={imageFileTypes}
            multiple={true}
            legend={'Images'}
            onChange={files => onChangePictures(files)}
            files={form.pictures}
            maxFileSizeInMB={maxFileSizeInMB}
          />
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col margin-top-mobile'>
          <select
            className="form-select"
            value={form.status || ''}
            onChange={(e) => handleChange(e.target.value, 'status')}
            placeholder="seleccionar"
          >
            <option
              className='font-bold'
              key={0}
              value=""
            >
              Seleccionar Estatus
            </option>
            {
              _.map(evidenceMaterialStatus, (status, index) => (
                <option
                  value={status}
                  key={index}
                >
                  {status}
                </option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col margin-top-mobile">
          <TextInput
            label="Comentarios"
            placeholder=""
            {...bindInput('comments')}
          />
        </div>
      </div>

      {
        currentPictures.map((picture) => 
          <div
            className='d-flex gap-4 comment-row'
            key={picture.id}
          >
            <div
              className='image-preview col-3'
              key={picture.appFile.id}
            >
              <img 
                src={api.appFiles.getUrl({id: picture.appFile?.id})} 
                width="100"
                alt={picture.appFile ? picture.appFile.name : ''}
              />
            </div>
            <div className='col-6'>
              {picture.comments}
            </div>
            <div className='col-2'>
              {moment(picture.createdDate).format( generalDatesHoursFormat )}
            </div>
            <div className='col-1'>
              <FontAwesomeIcon
                icon={faDownload}
                className='cursor-pointer'
                onClick={() => downloadFile(picture.appFile.id, picture.appFile.originalName)}
              />
            </div>
          </div>
      )}
      <div className="row">
        <div className='col-12'>
          {arrayPictures.map((picture, index) => 
            picture.id
            ? ''
            : <PreviewImage key={index} picture={picture} onDelete={() => onDelete(index)} />
          )}
        </div>
      </div>
      <div className='row'>
        {
          arrayPictures.length > 0 && form.status &&
          <Button
            className={`btn-primary btnNextStep`}
            onClick={saveFiles}
          >
            Guardar
          </Button>
        }
      </div>
    </div>
  )
}
