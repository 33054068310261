import React, {useEffect, useContext, useCallback, useState} from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getNotifier } from '../../../../services/notifier';
import { ApiContext } from '../../../../services/api/api-config';
import  useFormState from '../../../../hooks/useFormState';
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle';
import Card from '../../../../components/Dashboard/Card/Card';
import TextInput from '../../../../components/forms/TextInput/TextInput';
import Button from '../../../../components/molecules/Button/Button';
import { paths } from '../../../../services/routes/appRoutes';
import { storeSGroups } from '../StoreUtils';
import _ from 'lodash';
import "./StoreForm.scss";

export default function StoreForm() {
  const { id } = useParams();
  const api = useContext(ApiContext);
  const navigate = useNavigate();
  const { form, setForm, bindInput } = useFormState({});
  const [active, setActive] = useState('');
  const [storeGroups, setStoreGroups] = useState([])
  const [storeGroupSelected, setStoreGroupSelected] = useState('')
  const loadingId = `Grower.form.${id}`;

  const getStoreGroups = useCallback(() => {
    api.storeGroups.get().then(storeGroupsResponse => {
      setStoreGroups(storeGroupsResponse);
    } ).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.storeGroups]);


  useEffect(() => {
    if (storeGroups.length === 0) {
      getStoreGroups();
    }
    if (id && !form.id) {
      api.stores.get(
        {
          id,
          params: {sGroups: storeSGroups}
        }
      )
        .then(async(store) => {
          //fields are nullable, make sure the null value is not used on edit
          store.visualName = store.visualName ?? '';
          store.visualPhone = store.visualPhone ?? '';
          setForm( store );
          setActive(
            store.active ? '1' : '0'
          );
          setStoreGroupSelected(store.storeGroup ? store.storeGroup.id : '');
        })
        .catch(error => getNotifier().error(error.detail || error.message));
    }
  }, [api, form.id, getStoreGroups, id, setForm, storeGroups]);

  /**
   * Handle create or update material status
   */
  const handleCreateOrUpdateStore = useCallback(() => {
    const {id} = form;
    const method = id ? 'update' : 'create';
    let store = null;

    try {
      store = form;
      store.active = active === '1' ? true : false;
      store.storeGroup = storeGroupSelected;
    } catch (error) {
      return getNotifier().error(error.message || error.detail);            
    }
    
    api.stores[method]({ id, params: store })
      .then(store => {
        getNotifier().success(
          id ? 'Actualizado correctamente' : 'Agregado correctamente'
        );
        navigate(paths.store);
      }
    )
      .catch(error => getNotifier().error(error.message || error.detail));
  }, [active, api.stores, form, navigate, storeGroupSelected]);

  /**
   * Handle change active
   */
  const handleChange = useCallback((value) => {
    setActive(value);
  }, []);

  /**
   * Handle change store group
   */
  const handleChangeStoreGroup = useCallback((value) => {
    setStoreGroupSelected(value);
  }, []);

  const handleChangeAmount = useCallback((event, name) => {
    const value = event.target.value;
    setForm({...form, [name]: value.slice(0, 8)});
  }, [form, setForm]);

  const loading = useSelector(s => !!s.loadingIds[loadingId]);

  return (
    <div className="StoreForm dashboard-container">
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <SectionTitle>
            { !id ? 'AGREGAR TIENDA' : 'EDITAR TIENDA' }
          </SectionTitle>
          <div className='row'>
            <Card className='OrderStep1'><div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <select
                    className='form-select'
                    value={storeGroupSelected}
                    onChange={(e) => handleChangeStoreGroup(e.target.value)}
                  >
                    <option value="">Cadena de Tiendas</option>
                    {_.map(storeGroups, (storeGroup) => (
                      <option
                        value={storeGroup.id}
                        key={storeGroup.id}
                      >
                        {storeGroup.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Nombre"
                    placeholder=""
                    {...bindInput('name')}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Dirección"
                    placeholder=""
                    {...bindInput('address')}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Contacto"
                    placeholder=""
                    {...bindInput('manager')}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-4 margin-top-mobile'>
                  <TextInput
                    label="Nombre del visual"
                    placeholder=""
                    {...bindInput('visualName')}
                  />
                </div>
                <div className='col-4 margin-top-mobile'>
                  <TextInput
                    label="Teléfono del visual"
                    placeholder=""
                    {...bindInput('visualPhone')}
                  />
                </div>
                <div className='col-4 margin-top-mobile'>
                  <select
                    className="form-select"
                    value={active || ''}
                    onChange={(e) => handleChange(e.target.value)}
                    placeholder="seleccionar"
                  >
                    <option
                        className='font-bold'
                        key={0}
                        value=""
                    >
                        Estatus
                    </option>
                    <option
                        className='font-bold'
                        key={1}
                        value="1"
                    >
                        Activo
                    </option>
                    <option
                        className='font-bold'
                        key={2}
                        value="0"
                    >
                        Inactivo
                    </option>
                  </select>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-4 margin-top-mobile'>
                    <TextInput
                      label="Costo de Instalación"
                      placeholder=""
                      type="number"
                      // {...bindInput('installationAmount')}
                      onChange={(e) => handleChangeAmount(e, 'installationAmount')}
                      value={form.installationAmount || ''}
                    />
                  </div>
              </div>
                      
              <div className='d-flex justify-content-center align-items-center buttons-container'>
                <Button
                  className={'btn-primary btn-outline'}
                  onClick={() => window.location.href = paths.store }
                  design={'clear'}
                >
                  Cancelar
                </Button>
                <Button 
                  className={`btn-primary btnNextStep`}
                  onClick={handleCreateOrUpdateStore}
                  disabled={loading}
                >
                  { id ? 'Actualizar' : 'Agregar' }
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
