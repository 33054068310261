import React from 'react'
import { ADMIN_ROLE_ID } from '../../../../../services/models/userUtils';
import {ReactComponent as RoleAdminIcon} from "../../../../../assets/images/icons/role-admin.svg";
import './UserAvatar.scss';
import { useCallback } from 'react';

const UserAvatar = ({ user, onEdit }) => {
    const isAdmin = user.role?.id === ADMIN_ROLE_ID;

    const handleOnEdit = useCallback(() => {
        if (user.role && user.role.name !== 'SUPER_ADMIN') {
            onEdit(user);
        }
    }, [onEdit, user]);

    return (
        <div className='UserAvatar' onClick={handleOnEdit}>
            <div
                className={
                    [
                        'UserAvatar__avatar-image UserAvatar__avatar-image--empty',
                        user.role && user.role.name !== 'SUPER_ADMIN' ? 'cursor-pointer' : 'cursor-not-allowed'
                    ].join(' ')
                }
            >
                <span>
                    {user.username[0]}
                </span>
            </div>
            <div
                className={
                    [
                        'UserAvatar__user-name',
                        user.role && user.role.name !== 'SUPER_ADMIN' ? 'cursor-pointer' : 'cursor-not-allowed'
                    ].join(' ')
                }
            >
                <div className='UserAvatar__name-and-role'>
                    { user.fullName }
                    {isAdmin && <RoleAdminIcon />}
                </div>
            </div>
        </div>
    );
}

export default UserAvatar