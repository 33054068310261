import React, {useMemo, useCallback, useState } from 'react';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import Button from '../../../components/molecules/Button/Button';
import Card from '../../../components/Dashboard/Card/Card';
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import {ReactComponent as RoleAdminIcon} from "../../../assets/images/icons/role-admin.svg";
import useFormState from '../../../hooks/useFormState';
import { getUsersTableColumns, usersWithPermissionsGSGroups } from './UsersUtils';
import "./Users.scss";
import UsersForm from './UsersForm/UsersForm';

export default function Users() {
  const [showingModalUser, setShowingModalUser] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const {form, setForm} = useFormState({});

  const customProp = "UsersList";

  /**
   * Handle new user click
   */
  const handleNewUser = useCallback(() => setShowingModalUser(true), []);

  /**
   * Show modal for edit
   */
  const onEdit = useCallback((user) => {
    setSelectedUser(user);
    setShowingModalUser(true);
  }, []);

  /**
   * Get users table columns
   */
  const usersTableColumns = useMemo(() => getUsersTableColumns({onEdit}), [onEdit]);

  /**
   * Request filters
   */
  const requestFilters = useMemo(() => ({
    sGroups: usersWithPermissionsGSGroups,
    search: form.email
  }), [form.email]);

  /**
   * Request options
   */
  const requestOptions = useMemo(() => ({ customProp }), []);

  /**
   * Create users tide table
   */
  const table = useTideTable({
    entity: 'users',
    columns: usersTableColumns,
    requestFilters,
    requestOptions,
  });

  /**
   * Handle onClose modal
   */
  const onClose = useCallback(() => {
    setShowingModalUser(false);
    setSelectedUser(null);
  }, [setShowingModalUser, setSelectedUser]);

  /**
   * Handle onSuccess create or update
   */
  const onSuccess = useCallback(() => {
    onClose();
    table.reload();
  }, [onClose, table]);

  return (
    <div className='Users'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              USUARIOS
            </SectionTitle>
            <Button 
              className={'submit btnNewProducer'} 
              type='button' 
              onClick={handleNewUser}
              id="add-new-user"
            >
              + Agregar Usuario
            </Button>
          </div>

          <div className="row mt-3">
            <Card>
              <div className='role-with-icon'>
                <span className='bold d-flex justify-content-center align-items-center gap-3'>
                    Usuario Administrador <RoleAdminIcon />
                </span>
              </div>
              <div className='d-flex justify-content-between align-items-center gap-3 mt-3 mb-3'>
                <SearchTextbox
                    placeholder="Búsqueda"
                    value={form.email}
                    onChange={e => setForm({email: e.target.value})}
                />
              </div>
              <TideReactTable 
                  {...table.tableProps}
                  className="users-table"
              />
            </Card>
          </div>
          {
            showingModalUser &&
            <UsersForm
              onClose={onClose}
              onSuccess={onSuccess}
              selectedUser={selectedUser}
            />
          }
        </div>
      </div>
    </div>
  )
}
