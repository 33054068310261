import React from 'react'
import loader from "../../assets/images/loader.gif"
import './Loader.scss';

const Loader = () => {
    return (
        <div className='Loader'>
            <img src={loader} alt="loader" className='loader-image'/>
        </div>
    );
}

export default Loader