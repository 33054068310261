import React, {useEffect, useContext, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getNotifier } from '../../../../../services/notifier';
import { ApiContext } from '../../../../../services/api/api-config';
import  useFormState from '../../../../../hooks/useFormState';
import { SideMenu } from '../../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import Card from '../../../../../components/Dashboard/Card/Card';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import Button from '../../../../../components/molecules/Button/Button';
import { paths } from '../../../../../services/routes/appRoutes';
import { catVendorTableSGroups } from '../CatVendorUtils';
import "./CatVendorForm.scss";

export default function CatVendorForm() {
  const { id } = useParams();
  const api = useContext(ApiContext);
  const navigate = useNavigate();
  const { form, setForm, bindInput } = useFormState({});
  const loadingId = `Grower.form.${id}`;

  useEffect(() => {
    if (id) {
      api.catVendors.get(
        {
          id,
          params: {sGroups: catVendorTableSGroups}
        }
      )
        .then(async(vendor) => {
          setForm( vendor );
        })
        .catch(error => getNotifier().error(error.detail || error.message));
    }
  }, [api, id, setForm]);

  /**
   * Handle create or update material type
   */
  const handleCreateOrUpdateMaterialType = useCallback(() => {
    const {id} = form;
    const method = id ? 'update' : 'create';
    let vendor = null;

    try {
      vendor = form;
    } catch (error) {
      return getNotifier().error(error.message||error.detail);            
    }
    
    api.catVendors[method]({ id, params: vendor })
      .then(vendor => {
        getNotifier().success(
          id ? 'Actualizado correctamente' : 'Agregado correctamente'
        );
        navigate(paths.catVendor);
      }
    )
      .catch(error => getNotifier().error(error.message || error.detail));
  }, [api, form, navigate]);

  const loading = useSelector(s => !!s.loadingIds[loadingId]);

  return (
    <div className="CatVendorForm dashboard-container">
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <SectionTitle>
            { !id ? 'AGREGAR PROVEEDOR' : 'EDITAR PROVEEDOR' }
          </SectionTitle>
          <div className='row'>
            <Card className='OrderStep1'>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Nombre"
                    placeholder=""
                    {...bindInput('name')}
                  />
                </div>
              </div>
                      
              <div className='d-flex justify-content-center align-items-center buttons-container'>
                <Button
                  className={'btn-primary btn-outline'}
                  onClick={() => window.location.href = paths.catVendor }
                  design={'clear'}
                >
                  Cancel
                </Button>
                <Button 
                  className={`btn-primary btnNextStep`}
                  onClick={handleCreateOrUpdateMaterialType}
                  disabled={loading}
                >
                  { id ? 'Actualizar' : 'Agregar' }
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
