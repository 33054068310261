import { Link } from "react-router-dom";
import { paths } from "../../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit
} from '@fortawesome/free-solid-svg-icons';


export const getCatMaterialStatusTableColumns = (
  {onRemoveClick}
) =>[
  {
    Header: 'ID',
    accessor: materialStatus => 
      <div className='text-center'>
        {materialStatus.id}
      </div>
  },
  {
    Header: 'ESTATUS',
    accessor: materialStatus => 
      <div className='text-center'>
        {materialStatus.name}
      </div>
  },
  {
    Header: 'ACCIONES',
    accessor: materialStatus => 
    <span
      className='ActionSee d-flex justify-content-center align-items-center' 
    >
      <Link
        to={ paths.catMaterialStatusFormEdit.replace(":id", materialStatus.id) }
        className="m-2"
        title="Editar"
      >
        <FontAwesomeIcon icon={faEdit}/>
      </Link>
      <span
        onClick={() => {
            onRemoveClick(materialStatus.id)
        }} 
        className="m-2 error cursor-pointer"
        title="Eliminar"
      >
          <FontAwesomeIcon icon={faTrash}/>
      </span>
    </span>
  }
];

export const catMaterialStatusTableSGroups = [
    'cat_material_status_read'
];