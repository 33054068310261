import React, { useCallback, useState, useEffect, useContext } from 'react'
import "./MaterialsTable.scss"
import _ from "lodash";
import Button from '../../../../../../components/molecules/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import MaterialEvidences from '../MaterialEvidences/MaterialEvidences';
import { ApiContext } from '../../../../../../services/api/api-config';

export default function MaterialsTable(
  {vendorWorkOrder}
) {
  
  const [materialSelected, setMaterialSelected] = useState(null)
  const [first, setFirst] = useState(true)
  const api = useContext(ApiContext);
  const [materials, setMaterials] = useState([])

  const loadEvidences = useCallback((material) => {
    setMaterialSelected(material)
  }, []);

  const getMaterialDetails = useCallback(async () => {
    let theMaterials = [];
    for (const material of vendorWorkOrder.materials) {
      const theResponse = await api.evidence.get({
        params: {
          'campaign.id': vendorWorkOrder.campaign.id,
          'store.id': vendorWorkOrder.store.id,
          'material.id': material.id
        }
      });
      theMaterials.push(
        {...material, status: theResponse.length > 0 ? theResponse[0].status : ''}
      );
    }
    setMaterials(theMaterials);
  }, [api.evidence, vendorWorkOrder.campaign.id, vendorWorkOrder.materials, vendorWorkOrder.store.id]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getMaterialDetails();
    }
  }, [first, getMaterialDetails, materials])
  

  return (
    <div className='MaterialsTable'>
      <table className='table'>
        <thead>
          <tr>
            <th>
              Nombre
            </th>
            <th>
              Descripción
            </th>
            <th>
              Cantidad
            </th>
            <th>
              Especificaciones
            </th>
            <th>
              Estatus
            </th>
            <th>
              Acciones
            </th>
          </tr>
        </thead>
        <tbody>
          {
            _.map(materials, (material) => (
              <tr key={material.id}>
                <td>
                  {material.name}
                </td>
                <td>
                  {material.description}
                </td>
                <td>
                  {material.quantity}
                </td>
                <td>
                  {material.specifications !== '' ? material.specifications : 'n/a'}
                </td>
                <td>
                  {material.status}
                </td>
                <td>
                  <Button
                    design="clear"
                    onClick={() => loadEvidences(material)}
                  >
                    Evidencias
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {
        materialSelected &&
        <Modal
          title={`Evidencias de: ${materialSelected.name} - ${materialSelected.description}`}
          onClose={() => setMaterialSelected(null)}
          large={true}
        >
          <div className='GrowerChangeStatusButtons__modal-content'>
            <div className='GrowerChangeStatusButtons__modal-content-title'>
              <MaterialEvidences
                vendorWorkOrder={vendorWorkOrder}
                materialSelected={materialSelected}
                onClose={() => setMaterialSelected(null)}
              />
            </div>
          </div>
        </Modal>
      }
    </div>
  )
}
