import React, { useCallback, useContext, useEffect, useState } from 'react'
import _ from 'lodash';
import "./WorkOrderDetails.scss"
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../../../../services/api/api-config';
import { vendorWorkOrderSGroups } from '../../WorkOrderUtils';
import { getNotifier } from '../../../../../services/notifier';
import { SideMenu } from '../../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import Card from '../../../../../components/Dashboard/Card/Card';
import VendorWorkOrder from '../VendorWorkOrder/VendorWorkOrder';
import Button from '../../../../../components/molecules/Button/Button';


export default function WorkOrderDetails() {

  const { id } = useParams();
  const api = useContext(ApiContext);
  const [vendors, setVendors] = useState([])
  const [first, setFirst] = useState(true)
  const [campaignInfo, setCampaignInfo] = useState(null)

  const downloadReport = api.vendorWorkOrders.getReport({
    id: id,
  });

  const getCampaign = useCallback(() => {
    api.campaigns.get({ id }).then(response => {
      setCampaignInfo(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaigns, id]);

  const getVendorWorkOrders = useCallback(() => {
    api.vendorWorkOrders.get(
      {
        params: {
          'campaign.id': id,
          sGroups: vendorWorkOrderSGroups,
        }
      }
    ).then(response => {
      let vendorsList = [];
      response.forEach(element => {
        const found = vendorsList.find((vendor) => vendor.id === element.catVendor.id );
        if (!found) {
          vendorsList.push(element.catVendor);
        }
      });
      setVendors(vendorsList);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders, id]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getCampaign();
      getVendorWorkOrders();
    }
  }, [first, getCampaign, getVendorWorkOrders])


  return (
    <div className='WorkOrderDetails'>
      <div
        className='WorkOrder'
      >
        <SideMenu />
        <div className='right-content'>
          <TopBar />
          <div className='main-content'>
            <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
              <SectionTitle className="m-0 p-0">
                ÓRDENES DE TRABAJO
              </SectionTitle>
              <a
                href = {downloadReport}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  type='button'
                  id="generate-report"
                >
                  Generar Reporte
                </Button>
              </a>
              <div>
                Ver Archivos de campaña
              </div>
            </div>
            <div className='mt-2'>
              {
                campaignInfo &&
                <h3>
                  {campaignInfo.name}
                </h3>
              }
            </div>
            <div className="row mt-3">
              <Card>
                <div>
                  {
                    _.map(vendors, (vendor) => (
                      <div key={vendor.id}>
                        <VendorWorkOrder
                          vendor={vendor}
                          campaign={id}
                        ></VendorWorkOrder>
                      </div>
                    ))
                  }
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
