import React from 'react'
import {useSelector} from "react-redux";
import './UserInfo.scss';
import avatar from "../../../../../assets/images/logos/logo_puma.png";

const UserInfo = () => {
    const me = useSelector(({api}) => api.me);

    return (
        <div className='UserInfo'>
            <div>
                <div className='UserInfo__name'>
                    { me.email }
                </div>
                <div className='UserInfo__role'>
                    { me?.role.title }
                </div>
            </div>
            <div className='UserInfo__picture'>
                <img src={avatar} alt='Mostacho' />
            </div>
        </div>
    );
}


export default UserInfo;