import React from 'react'
import "./BarChart.scss"
import ReactEcharts from "echarts-for-react"

export default function BarChart({labels, data}) {
  const options = {
    grid: { top: 20, right: 40, bottom: 20, left: 40 },
    xAxis: {
      type: "category",
      data: labels
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        data: data,
        type: "bar",
        smooth: true
      }
    ],
    tooltip: {
      trigger: "axis"
    },
  }

  return (
    <div className='BarChart'>
      <div className='text-center'>
        <h3>Materiales instalados por mes</h3>
      </div>
      <ReactEcharts
        option={options}
        style={{ width: "100%", height: "300px" }}
      ></ReactEcharts>
    </div>
  )
}
