import React, {useState, useContext, useEffect, useCallback} from 'react'
import { ApiContext } from '../../../../../../services/api/api-config';
import { getNotifier } from '../../../../../../services/notifier';
import { storeMaterialSGroups } from '../../../CampaignUtils';
import _ from 'lodash';
import "./StoreMaterialList.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../../../../components/Modal/Modal';
import AddMaterialToStore from '../AddMaterialToStore/AddMaterialToStore';
import AddPermissionToStore from '../AddPermissionToStore/AddPermissionToStore';
import {SecurityContext} from "../../../../../../services/SecurityManager";

export default function StoreMaterialList(
  {storeId, materialsList, load, campaignId, handleAddMaterial}
) {

  const [materials, setMaterials] = useState([]);
  const [first, setFirst] = useState(true)
  const [showAddMaterialToStoreModal, setShowAddMaterialToStoreModal] = useState(false)
  const [showAddPermissionToStoreModal, setShowAddPermissionToStoreModal] = useState(false)
  const [allChecked, setAllChecked] = useState(false)
  const api = useContext(ApiContext);
  const security = useContext(SecurityContext);

  //Don't show buttons for adding for ROLE_CLIENT and ROLE_VENDOR
  const onClientOrVendor = security.isClient() || security.isVendor();

  /**
   * Reorder materials list
   */
  const reorderMaterials = useCallback((materialsResponse) => {
    let materialsChecked = [];
    let materialsUnchecked = [];
    materialsResponse.forEach(theMaterial => {
      if (first) {
        setFirst(false);
        const materialChecked = materialsList.find((element) => theMaterial.id === element.id);
        if (materialChecked) {
          theMaterial.checked = true;
          materialsChecked.push(theMaterial);
        } else {
          theMaterial.checked = false;
          materialsUnchecked.push(theMaterial);
        }
      } else {
        if (theMaterial.checked) {
          materialsChecked.push(theMaterial);
        } else {
          materialsUnchecked.push(theMaterial);
        }
      }
    });
    setMaterials([...materialsChecked, ...materialsUnchecked]);
  }, [first, materialsList]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      api.materials.get({
        params: {
          'store.id': storeId,
          sGroups: storeMaterialSGroups
        }
      })
        .then(response => {
          reorderMaterials(response);
        })
        .catch(error => getNotifier().error(error.detail || error.message));
    }
  }, [api, first, load, materials, materials.length, materialsList, reorderMaterials, storeId])

  /**
   * Add materials to store in the campaign
   */
  const createMaterials = useCallback((materials) => {
    api.campaignDetails.create({
      params: {
        'campaign': campaignId,
        'store': storeId,
        'materials': materials,
        editable: true
      }
    }).then(responseCreateMaterials => {
      getNotifier().success('Se actualizó correctamente');
      handleAddMaterial(storeId, materials);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaignDetails, campaignId, handleAddMaterial, storeId]);

  /**
   * Update materials to store in campaign
   */
  const updateMaterials = useCallback((materials, id) => {
    api.campaignDetails.update({
      id,
      params: {
        'materials': materials
      }
    }).then(responseUpdateMaterials => {
      getNotifier().success('Se actualizó correctamente')
      handleAddMaterial(storeId, materials);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaignDetails, handleAddMaterial, storeId]);

  /**
   * Handle material click to add the material selected to store
   */
  const onMaterialClick = useCallback((material) => {
    const materialsTemp = [...materials];
    let materialsResult = [];
    materialsTemp.forEach(element => {
      materialsResult.push({
        id: element.id,
        checked: element.id === material.id ? !element.checked : element.checked,
        customId: element.customId,
        sizes: element.sizes,
        catMaterialType: element.catMaterialType
      });
    });
    api.campaignDetails.get({
      params: {
        'campaign.id': campaignId,
        'store.id': storeId
      }
    }).then(responseCampaignDetails => {
      reorderMaterials(materialsResult);
      let materialsToUpdate = [];
      if (responseCampaignDetails.length > 0) {
        materialsToUpdate = responseCampaignDetails[0].materials;
        const materialFind = materialsToUpdate.find(theMaterial => theMaterial.id === material.id );
        if (!materialFind) {
          materialsToUpdate.push({
            id: material.id,
            name: material.catMaterialType.name,
            description: material.catMaterialType.description,
            amount: material.catMaterialType.clientAmount,
            vendor: 0,
            quantity: 1,
            specifications: '',
            vendorAmount: material.catMaterialType.vendorAmount
          });
        } else {
          const theIndex = materialsToUpdate.findIndex(theMaterial => theMaterial.id === material.id );
          materialsToUpdate.splice(theIndex, 1);
        }
        updateMaterials(
          materialsToUpdate,
          responseCampaignDetails[0].id
        );
      } else {
        materialsToUpdate.push({
          id: material.id,
          name: material.catMaterialType.name,
          description: material.catMaterialType.description,
          amount: material.catMaterialType.clientAmount,
          vendor: 0,
          quantity: 1,
          specifications: '',
          vendorAmount: material.catMaterialType.vendorAmount
        });
        createMaterials(materialsToUpdate);
      }
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaignDetails, campaignId, createMaterials, materials, reorderMaterials, storeId, updateMaterials]);

  /**
   * Handle on Add material
   */
  const onAddMaterial = useCallback(() => {
    setShowAddMaterialToStoreModal(true);
  }, []);

  const onAddPermissionFile = useCallback(() => {
    setShowAddPermissionToStoreModal(true);
  }, []);

  /**
   * Load materials for store
   */
  const loadMaterials = useCallback(() => {
    setShowAddMaterialToStoreModal(false);
    api.materials.get({
      params: {
        'store.id': storeId,
        sGroups: storeMaterialSGroups
      }
    })
    .then(response => {
      reorderMaterials(response);
    })
    .catch(error => getNotifier().error(error.detail || error.message));
  }, [api.materials, reorderMaterials, storeId]);

  const handleAllChecked = useCallback(() => {
    const isChecked = !allChecked;
    setAllChecked(isChecked);
    const materialsTemp = [...materials];
    let materialsResult = [];
    materialsTemp.forEach(element => {
      materialsResult.push({
        id: element.id,
        checked: isChecked,
        customId: element.customId,
        sizes: element.sizes,
        catMaterialType: element.catMaterialType
      });
    });
    setMaterials(materialsResult);
    let materialsToUpdate = [];
    if (isChecked) {
      materialsTemp.forEach(material => {
        materialsToUpdate.push({
          id: material.id,
          name: material.catMaterialType.name,
          description: material.catMaterialType.description,
          amount: material.catMaterialType.clientAmount,
          vendor: 0,
          quantity: 1,
          specifications: '',
          vendorAmount: material.catMaterialType.vendorAmount
        });
      });
    }
    api.campaignDetails.get({
      params: {
        'campaign.id': campaignId,
        'store.id': storeId
      }
    }).then(responseCampaignDetails => {
      if (responseCampaignDetails.length > 0) {
        updateMaterials(
          materialsToUpdate,
          responseCampaignDetails[0].id
        );
      } else {
        createMaterials(materialsToUpdate);
      }
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [allChecked, api.campaignDetails, campaignId, createMaterials, materials, storeId, updateMaterials]);

  return (
    <div className='StoreMaterialList'>
      {!onClientOrVendor && (
        <div>
          <input
            type="checkbox"
            onClick={() => {
              handleAllChecked();
            }}
          />
          <label
            className='checked-all'
          >
            Seleccionar todos
          </label>
        </div>
      )}
      {_.map(materials, (material) => (
        (!onClientOrVendor || material.checked) && (
          <div
            className="d-flex justify-content-between"
            key={material.id}
          >
            <div
              className="col"
              key={material.id}
            >
              {!onClientOrVendor && (
                <input
                  type="checkbox"
                  value={material.id}
                  id={`${material.id}-${storeId}`}
                  onChange={() => {
                    onMaterialClick(material);
                  }}
                  checked={material.checked}
                />
              )}
              <label
                className='material-name'
                htmlFor={`${material.id}-${storeId}`}
                title={material.catMaterialType.description}
              >
                {material.catMaterialType.name}
              </label>
            </div>
            <div className='tooltip top'>
              <FontAwesomeIcon icon={faInfoCircle} className="info-icon cursor-pointer"/>
              <span className="tiptext">
              {material.catMaterialType.description}
                <br />
              Medidas: {material.sizes}
            </span>
            </div>
          </div>
        )
      ))}
      {!onClientOrVendor && (
          <div className='d-flex justify-content-between'>
            <div className='p-2'>
              <button
                  onClick={() => onAddMaterial()}
                  className="btn-add-material"
                  design="clear"
              >
                + Agregar Material
              </button>
            </div>
            <div className='p-2'>
              <button
                  onClick={() => onAddPermissionFile()}
                  className="btn-add-permission-file"
                  design="clear"
              >
                + Agregar Permiso
              </button>
            </div>
          </div>
      )}
      {
        showAddMaterialToStoreModal &&
        <Modal
          title="Agregar Material a la Tienda"
          onClose={() => setShowAddMaterialToStoreModal(false)}
        >
          <div className='GrowerChangeStatusButtons__modal-content'>
            <div className='GrowerChangeStatusButtons__modal-content-title'>
              <AddMaterialToStore
                storeId={storeId}
                onSuccess={loadMaterials}
              ></AddMaterialToStore>
            </div>
          </div>
        </Modal>
      }
      {
        showAddPermissionToStoreModal &&
        <Modal
          title="Agregar Permiso a la Tienda"
          onClose={() => setShowAddPermissionToStoreModal(false)}
        >
          <div className='GrowerChangeStatusButtons__modal-content'>
            <div className='GrowerChangeStatusButtons__modal-content-title'>
              <AddPermissionToStore
                storeId={storeId}
                campaignId={campaignId}
              ></AddPermissionToStore>
            </div>
          </div>
        </Modal>
      }
    </div>
  )
}
