import React, {useCallback, useContext, useEffect, useState} from 'react'
import { SectionTitle } from '../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../components/Dashboard/TopBar/TopBar';
import ResultsCard from '../../components/Dashboard/ResultsCard/ResultsCard';
import './Dashboard.scss';
import SimpleList from '../../components/Dashboard/SimpleList/SimpleList';
import LabelStatus from '../../components/Dashboard/LabelStatus/LabelStatus';
import usersImage from '../../assets/images/icons/icono_clientes.png';
import vendorsImage from '../../assets/images/icons/icono_proveedores.png';
import campaignsImage from '../../assets/images/icons/icono_campañas.png';
import activeCampaignsImage from '../../assets/images/icons/icono_campañas_activas.png';
import HeaderBanner from '../../assets/images/backgrounds/dashboardBanner.png';
import { SecurityContext } from "../../services/SecurityManager";
import {ApiContext} from "../../services/api/api-config";
import {getNotifier} from "../../services/notifier";
import BarChart from '../../components/Chart/BarChart/BarChart';

const Dashboard = () => {

    const api = useContext(ApiContext);
    const [vendorWorkOrders, setVendorWorkOrders] = useState([])
    const [campaignCountByStatus, setCampaignCountByStatus] = useState([])
    const [first, setFirst] = useState(true)
    const [chartData, setChartData] = useState([])

    const chartLabels = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    const getVendorWorkOrders = useCallback(() => {
        api.vendorWorkOrders.getVendorWorkOrdersWithDetails().then((response) => {
            setVendorWorkOrders(response);
        }).catch(
          error => getNotifier().error(error.detail || error.message)
        );
    }, [api]);

    const getCampaignCountByStatus = useCallback(() => {
        api.campaigns.getCampaignCountByStatus().then((response) => {
            setCampaignCountByStatus(response);
        }).catch(
          error => getNotifier().error(error.detail || error.message)
        );
    }, [api]);

    /**
     * Get color for bar in chart
     */
    const getColor = useCallback((count) => {
        if (count <= 10) {
            return 'gray';
        }
        if (count <= 30) {
            return 'green';
        }
        return 'gold';
    }, []);

    /**
     * Get materials installed by month in current year
     */
    const getMaterialsInstalledThisYear = useCallback(() => {
        api.materials.getInstalledThisYear().then((months) => {
            let monthsArray = [];
            for (let index = 1; index < 13; index++) {
                const monthFound = months.find(month => month.month === index);
                if (monthFound) {
                    monthsArray.push(
                        {
                            value: monthFound.count,
                            itemStyle: {
                                color: getColor(monthFound.count)
                            },
                        }
                    );
                } else {
                    monthsArray.push(
                        {
                            value: 0,
                            itemStyle: {
                                color: 'red'
                            }
                        }
                    );
                }
            }
            setChartData(monthsArray);
        }).then().catch(
            error => getNotifier().error(error.detail || error.message)
        );
    }, [api.materials, getColor]);

    const columns = [
        {
            Header: "PROVEEDOR",
            accessor: "vendor"
        },
        {
            Header: "CAMPAÑA",
            accessor: "campaign"
        },
        {
            Header: "TIENDA",
            accessor: "store"
        },
        {
            Header: "DESCRIPCIÓN",
            accessor: "description"
        },
        {
            Header: "STATUS",
            id: 'status',
            accessor: (row) => <LabelStatus status={row.status} statusText={row.status} />
        },
        {
            Header: "PRECIO",
            accessor: "price"
        },
    ];

    //Don't show vendor and price columns for Client Role
    const security = useContext(SecurityContext);
    const onClientRole = security.isClient();
    const filteredColumns = columns.filter(column => column.accessor !== "vendor" && column.accessor !== "price");

    useEffect(() => {
        if (first) {
            setFirst(false);
            getVendorWorkOrders();
            getCampaignCountByStatus();
            getMaterialsInstalledThisYear();
        }
    }, [getVendorWorkOrders, getCampaignCountByStatus, getMaterialsInstalledThisYear, first])

    return (
        <div className="Dashboard">
            <SideMenu />
            <div className='right-content'>
                <TopBar />
                <div className='main-content'>
                    <div className="header-image">
                        <img src={HeaderBanner} alt="Foto de encabezado" />
                    </div>
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard
                                title={campaignCountByStatus.invoicedCampaigns}
                                value="CAMPAÑAS FACTURADAS"
                                title2=""
                                value2=""
                                title3=""
                                value3=""
                                numberClass="number-first"
                                cardImage={usersImage}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard
                                title={campaignCountByStatus.pendingCampaigns}
                                value={<span>CAMPAÑAS<br/>PENDIENTES<br/>DE FOLIO</span>}
                                title2=""
                                value2=""
                                title3=""
                                value3=""
                                numberClass="number-second"
                                cardImage={vendorsImage}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard
                                title={campaignCountByStatus.totalCampaigns}
                                value="CAMPAÑAS TOTALES"
                                title2=""
                                value2=""
                                title3=""
                                value3=""
                                numberClass="number-third"
                                cardImage={campaignsImage}
                            />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <ResultsCard
                                title={campaignCountByStatus.activeCampaigns}
                                value="CAMPAÑAS ACTIVAS"
                                title2=""
                                value2=""
                                title3=""
                                value3=""
                                numberClass="number-fourth"
                                cardImage={activeCampaignsImage}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="row">
                            <BarChart
                                labels={chartLabels}
                                data={chartData}
                            ></BarChart>
                        </div>
                    </div>

                    <SectionTitle>
                        ÓRDENES ABIERTAS
                    </SectionTitle>

                    <div className="row">
                        <div className="col-12">
                            <SimpleList columns={onClientRole ? filteredColumns : columns} data={vendorWorkOrders} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Dashboard;