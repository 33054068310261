import React, { useCallback, useEffect, useState } from 'react'
import "./StoreAlertExpired.scss"
import { SideMenu } from '../../../../../../components/Dashboard/SideMenu/SideMenu'
import TopBar from '../../../../../../components/Dashboard/TopBar/TopBar'
import { SectionTitle } from '../../../../../../components/Dashboard/SectionTitle/SectionTitle'
import Card from '../../../../../../components/Dashboard/Card/Card'
import { useContext } from 'react'
import { ApiContext } from '../../../../../../services/api/api-config'
import { getNotifier } from '../../../../../../services/notifier'
import _ from "lodash"
import { generalDatesFormat } from '../../../../../../services/generalConfig'
import moment from 'moment'
import Button from '../../../../../../components/molecules/Button/Button'

export default function StoreAlertExpired() {

  const api = useContext(ApiContext);
  const [first, setFirst] = useState(true)
  const [alerts, setAlerts] = useState([])

  const getStoreAlert = useCallback(() => {
    api.storeAlerts.getAlerts().then(response => {
      setAlerts(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.storeAlerts]);

  const removeAlert = useCallback((alert) => {
    const theAlerts = [...alerts];
    const theIndex = theAlerts.findIndex(element => element.id === alert.id);
    theAlerts.splice(theIndex, 1);
    setAlerts(theAlerts);
    return getNotifier().success('Se ha actualizado correctamente');
  }, [alerts]);

  const handleAlertModification = useCallback((alert) => {
    if (moment(alert.postponedDate) > moment.now()) {
      api.storeAlerts.update({
        id: alert.id,
        params: {
          postponedDate: alert.postponedDate
        }
      }).then(response => {
        removeAlert(response);
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    } else {
      return getNotifier().error('La fecha debe ser mayor al día actual');
    }
  }, [api.storeAlerts, removeAlert]);

  const updatePostponedDate = useCallback((date, alert) => {
    const theAlerts = [...alerts];
    const theIndex = theAlerts.findIndex(element => element.storeId === alert.storeId);
    if (theIndex > -1) {
      let theNewDate = date.target.value;
      theAlerts[theIndex].postponedDate = theNewDate;
      setAlerts(theAlerts);
    }
  }, [alerts]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getStoreAlert();
    }
  }, [first, getStoreAlert])
  
  return (
    <div className='StoreAlertExpired'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col mt-4'>
            <SectionTitle className="m-0 p-0">
              Tiendas con materiales sin cambio
            </SectionTitle>
          </div>
          <div className='mt-4'>
            <Card>
              {
                _.map(alerts, (alert, index) => (
                  <div className='store-row' key={index}>
                    <div className='store-name d-flex justify-content-between'>
                      <div>
                        Tienda: {alert.store}
                      </div>
                      <div className='mr-4 cursor-pointer d-flex'>
                        <div className='mr-4'>
                          <input
                            type="date"
                            value={alert.postponedDate ? alert.postponedDate : ''}
                            onChange={(e) => updatePostponedDate(e, alert)}
                            className='date-input'
                          />
                        </div>
                        <Button
                          type='button' 
                          onClick={() => handleAlertModification(alert)}
                        >
                          Posponer alerta
                        </Button>
                      </div>
                    </div>
                    <div className='material-row'>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              Nombre
                            </th>
                            <th>
                              Descripción
                            </th>
                            <th>
                              Fecha de última actualización
                            </th>
                            <th>
                              Estatus actual
                            </th>
                            <th>
                              Días sin modificación
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            _.map(alert.materials, (material, materialIndex) => (
                              <tr key={`${alert.storeId}${materialIndex}`}>
                                <td>
                                  {material.name}
                                </td>
                                <td>
                                  {material.description}
                                </td>
                                <td>
                                  {
                                    moment(material.updatedDate).format( generalDatesFormat )
                                  }
                                </td>
                                <td>
                                  {material.status ? material.status : 'n/a'}
                                </td>
                                <td>
                                  {material.diffDays}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                    
                  </div>
                ))
              }
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
