//Vamos a organizar las traducciones alfabéticamente
const translations = {
    "Accepted": "Aceptado",
    "account-confirmation-error": "No hemos podido confirmar tu cuenta",
    "account-confirmed": "Tu cuenta ha sido confirmada correctamente",
    "Actions": "Acciones",
    "Active": "Activo",
    "Active-certification": "Certificación activa",
    "Activate": "Activar",
    "Activate-user": "Activar usuario",
    "Active-ingredient": "Ingrediente activo",
    "Active-ingredients": "Ingredientes activos",
    "Active-ingredient-created-successfully": "Ingrediente activo creado correctamente",
    "Active-ingredient-updated-successfully": "Ingrediente activo actualizado correctamente",
    "Add-document": "Agregar documento",
    "Add-file": "Agregar archivo",
    "Add-foliar-application": "Crear aplicación de foliar",
    "Add-foliar-application-to-sectors": "Añadir aplicación de foliar a sectores",
    "Add-hobbies-extra-activities-etc": "Agregar hobbies, actividades extra, etc",
    "Add-Links": "Agregar enlaces",
    "add-Machinery": "Agregar maquinaria",
    "Add-more": "Agregar más",
    "Add-myfirst-Sector": "Agregar mi primer sector",
    "Add-new-active-ingredient": "Agregar nuevo ingrediente activo",
    "add-new-application": "Crear nueva aplicación",
    "Add-new-brand": "Agregar nueva marca",
    "Add-new-foliar-application": "Crear nueva aplicación de foliar",
    "Add-new-orchard": "Agregar nueva huerta",
    "add-new-order": "Agregar nueva orden",
    "Add-new-product": "Agregar nuevo producto",
    "add-new-productor": "Crear nuevo productor",
    "Add-New-Productor": "Agregar nuevo productor",
    "Add-new-sector": "Agregar nuevo sector",
    "Add-new-user": "Agregar nuevo usuario",
    "Add-new-visit": "Agregar nueva visita",
    "Add-new-warehouse": "Agregar nuevo almacén",
    "Add-orchard": "Agregar huerta",
    "Add-other-comments-here": "Agregar otros comentarios aquí",
    "Add-product": "Agregar producto",
    "Add-product-here": "Agregar producto aquí",
    "Add-products": "Agregar productos",
    "Add-sector-here": "Agregar sector aquí",
    "Add-value": "Agregar valor",
    "Add-variant": "Agregar variante",
    "Add-variant-attribute": "Agregar atributo de variante",
    "Add-variant-value": "Agregar valor de variante",
    "Add-variation": "Agregar variedad",
    "Add-variety": "Agregar variedad",
    "Add-variety-attribute": "Agregar atributo de variedad",
    "Add-new-followup": "Agregar nuevo seguimiento",
    "Add-variety-value": "Agregar valor de variedad",
    "Add-visit-sector-details": "Agregar detalles del sector de la visita",
    "Add-sector-details": "Agregar detalles de sector",
    "Add-your-first-application-here": "Agregar tu primera aplicación aquí",
    "Admin-user": "Usuario administrador",
    "Are-you-sure-you-want-to-delete-this-productor": "¿Estás seguro de que quieres eliminar este productor?",
    "Address": "Dirección",
    "address": "dirección",
    "address.createdBy": "creado por",
    "address.createdDate": "fecha de creación",
    "address.externalNumber": "número exterior",
    "address.internalNumber": "número interior",
    "address.latitude": "latitud",
    "address.longitude": "longitud",
    "address.neighbourhood": "colonia",
    "address.street": "calle",
    "address.updatedBy": "actualizado por",
    "address.updatedDate": "fecha de actualización",
    "already-have-account": "¿Ya tienes una cuenta?",
    "Analysis": "Análisis",
    "An-error-occurred-while-creating-the-attribute": "Se produjo un error al crear el atributo",
    "An-error-occurred-while-creating-the-value": "Se produjo un error al crear el valor",
    "appFile": "Archivo",
    "appFile.createdBy": "creado por",
    "appFile.createdDate": "fecha de creación",
    "appFile.mimeType": "tipo",
    "appFile.name": "nombre",
    "appFile.originalName": "nombre original",
    "appFile.size": "tamaño",
    "appFile.updatedBy": "actualizado por",
    "appFile.updatedDate": "fecha de actualización",
    "Application no": "No. de aplicación",
    "Applications": "Aplicaciones",
    "Application-date": "Fecha de aplicación",
    "Approve-productors": "Aprobar productores",
    "Approve-productors-description": "El usuario podrá revisar los usuarios productores creados y aceptarlos o eliminarlos.",
    "Are-you-sure-you-want-to-activate-this-user": "¿Estás seguro de que quieres activar este usuario?",
    "Are-you-sure-you-want-to-disable-this-user": "¿Estás seguro de que deseas deshabilitar este usuario?",
    "Are-you-sure-you-want-to-delete-this-visit": "¿Estás seguro de que quieres eliminar esta visita?",
    "As-a-grower": "Como un Productor",
    "As-a-wholesale": "Como un Distribuidor",
    "Attribute": "Atributo",
    "available": "disponible",
    "bad": "Malo",
    "Billing-Constancy": "Constancia de facturación",
    "Billing-data": "Datos de facturación",
    "Birthday-date": "Fecha de cumpleaños",
    "bloom": "Floración",
    "Boxes/Pallet": "Cajas/Pallet",
    "boxes-left-with-this-productor": "cajas restantes con este productor",
    "Boxes-per-pallet": "Cajas por pallet",
    "Brand": "Marca",
    "Brands": "Marcas",
    "Brand-created-successfully": "Marca creada con éxito",
    "Brand-updated-successfully": "Marca actualizada correctamente",
    "Brand's name": "Nombre de la marca",
    "BROTHER": "Hermano",
    "budding": "Creciendo",
    "Business-name": "Razón social",
    "buttoning": "Botonamiento",
    "Buyer": "Comprador",
    "Caliber": "Calibre",
    "Cancel": "Cancelar",
    "Cancel-changes": "Cancelar cambios",
    "cer-here": ".cer aquí",
    "Certificate-and-digital-seal": "Certificado y sello digital",
    "Certifications": "Certificaciones",
    "Client": "Cliente",
    "Client-order": "Orden de cliente",
    "Climate-damage": "Daño climático",
    "Comments": "Comentarios",
    "company": "empresa",
    "Company-name": "Nombre de la empresa",
    "Company-name-here": "Nombre de la empresa aquí",
    "company.address": "dirección",
    "company.boughtProductOffers": "compras",
    "company.businessName": "razón social",
    "company.classification": "clasificación",
    "company.companyCode": "código de empresa",
    "company.companyProducts": "productos de la empresa",
    "company.contactCellphoneNumber": "número celular",
    "company.contactEmail": "email",
    "company.contactFirstLastName": "apellido",
    "company.contactLandlinePhoneNumber": "número telefónico local",
    "company.contactName": "nombre",
    "company.contactSecondLastName": "segundo apellido",
    "company.conversations": "conversaciones",
    "company.createdBy": "creado por",
    "company.createdDate": "fecha de creación",
    "company.fileArchives": "archivos",
    "company.interestedOffers": "ofertas de interés",
    "company.leadCode": "código de prospecto",
    "company.orchards": "huertas",
    "company.producingProducts": "productos en producción",
    "company.registryStatus": "estado del registro",
    "company.rfc": "RFC",
    "company.senasicaNumber": "número SENASICA",
    "company.soldProductOffers": "ventas",
    "company.tradeName": "nombre comercial",
    "company.type": "tipo",
    "company.updatedBy": "actualizado por",
    "company.updatedDate": "fecha de actualización",
    "companyProduct": "producto producido",
    "companyProduct.company": "empresa",
    "companyProduct.companyProductCode": "código",
    "companyProduct.country": "país",
    "companyProduct.createdBy": "creado por",
    "companyProduct.createdDate": "fecha de creación",
    "companyProduct.product": "producto",
    "companyProduct.productType": "tipo",
    "companyProduct.productVariation": "variación",
    "companyProduct.updatedBy": "actualizado por",
    "companyProduct.updatedDate": "fecha de actualización",
    "Confirm": "Confirmar",
    "Confirm-delete-visit": "Confirmar eliminación de visita",
    "Confirm-grower": "Confirmar productor",
    "Confirm-password": "Confirmar contraseña",
    "confirm-your-email": "Confirma tu email",
    "confirmation-mail-sent": "Correo de confirmación enviado a",
    "confirmation-mail-sent-to-email": "Tu correo de confirmación ha sido reenviado",
    "confirmation-mail-text": "Por favor, comprueba tu correo. Haz clic en el enlace del correo para confirmar tu dirección de correo electrónico.",
    "Consistency": "Consistencia",
    "contact-email-format-error": "El formato de correo electrónico de contacto no es correcto",
    "Continue": "Continuar",
    "Continue-later": "Continuar más tarde",
    "Contitutional-act": "Acta constitutiva",
    "conversation": "conversación",
    "conversation.companies": "empresas",
    "conversation.conversationMessages": "mensajes de la conversación",
    "conversation.createdBy": "creado por",
    "conversation.createdDate": "fecha de creación",
    "conversation.updatedBy": "actualizado por",
    "conversation.updatedDate": "fecha de actualización",
    "conversationMessage": "conversación",
    "conversationMessage.conversation": "conversación",
    "conversationMessage.createdBy": "creado por",
    "conversationMessage.createdDate": "fecha de creación",
    "conversationMessage.fileArchive": "archivos",
    "conversationMessage.message": "mensaje",
    "conversationMessage.updatedBy": "actualizado por",
    "conversationMessage.updatedDate": "fecha de actualización",
    "Coordinates": "Coordenadas",
    "Country": "País",
    "country": "país",
    "country.code": "código",
    "country.createdBy": "creado por",
    "country.createdDate": "fecha de creación",
    "country.name": "nombre",
    "country.states": "estados",
    "country.updatedBy": "actualizado por",
    "country.updatedDate": "fecha de actualización",
    "Create-account": "Crear cuenta",
    "Create-an-account": "Crear una cuenta",
    "Create-orchard-and-continue": "Crear huerta y continuar",
    "Create-orders": "Crear órenes",
    "Create-orders-description": "El usuario podrá agregar nuevos pedidos de productos en cualquier momento, con cualquier revisión",
    "Create-product-order": "Crear orden de producto",
    "Create-productors": "Crear productores",
    "Create-productors-description": "El usuario podrá agregar nuevos usuarios productores con cualquier revisión anterior.",
    "Create-sectors": "Crear sectores",
    "Created-On": "Creado el",
    "Created-by": "Creado por",
    "credits": "créditos",
    "Date": "Fecha",
    "Date-uploaded": "Fecha de carga",
    "DAUGHTER": "Hija",
    "Delete": "Eliminar",
    "Delete-product": "Eliminar producto",
    "Delivery-address": "Dirección de entrega",
    "Delivery-date": "Fecha de entrega",
    "Dependant": "Dependiente",
    "Description": "Descripción",
    "Description-here": "Descripción aquí",
    "Desired-bozed-to-buy": "Producto deseado para comprar",
    "did-not-receive-email": "¿No recibiste el correo?",
    "Digital-signature": "Firma electrónica",
    "Digital-signature-here": "Firma electrónica aquí",
    "Disable": "Deshabilitar",
    "Disable-user": "Deshabilitar usuario",
    "Disenroll-grower": "Desinscribir productor",
    "Divorced": "Divorciado",
    "Document": "Documento",
    "Document-name": "Nombre del documento",
    "document-specs": "Los formatos aceptados son .pdf, .png, .jpg. Los documentos deben ser menores a 5MB",
    "Documents": "Documentos",
    "Documents-and-social-economic-information": "Documentos y información social económica",
    "Documents-should-follow": "Documentos que deben ser ",
    "Documents-to-upload": "Documentos a subir",
    "dont-have-account": "¿No tienes una cuenta?",
    "Dose": "Dosis",
    "Drag-and-drop-files": "Arrastra y suelta los archivos o clic para seleccionar",
    "Edit-active-ingredient": "Editar ingrediente activo",
    "Edit-follow-up-visit": "Editar visita de seguimiento",
    "Forget-password": "¿Olvidó su contraseña?",
    "Edit-brand": "Editar marca",
    "Edit-orchard": "Editar huerta",
    "Edit-Productor": "Editar Productor",
    "Edit-user": "Editar usuario",
    "Edit-variant-attribute": "Editar atributo de variante",
    "Edit-variant-value": "Editar valor de variante",
    "Edit-variety-attribute": "Editar atributo de variedad",
    "Edit-variation-value": "Editar valor de variación",
    "Edit-visit": "Editar visita",
    "Edit-warehouse": "Editar almacén",
    "Email": "Correo electrónico",
    "email-format-error": "El correo electrónico no tiene un formato válido",
    "English": "Inglés",
    "Engineer-in-charge": "Ingeniero a cargo",
    "Enter-company-email-here": "Ingresa tu email empresarial aquí",
    "Enter-email-here": "Ingresa tu email aquí",
    "Enter-first-name-here": "Ingresa tu nombre aquí",
    "Enter-last-name-here": "Ingresa tu apellido aquí",
    "Entry-date": "Fecha de entrega",
    "Estimated-end-date": "Fecha de fin estimada",
    "Estimated-quantity": "Cantidad estimada",
    "Estimated-start-date": "Fecha de inicio estimada",
    "Explain-reason-of-application": "Explica el motivo de la aplicación",
    "expired-certification-message": "La certificación ha expirado, por favor actualiza el documento",
    "Export-program": "Programa de exportación",
    "Exportation": "Exportación",
    "exportProgram": "Programa de exportación",
    "Ext-number": "Número exterior",
    "expired": "expirado",
    "Facade-picture": "Foto de la fachada",
    "FATHER": "Padre",
    "Field-visits": "Visitas de campo",
    "fileArchive": "Archivos",
    "fileArchive.appFiles": "archivos",
    "fileArchive.company": "empresa",
    "fileArchive.type": "tipo",
    "Files-should-be": "Los archivos deben ser ",
    "FileTypeError": "El tipo de archivo no es válido",
    "Fill-the-products-applied-in-the-sector": "Llena los productos aplicados en el sector",
    "filter-by": "Filtrar por",
    "Financing-profile": "Perfil de financiamiento",
    "financingProfile": "Perfil de financiamiento",
    "First-name": "Nombre",
    "Fiscal-Information": "Información fiscal",
    "flower-bud-maturity": "Maduración de flor",
    "foliarApplicationCreated": "Aplicación foliar creada",
    "foliarApplicationSaved": "Aplicación foliar guardada",
    "Foliar-application-damage": "Daño de la solicitud de foliar",
    "Foliar-application-date": "Fecha de solicitud de foliar",
    "Foliar-applications": "Aplicaciones foliares",
    "Follow-up": "Seguimiento",
    "Followup-track-orders": "Seguimiento de órdenes",
    "Followup-track-orders-description": "El usuario podrá realizar un seguimiento del estado del pedido en cualquier momento y editar el proceso.",
    "Follow-ups": "Seguimientos",
    "Follow-up-visits": "Visitas de seguimiento",
    "Forgot-password": "Olvidé mi contraseña",
    "General-and-fiscal-information": "Información general y fiscal",
    "General-data": "Datos generales",
    "General-Information": "Información general",
    "General-information-owner": "Información general (propietario)",
    "Get-Help": "Obtener ayuda",
    "good": "Bueno",
    "Great-see-again": "Me alegra volverte a ver!",
    "Greenhouses": "Huertas",
    "Ground-analysis": "Análisis de suelo",
    "Grower": "Productor",
    "Grower-confirm-message": "Si se confirma, se activarán todas las funciones de los productos y el estado cambiará a “Productor”",
    "Grower-created-successfully": "Productor creado correctamente",
    "Grower-disenroll-message": "¿Estás seguro que deseas dar de baja este productor?",
    "grower-role-description": "Aumenta tus ventas, encuentra nuevos mercados a nivel internacional y haz crecer tu producto.",
    "Harvest-documentation": "Documentación de la cosecha",
    "harvest-fruiting": "Cosecha (Fructificación)",
    "Has-data-plan": "¿Tiene plan de datos?",
    "Has-computer": "¿Tiene una computadora?",
    "Has-email": "¿Tiene un correo electrónico?",
    "Has-internet": "¿Tiene una conexión a internet en casa?",
    "Has-smartphone": "¿Tiene un teléfono inteligente?",
    "Height": "Altura",
    "Home": "Inicio",
    "HUSBAND": "Esposo",
    "I-agree-with-terms-privacy": "Acepto <0>Términos</0> y <1>Privacidad</1>",
    "ID here": "ID aquí",
    "ID Orchard": "ID de la huerta",
    "Images": "Imágenes",
    "Important": "Importante",
    "Infraestructure": "Infraestructura",
    "Ingredients": "Ingredientes",
    "Int-number": "Número interior",
    "Irrigation-system": "Sistema de riego",
    "isAdministrator": "Administrador?",
    "key-here": ".key aquí",
    "Language": "Idioma",
    "Last transaction": "Última transacción",
    "Last-name": "Apellido",
    "Last-Name": "Apellido",
    "Last-Name-here": "Apellido aquí",
    "LastName": "Apellido",
    "Location": "Ubicación",
    "Log in": "Iniciar sesión",
    "Log-in-google": "Iniciar con cuenta de Google",
    "login-here": "Inicia sesión aquí",
    "Machinery": "Maquinaria",
    "Macro-tunnel": "Túnel macro",
    "Madurity": "Maduración",
    "Manage-permissions": "Administrar permisos",
    "Maturity": "Maduración",
    "Marital-status": "Estado civil",
    "Marketplace": "Marketplace",
    "Mark-all-as-read": "Marcar todo como leído",
    "Married": "Casado",
    "Max-files-size": "Tamaño máximo de los archivos",
    "Max-size": "Tamaño máximo",
    "MaxFileSizeExceed": "El tamaño del archivo no debe exceder ",
    "missing-birthday-field": "Falta el campo de fecha de nacimiento",
    "missing-email-field": "Falta el campo email",
    "missing-lastname-field": "Falta el campo apellido",
    "missing-marital-status-field": "Falta el campo estado civil",
    "missing-name-field": "Falta el campo nombre",
    "missing-other-activities-field": "Falta el campo otras actividades",
    "missing-password-field": "Falta el campo contraseña",
    "More-actions": "Más acciones",
    "More-details": "Más detalles",
    "MOTHER": "Madre",
    "Municipality": "Municipio",
    "municipality": "municipio",
    "municipality.createdBy": "creado por",
    "municipality.createdDate": "fecha de creación",
    "municipality.name": "nombre",
    "municipality.neighbourhoods": "colonias",
    "municipality.state": "estado",
    "municipality.updatedBy": "actualizado por",
    "municipality.updatedDate": "fecha de actualización",
    "must-accept-terms-privacy": "Debes aceptar <0>Términos</0> y <1>Privacidad</1>",
    "Must-select-all-varieties": "Debes seleccionar todas las variedades",
    "Must-select-at-least-one-productor": "Debes seleccionar al menos un productor",
    "Must-select-the-base-product": "Debes seleccionar el producto base",
    "must-select-buyer-company": "Debes seleccionar una empresa compradora",
    "Must-select-boxes-per-pallet": "Debes seleccionar una caja por pallet",
    "Must-select-caliber": "Debes seleccionar un calibre",
    "Must-select-consistency": "Debes seleccionar una consistencia",
    "Must-select-brand": "Debes seleccionar una marca",
    "Must-select-delivery-date": "Debes seleccionar una fecha de entrega",
    "Must-select-handling": "Debes seleccionar un manejo",
    "Must-select-no-boxes": "Debes seleccionar cantidad de cajas",
    "Must-select-phenological-stage": "Debes seleccionar una etapa fenológica",
    "Must-select-price-per-box": "Debes seleccionar un precio por caja",
    "Must-select-product": "Debes seleccionar un producto",
    "Must-select-productor": "Debes seleccionar un productor",
    "must-select-seller-company": "Debes seleccionar una empresa",
    "Must-select-the-category-of-the-product": "Debes seleccionar la categoría de producto",
    "Must-write-name": "Debes escribir un nombre",
    "Must-write-caliber-comments": "Debes escribir un comentario para el calibre",
    "Must-write-consistency-comments": "Debes escribir un comentario para la consistencia",
    "My-account": "Mi cuenta",
    "My-brands": "Mis marcas",
    "My-Orders": "Mis Pedidos",
    "My-Requests": "Mis Solicitudes",
    "My-users": "Mis usuarios",
    "My-warehouses": "Mis almacenes",
    "Name": "Nombre",
    "Name-here": "Nombre aquí",
    "Neighborhood": "Colonia",
    "neighborhood": "colonia",
    "neighborhood.createdBy": "creado por",
    "neighborhood.createdDate": "fecha de creación",
    "neighborhood.municiaplity": "municipio",
    "neighborhood.name": "nombre",
    "neighborhood.updatedBy": "actualizado por",
    "neighborhood.updatedDate": "fecha de actualización",
    "neighborhood.zipCode": "código postal",
    "New-follow-up-visit": "Nueva visita de seguimiento",
    "New-Order": "Nueva Orden",
    "New-Productor": "Nuevo Productor",
    "No. boxes": "No. cajas",
    "not-enough-boxes-left": "No hay suficientes cajas disponibles",
    "notification": "notificación",
    "notification.config": "configuración",
    "notification.createdBy": "creada por",
    "notification.createdDate": "fecha de creación",
    "notification.groupName": "nombre del grupo",
    "notification.html": "html",
    "notification.link": "link",
    "notification.metadata": "metadata",
    "notification.notificationTopic": "grupo",
    "notification.quantity": "cantidad",
    "notification.readDate": "fecha de lectura",
    "notification.relatedUser": "usuario relacionado",
    "notification.updatedBy": "actualizada por",
    "notification.updatedDate": "fecha de actualización",
    "notification.user": "usuario",
    "notificationTopic": "tipo de notificación",
    "notificationTopic.description": "descripción",
    "notificationTopic.name": "nombre",
    "notificationTopic.permissions": "permisos",
    "Once-ODC-is-uploaded-status-will-change-to": "Una vez que se suba la ODC, el estado cambiará a",
    "Orchard": "Huerta",
    "orchard": "huerta",
    "Orchard date": "Fecha de la huerta",
    "Orchard-applications": "Aplicaciones de huerta",
    "Orchard-created-successfully": "Huerta creada correctamente",
    "Orchard-date": "Fecha de la huerta",
    "Orchard-name": "Nombre de la huerta",
    "orchard.address": "dirección",
    "orchard.company": "empresa",
    "orchard.createdBy": "creada por",
    "orchard.createdDate": "fecha de creación",
    "orchard.hectares": "hectáreas",
    "orchard.name": "nombre",
    "orchard.orchardVisits": "vistas",
    "orchard.photo": "Foto",
    "orchard.productOffers": "ofertas de productos",
    "orchard.updatedBy": "actualizada por",
    "orchard.updatedDate": "fecha de actualización",
    "Orchard-updated-successfully": "Huerta actualizada correctamente",
    "Orchard-sectors": "Sectores de la huerta",
    "Orchards": "Huertas",
    "Orchards-sectors-and-products": "Huertas, sectores y productos",
    "orchardVisit": "visita a la huerta",
    "orchardVisit.companyProduct": "producto",
    "orchardVisit.createdBy": "creado por",
    "orchardVisit.createdDate": "fecha de creación",
    "orchardVisit.orchard": "huerta",
    "orchardVisit.phenologicalStage": "etapa fenológica",
    "orchardVisit.shceduledBy": "programada por",
    "orchardVisit.status": "estado",
    "orchardVisit.updatedBy": "actualizado por",
    "orchardVisit.updatedDate": "fecha de actualización",
    "orchardVisit.userInCharge": "usuario a cargo",
    "Order-created": "Orden creada",
    "Order-percentage-completed": "Pedido {{percentage}}% completado",
    "Order-will-be-compleated-once-all-products-fulfill-their-quantity": "El pedido se completará una vez que todos los productos cumplan su cantidad",
    "order.cantrack": "Puedes seguir el estatus en cualquier momento",
    "order.created": "Orden creada correctamente",
    "other": "otro",
    "Other-activities": "Otras actividades",
    "Other-comments": "Otros comentarios",
    "Own-family": "Familia propia",
    "Own-vehicle": "Vehículo propio",
    "Packing-and-product-type": "Empaque y tipo de producto",
    "Packing-list": "Lista de empaque",
    "Packing-comments": "Comentarios de empaque",
    "Password": "Contraseña",
    "passwords-are-different": "Las contraseñas no coinciden",
    "Past-performance": "Rendimiento pasado",
    "Payment": "Pago",
    "PDF-Invoice": "Factura PDF",
    "Pendant": "Pendiente",
    "Pending": "Pendiente",
    "Pending-orchard-id": "Huerta nueva",
    "Performance": "Rendimiento",
    "permission": "permiso",
    "Permissions": "Permisos",
    "permission.createdBy": "creado por",
    "permission.createdDate": "fecha de creación",
    "permission.name": "nombre",
    "permission.roles": "roles",
    "permission.updatedBy": "actualizado por",
    "permission.updatedDate": "fecha de actualización",
    "permissionGroup": "grupo de permisos",
    "permissionGroup.code": "código",
    "permissionGroup.description": "descripción",
    "permissionGroup.name": "nombre",
    "permissionGroup.permissions": "permisos",
    "Person-type": "Tipo de persona",
    "Phenological-Stage": "Etapa fenológica",
    "Phone": "Teléfono",
    "Plague-demage": "Daño por plaga",
    "plant-development": " Desarrollo Vegetal",
    "Please-enter-a-dose": "Por favor ingresa una dosis",
    "Please-enter-a-name-for-the-variant": "Por favor, introduzca un nombre para la variante",
    "Please-enter-a-reason": "Por favor ingresa una razón",
    "Please-select-a-date": "Por favor, seleccione una fecha",
    "Please-select-a-productor": "Por favor, seleccione un productor",
    "Please-select-a-unit": "Por favor, seleccione una unidad",
    "Please-select-an-active-ingredient": "Por favor, seleccione un ingrediente activo",
    "Please-select-an-orchard": "Por favor, seleccione una huerta",
    "Please-select-the-orchard": "Por favor, seleccione la huerta",
    "Please-update-the-document": "Por favor, actualice el documento",
    "Phenological-stage": "Etapa fenológica",
    "Postal-code": "Código postal",
    "Price": "Precio",
    "Price-per-box": "Precio por caja",
    "Product": "Producto",
    "product": "producto",
    "baseProduct": "Producto base",
    "Product-created-successfully": "Producto creado correctamente",
    "Product-code": "Código de producto",
    "Product-details": "Detalles del producto",
    "Product-follow-up": "Seguimiento del producto",
    "product.createdBy": "creado por",
    "product.createdDate": "fecha de creación",
    "product.name": "nombre",
    "product.productCategory": "categoría",
    "product.productCode": "código",
    "Product-saved": "Producto guardado",
    "Product-type": "Tipo de producto",
    "product.updatedBy": "actualizado por",
    "product.updatedDate": "fecha de actualización",
    "Product-saved-successfully": "Producto guardado correctamente",
    "productCategory": "categoría de producto",
    "productCategory.createdBy": "creado por",
    "productCategory.createdDate": "fecha de creación",
    "productCategory.name": "nombre",
    "productCategory.products": "productos",
    "productCategory.updatedBy": "actualizado por",
    "productCategory.updatedDate": "fecha de actualización",
    "productOffer": "Oferta",
    "productOffer.buyerCompany": "empresa compradora",
    "productOffer.buyerSignature": "firma del comprador",
    "productOffer.companyProduct": "producto",
    "productOffer.contractFile": "contrato",
    "productOffer.createdBy": "creado por",
    "productOffer.createdDate": "fecha de creación",
    "productOffer.description": "descripción",
    "productOffer.filledByProductOrders": "órdenes hijas",
    "productOffer.fillingOrder": "orden padre",
    "productOffer.interestedCompanies": "empresas interesadas",
    "productOffer.offerCode": "código",
    "productOffer.orchard": "huerta",
    "productOffer.productOfferUpdates": "feed de la oferta",
    "productOffer.sellerCompany": "empresa vendedora",
    "productOffer.sellerSignature": "firma del vendedor",
    "productOffer.status": "estado",
    "productOffer.updatedBy": "actualizado por",
    "productOffer.updatedDate": "fecha de actualización",
    "productOfferUpdate": "actualización de oferta",
    "productOfferUpdate.comments": "comentarios",
    "productOfferUpdate.createdBy": "creado por",
    "productOfferUpdate.createdDate": "fecha de creación",
    "productOfferUpdate.fileArchive": "archivos",
    "productOfferUpdate.productOffer": "oferta de producto",
    "productOfferUpdate.type": "tipo",
    "productOfferUpdate.updatedBy": "actualizado por",
    "productOfferUpdate.updatedDate": "fecha de actualización",
    "Productor": "Productor",
    "Productor-confirmed-successfully": "Productor confirmado correctamente",
    "Productor-deleted-successfully": "Productor eliminado correctamente",
    "Productor-disenrolled-successfully": "Productor dado de baja correctamente",
    "Productor-name": "Nombre del productor",
    "Productors": "Productores",
    "Products": "Productos",
    "Products-to-be-ordered": "Productos a pedir",
    "productType": "tipo de producto",
    "productType.createdBy": "createdBy",
    "productType.createdDate": "createdDate",
    "productType.name": "name",
    "productType.updatedBy": "updatedBy",
    "productType.updatedDate": "updatedDate",
    "productVariation": "variación del producto",
    "productVariation.createdBy": "creado por",
    "productVariation.createdDate": "fecha de creación",
    "productVariation.name": "nombre",
    "productVariation.updatedBy": "actualizado por",
    "productVariation.updatedDate": "fecha de actualización",
    "Profile-image": "Imagen de perfil",
    "Properties": "Propiedades",
    "Quality": "Calidad",
    "Quantity": "Cantidad",
    "Quick-explanation-sector": "Breve explicación de lo que es un sector",
    "Reason": "Razón",
    "re-send-confirmation-email": "Reenviar correo de confirmación",
    "References": "Referencias",
    "register-success": "Registro exitoso",
    "REGISTRY_STATUS_DISENROLL": "Dado de baja",
    "REGISTRY_STATUS_PENDING": "Pendiente",
    "REGISTRY_STATUS_PRODUCER": "Productor",
    "REGISTRY_STATUS_PROSPECT": "Prospecto",
    "regular": "Regular",
    "Replace-file": "Reemplazar archivo",
    "Resume": "Resumen",
    "RFC": "RFC",
    "role": "rol",
    "role.assignable": "asignable",
    "role.createdBy": "creado por",
    "role.createdDate": "fecha de creación",
    "role.name": "nombre",
    "role.permissions": "permisos",
    "role.title": "título",
    "role.updatedBy": "actualizado por",
    "role.updatedDate": "fecha de actualización",
    "role.users": "usuarios",
    "RPPyC-legal-representant": "RPPyC Representante legal",
    "RPPyC-registration": "Registro RPPyC",
    "Save": "Guardar",
    "Save-and-continue": "Guardar y continuar",
    "Save-and-continue-later": "Guardar y continuar más tarde",
    "Save-changes": "Guardar cambios",
    "Saved-changes": "Cambios guardados correctamente",
    "Search-by-name": "Buscar por nombre",
    "Search-by-name, email, id": "Buscar por nombre, email o ID",
    "Search-by-name-or-id": "Buscar por nombre o ID",
    "Search-by-name, state": "Buscar por nombre o estado",
    "Search-by-name-product": "Buscar por nombre o producto",
    "Sector-created-successfully": "Sector creado correctamente",
    "Sector-details": "Detalles del sector",
    "Sector-name": "Nombre del sector aquí",
    "See": "Ver",
    "See-file": "Ver archivo",
    "Search-by-orchard-name": "Buscar por nombre de huerta",
    "Select the sectors to which you want to add the foliar application": "Selecciona los sectores a los que quieres agregar la aplicación foliar",
    "Select-any-option": "Seleccione",
    "Select-client": "Selecciona un cliente",
    "select-language": "Selecciona tu idioma",
    "Select-municipality": "Selecciona el municipio",
    "Select-neighborhood": "Selecciona la colonia",
    "Select-No-boxes": "Selecciona No. de cajas",
    "Select-permissions-to-grant": "Selecciona los permisos a otorgar",
    "Select-a-orchard": "Selecciona una huerta",
    "Select-a-sector": "Selecciona un sector",
    "Select-a-user": "Selecciona un usuario",
    "Select-the-date-of-the-visit": "Selecciona la fecha de la visita",
    "Productor-devlivery-date": "Fecha de entrega del productor",
    "Select-state": "Selecciona el estado",
    "Select-status": "Selecciona el estatus",
    "Select-type": "Seleccionar tipo",
    "Select...": "Selecciona...",
    "Separated": "Separado",
    "Serial": "Serial",
    "Settings": "Configuración",
    "Shipment-details": "Detalles del envío",
    "Sign-up": "Registro",
    "sign-up-free": "Regístrate sin costo aquí",
    "Single": "Soltero",
    "SISTER": "Hermana",
    "Socio-economic-information": "Información socioeconómica",
    "SON": "Hijo",
    "Spanish": "Español",
    "State": "Estado",
    "state": "estado",
    "state.code": "código",
    "state.country": "país",
    "state.createdBy": "creado por",
    "state.createdDate": "fecha de creación",
    "state.municipalities": "municipios",
    "state.name": "nombre",
    "state.updatedBy": "actualizado por",
    "state.updatedDate": "fecha de actualización",
    "Status": "Estatus",
    "STATUS_ACCEPTED": "Aceptado",
    "STATUS_PENDING": "Pendiente",
    "Street": "Calle",
    "Surface": "Superficie",
    "Tax-system": "Régimen fiscal",
    "Telephone": "Teléfono",
    "The-attribute-name-is-required-for-all-languages": "El atributo es requerido para todos los idiomas",
    "The-attribute-was-created-successfully": "El atributo fue creado correctamente",
    "The-value-was-created-successfully": "El valor fue creado correctamente",
    "This-data-will-be-lost": "Esta información se perderá",
    "Types": "Tipos",
    "Units": "Unidades",
    "Units-per-box": "Unidades por caja",
    "Updated-successfully": "Actualizado correctamente",
    "Upload": "Cargar",
    "Upload-buying-order": "Subir orden de compra",
    "Upload-image": "Subir imagen",
    "Uploaded-by": "Subido por",
    "Use-of-technology": "Uso de tecnología",
    "Use-whatsapp": "Usar WhatsApp",
    "User": "Usuario",
    "user": "Usuario",
    "user.company": "empresa",
    "user.createdBy": "creado por",
    "Users": "Usuarios",
    "users.created.success": "Usuario creado correctamente",
    "user.createdDate": "fecha de creación",
    "user.disabledNotificationTopics": "grupo de notificaciones deshabilitadas",
    "user.email": "correo",
    "user.emailValidationDate": "fecha de validación de correo electrónico",
    "user.emailValidationToken": "token de validación de correo electrónico",
    "user.isActive": "está activo",
    "user.lastName": "apellido",
    "user.locale": "idioma",
    "user.name": "nombre",
    "user.notifications": "Notificaciones",
    "user.orchardVisits": "visitas a huertas",
    "user.password": "contraseña",
    "user.permissions": "permisos",
    "user.role": "rol",
    "user.scheduledOrchardVisits": "visitas a huertas programadas",
    "user.update.success": "Usuario actualizado correctamente",
    "user.updatedBy": "actualizado por",
    "user.updatedDate": "fecha de actualización",
    "user.username": "nombre de usuario",
    "User-activated": "Usuario activado",
    "User-created-successfully": "Usuario creado correctamente",
    "User-disabled": "Usuario deshabilitado",
    "User-permissions": "Permisos de usuario",
    "User-permissions-description": "Proporcione a los miembros permisos básicos y funcionalidades de forma predeterminada. Personalice la configuración para todos los miembros o realice ajustes locales para usuarios individuales en sus perfiles.",
    "User-permissions-updated-successfully": "Permisos de usuario actualizados correctamente",
    "Value": "Valor",
    "Values": "Valores",
    "Variants": "Variantes",
    "Variations": "Variaciones",
    "Variety": "Variedad",
    "Varieties": "Variedades",
    "very-bad": "Muy mala",
    "very-good": "Muy bueno",
    "Visit-date": "Fecha de visita",
    "Visit-details": "Detalles de la visita",
    "visit.cantrack": "No se puede rastrear en cualquier momento",
    "visit.created": "Visita creada",
    "Visited": "Visitado",
    "Visits": "Visitas",
    "Visit-deleted-successfully": "Visita eliminada correctamente",
    "Warehouse": "Almacén",
    "Warehouses": "Almacenes",
    "Warehouse-created-successfully": "Almacén creado correctamente",
    "Warehouse-updated-successfully": "Almacén actualizado correctamente",
    "Water-analysis": "Análisis de agua",
    "We-believe-we-can-login-text": "Experiential & Promotional Marketing. Producción de eventos, activaciones, promociones. Soluciones.",
    "What-your-role": "¿Cuál será tu rol?",
    "Wholesale": "Distribuidor",
    "wholesale-role-description": "Encuentra los mejores precios con los productores locales, mejora tus canales de comunicación y aumenta las ventas.",
    "Widowed": "Viudo",
    "WIFE": "Esposa",
    "yes": "Sí",
    "Yes": "Sí",
    "you-logged-in": "Has iniciado sesión :)",
    "You-must-add-the-email": "Debes agregar el correo electrónico",
    "You-must-add-the-name-of-the-company": "Debes agregar el nombre de la empresa",
    "You-need-to-Add-a-new-Sector-to-add-products": "Debes agregar un nuevo sector para agregar productos",
    "XML-Invoice": "Factura XML",
}

export default translations;
