import React, {useCallback, useState, useContext, useEffect } from 'react';
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../components/Dashboard/TopBar/TopBar';
import Card from '../../../../components/Dashboard/Card/Card';
import Button from '../../../../components/molecules/Button/Button';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../../../services/api/api-config';
import { getNotifier } from '../../../../services/notifier';
import { paths } from '../../../../services/routes/appRoutes';
import { useNavigate } from 'react-router-dom';
import MultipleFileUpload, {PreviewImage} from '../../../../components/forms/MultipleFileUpload/MultipleFileUpload';
import useFormState from '../../../../hooks/useFormState';
import { campaignFilesSGroups, getCampaignPictures } from '../CampaignUtils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../../components/Modal/Modal';
import "./CampaignFiles.scss";

const imageFileTypes = ['JPG', 'PNG', 'GIF'];
const maxFileSizeInMB = 50;

export default function CampaignFiles() {
  const { id } = useParams();
  const api = useContext(ApiContext);
  const { form, setForm } = useFormState({pictures: []});
  const [campaign, setCampaign] = useState(null);
  const [arrayPictures, setArrayPictures] = useState([]);
  const [currentPictures, setCurrentPictures] = useState([]);
  const [currentFileId, setCurrentFileId] = useState(null);
  const navigate = useNavigate();

  const customProp = 'materialFileDelete';

  /**
   * Handle on change pictures
   * 
   */
  const onChangePictures = useCallback((newFiles) => {
    setForm({pictures: newFiles});
    setArrayPictures(newFiles);
  }, [setForm]);

  /**
   * Delete added file
   * 
   */
  const onDelete = useCallback((index) => {
    const newPictures = arrayPictures.filter((picture, i) => i !== index);
    setForm({...form, pictures: newPictures});
    setArrayPictures(newPictures);
  }, [arrayPictures, form, setForm]);

  /**
   * Load files related to campaign
   */
  const loadFiles = useCallback(() => {
    api.campaigns.get({id, params: {sGroups: campaignFilesSGroups}}).then(response => {
      setCampaign(response);
      let responsePictures = [];
      response.materialFiles.forEach(file => {
        responsePictures.push(file);
      });
      setCurrentPictures(responsePictures);
    }).catch(error => getNotifier().error(error.detail || error.message));
  }, [api.campaigns, id]);

  
  useEffect(() => {
    if (!campaign) {
      loadFiles();
    }
  }, [campaign, loadFiles]);

  useEffect(() => {
    const handleDragOver = (event) => {
      event.preventDefault();
    }

    const handleDrop = (event) => {
      event.preventDefault();
    }

    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);
  
    return () => {
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
    }
  }, [])

  /**
   * Save files added to campaign
   */
  const saveCampaignFiles = useCallback(() => {
    let pictures = null;
    pictures = getCampaignPictures(form);
    pictures.forEach(picture => {
      api.materialFiles.create(
        {
          params: {
            campaign: id
          },
          files: picture
        }
      ).then(fileResponse => {
        getNotifier().success('Archivo(s) agregados correctamente');
      }).catch(error => getNotifier().error(error.detail || error.message));
    });
  }, [api.materialFiles, form, id]);

  /**
   * Handle delete file from server
   */
  const onDeleteCurrentFile = useCallback(fileId => {
    setCurrentFileId(fileId);
  }, []);

  /**
   * Delete file from server
   */
  const deleteCurrentFile = useCallback(() => {
    api.materialFiles.delete({id: currentFileId, customProp}).then(deleteResponse => {
      setCurrentFileId(null);
      loadFiles();
    }).catch(error => {
      setCurrentFileId(null);
      loadFiles(); /*getNotifier().error(error.detail || error.message)*/
    });
  }, [api.materialFiles, currentFileId, loadFiles]);

  /**
   * Handle quote click
   */
  const handleQuote = useCallback(() => {
    navigate(paths.campaignQuotes.replace(':id', id));
  }, [id, navigate]);

  return (
    <div className='CampaignFiles'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              Archivos de la Campaña
            </SectionTitle>
            <Button
              className={'btn-primary btn-outline'}
              onClick={() => navigate(paths.campaignDetails.replace(':id', id))}
              design={'clear'}
            >
              Volver
            </Button>
          </div>

          <div className="row mt-3">
            <Card>
              <div className='d-flex justify-content-between'>
                {
                  campaign &&
                  <h3>{campaign.name}</h3>
                }
              </div>
              <div className="row">&nbsp;</div>
              <div className='row'>
                <div className='col-12'>
                  <div>
                    <div>Tipos de archivos: {imageFileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}</div>
                    <div>Tamaño máximo: {maxFileSizeInMB} MB</div>
                  </div>
                  <MultipleFileUpload
                    fileTypes={imageFileTypes}
                    multiple={true}
                    legend={'Images'}
                    onChange={files => onChangePictures(files)}
                    files={form.pictures}
                    maxFileSizeInMB={maxFileSizeInMB}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col container-files">
                  <div className="row">
                    <div className="col-12">
                      <div className='image-container-preview'>
                        {
                          currentPictures.map((picture) => 
                            <div
                              className='image-preview'
                              key={picture.image.id}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="trash-icon cursor-pointer"
                                onClick={() => onDeleteCurrentFile(picture.id)}
                                title="Eliminar"
                              />
                              <img 
                                src={api.appFiles.getUrl({id: picture.image?.id})} 
                                width="100"
                                alt={picture.image ? picture.image.name : ''}
                              />
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className='col-12'>
                      {arrayPictures.map((picture, index) => 
                        picture.id
                        ? ''
                        : <PreviewImage key={index} picture={picture} onDelete={() => onDelete(index)} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className="row mt-4">
              <div className="col mt-4 d-flex justify-content-between">
                <Button 
                  className={`btn-primary btnNextStep`}
                  onClick={saveCampaignFiles}
                >
                  Guardar
                </Button>
                <Button 
                  className={`btn-secondary btnNextStep`}
                  onClick={handleQuote}
                  design="secondary"
                >
                  Cotizar
                </Button>
              </div>
            </div>
          </div>
          {
            currentFileId !== null &&
            <Modal
              title="Confirmar Eliminación"
              mainButtonText="Confirmar"
              mainButtonColor='danger'
              mainButtonAction={deleteCurrentFile}
              secondaryButtonText={'Cancelar'}
              secondaryButtonAction={() => {
                  setCurrentFileId(null);
              }}
              onClose={() => setCurrentFileId(null)}
            >
              <div className='GrowerChangeStatusButtons__modal-content'>
                <div className='GrowerChangeStatusButtons__modal-content-title'>
                  ¿Está seguro de eliminar el archivo?
                </div>
              </div>
            </Modal>
          }
        </div>
      </div>
    </div>
  )
}
