import React, {useCallback, useState, useMemo, useContext} from 'react'
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle';
import { SearchTextbox } from '../../../../components/forms/SearchTextbox/SearchTextbox';
import Button from '../../../../components/molecules/Button/Button';
import TideReactTable from '../../../../components/utility/TideReactTable/TideReactTable';
import useTideTable from '../../../../components/utility/TideReactTable/useTideTable';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../services/routes/appRoutes';
import {getCatMaterialStatusTableColumns, catMaterialStatusTableSGroups} from './CatMaterialStatusUtils';
import Modal from '../../../../components/Modal/Modal';
import { getNotifier } from '../../../../services/notifier';
import { ApiContext } from '../../../../services/api/api-config';
import "./CatMaterialStatus.scss"

export default function CatMaterialStatus() {

  const [textFilter, setTextFilter] = useState('');
  const [showingDeleteModalConfirm, setShowingDeleteModalConfirm] = useState();
  const [idToDelete, setIdToDelete] = useState(0);
  const navigate = useNavigate();
  const customProp = 'materialStatus';

  const api = useContext(ApiContext);

  /**
   * Handle search text input
   */
  const handleSearchTextChange = useCallback((e) => {
    setTextFilter(e.target.value);
  }, []);

  /**
   * Search filter
   */
  const searchFilter = useMemo(() => {
    return {
      search: textFilter
    }
  }, [textFilter]);

  /**
   * Request filters
   */
  const requestFilters = useMemo(() => ({
    sGroups: catMaterialStatusTableSGroups,
    ...searchFilter
  }), [searchFilter]);

  /**
   * Show modal on remove click
   */
  const onRemoveClick = useCallback((id) => {
    setIdToDelete(id);
    setShowingDeleteModalConfirm(true);
  }, []);

  /**
   * Handle remove confirm
   */
  const handleRemove = useCallback(() => {
    if (idToDelete > 0) {
      api.catMaterialStatuses.delete(
        {id: idToDelete}
      ).then(response => {
        setIdToDelete(0);
        setShowingDeleteModalConfirm(false);
        getNotifier().success('Registro eliminado correctamente');
      })
      .catch(() => {
        getNotifier().error('Ocurrió un error al eliminar el registro');
        setIdToDelete(0);
      });
    }
  }, [api.catMaterialStatuses, idToDelete]);

  /**
   * Close modal on cancel
   */
  const closeModal = useCallback(() => {
    setIdToDelete(false);
    setShowingDeleteModalConfirm(false);
  }, []);

  /**
   * Get material status table columns
   */
  const catMaterialStatusTableColumns = useMemo(() => getCatMaterialStatusTableColumns(
    { onRemoveClick }
  ), [onRemoveClick]);

  const requestOptions = useMemo(() => ({ customProp }), []);

  /**
   * Create tide table
   */
  const table = useTideTable({
    entity: 'catMaterialStatuses',
    columns: catMaterialStatusTableColumns,
    requestFilters,
    requestOptions
  });

  const debouncedChangeHandler = debounce(handleSearchTextChange, 400);

  const handleNewMaterialStatus = useCallback( () => navigate(paths.catMaterialStatusForm), [navigate]);

  return (
    <div className='CatMaterialStatus'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <SectionTitle>
            ESTATUS DE MATERIALES
          </SectionTitle>
          <div className='row'>
            <div
              className='col d-flex justify-content-between align-items-center gap-3'
            >
              <SearchTextbox
                placeholder="Búsqueda por nombre"
                onChange={debouncedChangeHandler}
              />
              <Button 
                className={'submit btnNewMaterialStatus'} 
                type='button' 
                onClick={handleNewMaterialStatus}
                id="add-new-material-status"
              >
                + Agregar
              </Button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="">
              <TideReactTable 
                {...table.tableProps} 
                className="products-table"
              />
            </div>
          </div>
          {
            showingDeleteModalConfirm &&
            <Modal
              title="Confirmar Eliminación"
              mainButtonText="Confirmar"
              mainButtonColor='danger'
              mainButtonAction={handleRemove}
              secondaryButtonText={'Cancelar'}
              secondaryButtonAction={() => {
                  setShowingDeleteModalConfirm(false);
                  setIdToDelete(0);
              }}
              onClose={closeModal}
            >
              <div className='GrowerChangeStatusButtons__modal-content'>
                <div className='GrowerChangeStatusButtons__modal-content-title'>
                  ¿Está seguro de eliminar el registro?
                </div>
              </div>
            </Modal>
          }
        </div>
      </div>
    </div>
  )
}
