import { Link } from "react-router-dom";
import { paths } from "../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';

export const getCampaignApprovedQuoteTableColumns = () => [
    {
      Header: 'CAMPAÑA',
      accessor: campaign =>
        <div className="text-center">
          <span>
            {campaign.name}
          </span>
        </div>
    },
    {
      Header: 'ESTATUS',
      accessor: campaign =>
        <div className="text-center">
          <span>
            {campaign.status}
          </span>
        </div>
    },
    {
      Header: 'FECHA DE CREACIÓN',
      accessor: campaign =>
        <div className="text-center">
          <span>
            {moment(campaign.createdDate).format( generalDatesFormat )}
          </span>
        </div>
    },
    {
        Header: 'ACCIONES',
        accessor: campaign => 
        <span
          className='ActionSee d-flex justify-content-center align-items-center' 
        >
          <Link
              to={ paths.workOrderDetails.replace(":id", campaign.id) }
              className="m-2 details-link"
              title="Ver Detalles"
          >
              <FontAwesomeIcon icon={faEye}/>
          </Link>
        </span>
    }
  ];

  export const campaignWithDetailsSGroups = [
    'campaign_read',
    'campaign_read_campaign_details',
    'campaign_detail_read',
    'campaign_detail_read_store',
    'store_read',
    'campaign_read_vendor_work_order',
    'vendor_work_order_read'
  ];

  export const vendorWorkOrderSGroups = [
    'vendor_work_order_read',
    'vendor_work_order_read_campaign',
    'campaign_read',
    'vendor_work_order_read_store',
    'store_read',
    'vendor_work_order_read_cat_vendor',
    'cat_vendor_read'
  ];