import Login from "../../scenes/public/Login/Login";
import ForgotPassword from "../../scenes/public/ForgotPassword/ForgotPassword";
import ResetPassword from "../../scenes/public/ResetPassword/ResetPassword";

export const paths={
    login:'/login',
    forgotPassword: '/recover_password',
    resetPassword: '/reset_password',

};

const notLoggedRoutes=[
    {
        path:paths.login,
        Component: Login
    },
    {
        path:paths.forgotPassword,
        Component: ForgotPassword
    },
    {
        path:paths.resetPassword,
        Component: ResetPassword
    }
];

export default notLoggedRoutes;
