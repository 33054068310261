import React, {useMemo} from 'react'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import Card from '../../../components/Dashboard/Card/Card';
import "./WorkOrder.scss"
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import useFormState from '../../../hooks/useFormState';
import useTideTable from '../../../components/utility/TideReactTable/useTideTable';
import TideReactTable from '../../../components/utility/TideReactTable/TideReactTable';
import { campaignWithDetailsSGroups, getCampaignApprovedQuoteTableColumns } from './WorkOrderUtils';

export default function WorkOrder() {
  const {form, setForm} = useFormState({name: ''});
  const customProp = 'campaignList';
  
  /**
   * Get quote columns for table
   */
  const campaignQuoteTableColumns = useMemo(() => getCampaignApprovedQuoteTableColumns(), []);

  const requestOptions = useMemo(() => ({ customProp }), []);

  /**
   * Request filters
   */
  const requestFilters = useMemo(() => ({
    sGroups: campaignWithDetailsSGroups,
    search: form.name,
    'order[id]': 'desc'
  }), [form.name]);

  /**
   * Create campaign quotes tide table
   */
  const table = useTideTable({
    entity: 'campaigns',
    columns: campaignQuoteTableColumns,
    requestFilters,
    requestOptions
  });  

  return (
    <div
      className='WorkOrder'
    >
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              ÓRDENES DE TRABAJO
            </SectionTitle>
          </div>
          <div className="row mt-3">
            <Card>
              <div className='d-flex justify-content-between align-items-center gap-3 mt-3 mb-3'>
                <SearchTextbox
                    placeholder="Búsqueda"
                    value={form.name}
                    onChange={e => setForm({name: e.target.value})}
                />
              </div>
              <div>
                {
                  table.tableProps &&
                  <TideReactTable 
                    {...table.tableProps}
                    className="quote-table"
                  />
                }
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
