import React, {useEffect, useContext, useCallback, useState, useMemo, useRef} from 'react'
import { useParams } from 'react-router-dom';
import { getNotifier } from '../../../../../services/notifier';
import { ApiContext } from '../../../../../services/api/api-config';
import  useFormState from '../../../../../hooks/useFormState';
import { SideMenu } from '../../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import Card from '../../../../../components/Dashboard/Card/Card';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import Button from '../../../../../components/molecules/Button/Button';
import { paths } from '../../../../../services/routes/appRoutes';
import useTideTable from "../../../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../../../components/utility/TideReactTable/TideReactTable";
import _ from "lodash";
import { getStoreMaterialsTableColumns, storeMaterialSGroups, prepareStoreMaterialToServer } from '../../StoreUtils';
import Modal from '../../../../../components/Modal/Modal';
import "./StoreMaterials.scss";

export default function StoreMaterials() {

  const { id } = useParams();
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materialType, setMaterialType] = useState('');
  const [active, setActive] = useState(null)
  const [store, setStore] = useState(null)
  const { form, setForm, bindInput } = useFormState();
  const [showingDeleteModalConfirm, setShowingDeleteModalConfirm] = useState();
  const [idToDelete, setIdToDelete] = useState(0);
  const [materialEdit, setMaterialEdit] = useState(null)
  const api = useContext(ApiContext);
  const topPageRef = useRef(null)

  const customProp = 'materiales';

  /**
   * Scroll to bottom of the page
   */
  const scrollToTop = () => {
    topPageRef.current?.scrollIntoView({behavior: "smooth"})
  }

  /**
   * Get store info
   */
  const getStore = useCallback(() => {
    api.stores.get({id}).then(response => {
      setStore(response);
    }).catch(() => {
      getNotifier().error('Ocurrió un error al obtener los tipos de material');
    });
  }, [api, id]);

  /**
   * Clear form
   */
  const clearForm = useCallback(() => {
    setForm({
      sizes: ''
    });
    setMaterialType('');
    setActive(null);
    setMaterialEdit(null);
  }, [setForm]);

  useEffect(() => {
    api.catMaterialTypes.get().then(response => {
      setMaterialTypes(response);
      if (!store) {
        getStore();
      }
    }).catch(() => {
      getNotifier().error('Ocurrió un error al obtener los tipos de material');
    });
  }, [api, getStore, id, store]);

  /**
   * Remove material
   */
  const onRemoveClick = useCallback((id) => {
    setIdToDelete(id);
    setShowingDeleteModalConfirm(true);
  },[]);

  /**
   * Fill form with material info for edition
   */
  const onEdit = useCallback((id) => {
    scrollToTop();
    api.materials.get(
      {
        id: id.toString(),
        customProp,
        params: {
          sGroups: storeMaterialSGroups
        }
      }  
    ).then(response => {
      setForm({
        sizes: response.sizes
      });
      setMaterialType(response.catMaterialType.id);
      setActive(response.active ? '1' : '2');
      setMaterialEdit(response);
    }).catch(error => {
      getNotifier().error(error.message||error.detail);            
    });
  },[api, setForm]);

  /**
   * Handle change of material type
   */
  const handleChange = useCallback((value) => {
    setMaterialType(value);
  }, []);

  /**
   * Handle change select active
   */
  const handleChangeActive = useCallback((value) => {
    setActive(value);
  }, []);

  /**
   * Get store materials table columns
   */
  const storeMaterialTableColumns = useMemo(() => getStoreMaterialsTableColumns({onRemoveClick, onEdit, editingId: materialEdit?.id}), [materialEdit?.id, onEdit, onRemoveClick]);

  /**
   * Request filters
   */
  const requestFilters = useMemo(() => ({
    'store.id': id,
    sGroups: storeMaterialSGroups,
  }), [id]);

  /**
   * Create materials tide table
   */
  const table = useTideTable({
    entity: 'materials',
    columns: storeMaterialTableColumns,
    requestFilters
  });

  /**
   * Handle add material
   */
  const handleAddMaterial = useCallback(async (value) => {
    const method = materialEdit ? 'update' : 'create';
    let material = null;
    try {
      material = prepareStoreMaterialToServer(form, active, materialType, store, materialEdit);    
    } catch (error) {
      return getNotifier().error(error.message||error.detail);
    }

    api.materials[method](
      {
        id: materialEdit ? materialEdit.id : undefined ,
        params: material, customProp
      }
    )
      .then(() => {
        getNotifier().success('Se agregó el material correctamente');
        table.reload();
        clearForm();
      })
      .catch(error => {
        getNotifier().error(error.message||error.detail);                
      });
  }, [active, api.materials, clearForm, form, materialEdit, materialType, store, table]);
  
  /**
   * Handle remove confirm
   */
  const handleRemove = useCallback(() => {
    if (idToDelete > 0) {
      api.materials.delete(
        {id: idToDelete}
      ).then(response => {
        setIdToDelete(0);
        setShowingDeleteModalConfirm(false);
        getNotifier().success('Registro eliminado correctamente');
      })
      .catch(() => {
        getNotifier().error('Ocurrió un error al eliminar el registro');
        setIdToDelete(0);
      });
    }
  }, [api, idToDelete]);

  /**
   * Close modal on cancel
   */
  const closeModal = useCallback(() => {
    setIdToDelete(false);
    setShowingDeleteModalConfirm(false);
  }, []);

  return (
    <div className="StoreMaterials dashboard-container">
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          {/* REF for page top */}
          <div ref={topPageRef}/>
          <div className='d-flex justify-content-between'>
            <SectionTitle>
              Catálogo de espacios en tienda
            </SectionTitle>
            <SectionTitle>
              {store?.name}
            </SectionTitle>
          </div>
          <div className='row'>
            <Card className='OrderStep1'>
              {
                materialEdit &&
                <div className='d-flex justify-content-between mb-4'>
                  <div>
                    <h3>
                      Edición de material
                    </h3>
                  </div>
                </div>
              }
              <div className='row'>
                <div className="col">
                  <select
                    className="form-select"
                    value={materialType || ''}
                    onChange={(e) => handleChange(e.target.value)}
                  >
                    <option
                        className='font-bold'
                        key={0}
                        value=""
                    >
                        Seleccionar Tipo de material
                    </option>
                    {_.map(materialTypes, (option) => (
                        <option
                            className='font-bold'
                            key={option.id}
                            value={option.id}
                        >
                            {option.name} | {option.description}
                        </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col col-md-6">
                  <TextInput
                    label="Medidas"
                    placeholder=""
                    {...bindInput('sizes')}
                  />
                </div>
                <div className="col col-md-6">
                  <label htmlFor="active">
                    ¿Activo?
                  </label>
                  <select
                      className="form-select"
                      value={active || ''}
                      onChange={(e) => handleChangeActive(e.target.value)}
                    >
                      <option
                          className='font-bold'
                          key={0}
                          value=""
                      >
                        Seleccionar
                      </option>
                      <option
                          className='font-bold'
                          key={1}
                          value="1"
                      >
                          Si
                      </option>
                      <option
                          className='font-bold'
                          key={2}
                          value="2"
                      >
                          No
                      </option>
                    </select>
                </div>
              </div>
                      
              <div className='d-flex justify-content-between align-items-center buttons-container'>
                <Button
                  className={'btn-primary btn-outline'}
                  onClick={() => window.location.href = paths.store }
                  design={'clear'}
                >
                  Volver
                </Button>
                <div className='d-flex'>
                  {
                    materialEdit &&
                    <Button
                      className={'btn-primary btn-outline mr-4'}
                      onClick={clearForm}
                      design={'clear'}
                    >
                      Cancelar
                    </Button>
                  }
                  <Button
                    className={'btn-primary btn-outline'}
                    onClick={handleAddMaterial}
                  >
                    {materialEdit ? 'Actualizar' : 'Guardar'}
                  </Button>
                </div>
              </div>
            </Card>
          </div>
          <div className='row'>
            <TideReactTable 
              {...table.tableProps}
              className="materials-table"
            />
          </div>
        </div>
        {
          showingDeleteModalConfirm &&
          <Modal
            title="Confirmar Eliminación"
            mainButtonText="Confirmar"
            mainButtonColor='danger'
            mainButtonAction={handleRemove}
            secondaryButtonText={'Cancelar'}
            secondaryButtonAction={() => {
                setShowingDeleteModalConfirm(false);
                setIdToDelete(0);
            }}
            onClose={closeModal}
          >
            <div className='GrowerChangeStatusButtons__modal-content'>
              <div className='GrowerChangeStatusButtons__modal-content-title'>
                ¿Está seguro de eliminar el registro?
              </div>
            </div>
          </Modal>
        }
      </div>
    </div>
  )
}
