import React from 'react'
import NotificationsBar from './components/NotificationsBar/NotificationsBar';
import UserInfo from './components/UserInfo/UserInfo';

import './TopBar.scss';

const TopBar = () => {
    return (
        <div className='TopBar'>
            <div className='TopBar__flexEnd'>
                <NotificationsBar />
            </div>           

            <UserInfo />
        </div>
    );
}

export default TopBar;
