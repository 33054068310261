import React, { useContext, useEffect, useState, useCallback } from 'react'
import TextInput from '../../../../components/forms/TextInput/TextInput';
import Modal from '../../../../components/Modal/Modal';
import useFormState from '../../../../hooks/useFormState';
import { ApiContext } from '../../../../services/api/api-config';
import { getNotifier } from '../../../../services/notifier';
import { useSelector } from 'react-redux';
import { getEmptyUser, prepareUserToServer } from '../UsersUtils';
import PasswordInput from '../../../../components/forms/PasswordInput/PasswordInput';
import _ from "lodash";
import "./UsersForm.scss";

export default function UsersForm(
  {onClose, onSuccess, selectedUser}
) {

  const api = useContext(ApiContext);
  const { form, setForm, bindInput, handleInputChange } = useFormState(getEmptyUser());
  const loadingId = `user-form-${selectedUser ? selectedUser.id : '0'}`;
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
  const [vendors, setVendors] = useState([])
  const [vendor, setVendor] = useState(null)
  const [showVendors, setShowVendors] = useState(false);

  /**
   * Get vendors list
   */
  const getVendors = useCallback(() => {
    api.catVendors.get().then(response => {
      setVendors(response);
      setShowVendors(true);
    });
  }, [api]);

  /**
   * Fill form in edit user
   */
  const fillForm = useCallback(() => {
    setForm(
      {
        id: selectedUser.id,
        fullName: selectedUser.fullName,
        username: selectedUser.email,
        email: selectedUser.email,
        role: selectedUser.role.id,
        vendor: selectedUser.vendor ? selectedUser.vendor.id : null
      }
    );
    setRole(selectedUser.role ? selectedUser.role.id : null);
    if (selectedUser.vendor) {
      getVendors();
      setVendor(selectedUser.vendor.id);
      setShowVendors(true);
    }
  }, [getVendors, selectedUser, setForm]);

  
  useEffect(() => {
    api.roles.get().then(response => {
      let rolesOptions = [];
      response.forEach(rol => {
        if (rol.assignable) {
          rolesOptions.push(
            {
              id: rol.id,
              value: rol.title
            }
          );
        }
      });
      setRoles(rolesOptions);
      if (selectedUser) {
        fillForm();
      }
    });
  }, [api, fillForm, selectedUser]);

  /**
   * Handle Change role
   */
  const handleChange = useCallback((value) => {
    setRole(value);
    let isVendor = false;
    roles.forEach(element => {
      if (element.value === 'Proveedor' && element.id === parseInt(value)) {
        isVendor = true;
      }
    });
    if (!isVendor) {
      setShowVendors(false);
      setVendor(null);
      return;
    }
    if (vendors.length === 0) {
      getVendors();
    }
    setShowVendors(true);
  }, [getVendors, roles, vendors]);

  /**
   * Handle change vendor
   */
  const handleChangeVendor = useCallback((value) => {
    setVendor(value);
  }, []);
  
  /**
   * Handle create or update user
   */
  const handleNewUser = () => {
    const id = form.id;
    const method = id ? 'update' : 'create';
    let user = null;

    try {
      user = prepareUserToServer(form, role, vendor);    
    } catch (error) {
      return getNotifier().error(error.message||error.detail);
    }
    api.users[method]({id, params: user})
      .then(() => {
        getNotifier().success('Se realizó la acción correctamente');  
        onSuccess();
      })
      .catch(error => {
        getNotifier().error(error.message||error.detail);                
      });
  };

  /**
   * Loading constant
   */
  const loading = useSelector(s=>!!s.loadingIds[loadingId]);

  return (
    <div className='UsersForm'>
      <Modal
        title="Agregar Usuario"
        mainButtonText="Confirmar"
        mainButtonColor='success'
        mainButtonAction={handleNewUser}
        secondaryButtonText={'Cancelar'}
        secondaryButtonAction={onClose}
        className="UsersForm__modal"
        onClose={onClose}
        loading={loading}
      >
        <form className='UsersForm'>
          <div className='mt-3'>
            <div className="row">
              <div className="col">
                <TextInput
                  label="Nombre Completo"
                  placeholder=""
                  {...bindInput('fullName')}
                />
              </div>
              <div className="col">
                <TextInput
                  label="Correo electrónico"
                  placeholder=""
                  {...bindInput('email')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <PasswordInput
                  placeholder="Contraseña"
                  onChange={handleInputChange('password')}
                  value={form.password || ''}
                />
              </div>
              <div className="col">
                <PasswordInput
                  placeholder="Confirmar Contraseña"
                  onChange={handleInputChange('passwordConfirm')}
                  value={form.passwordConfirm || ''}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <select
                  className="form-select"
                  value={role || ''}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <option
                      className='font-bold'
                      key={0}
                      value=""
                  >
                      Seleccionar Rol
                  </option>
                  {_.map(roles, (option) => (
                      <option
                          className='font-bold'
                          key={option.id}
                          value={option.id}
                      >
                          {option.value}
                      </option>
                  ))}
                </select>
              </div>                            
            </div>
            {
              vendors.length > 0 && showVendors &&
              <div className="row">
                <div className="col">
                  <select
                    className="form-select"
                    value={vendor || ''}
                    onChange={(e) => handleChangeVendor(e.target.value)}
                  >
                    <option
                        className='font-bold'
                        key={0}
                        value=""
                    >
                        Seleccionar Proveedor
                    </option>
                    {_.map(vendors, (option) => (
                        <option
                            className='font-bold'
                            key={option.id}
                            value={option.id}
                        >
                            {option.name}
                        </option>
                    ))}
                  </select>
                </div>                            
              </div>
            }
          </div>
        </form>
      </Modal>
    </div>
  )
}
