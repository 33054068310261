import React from 'react';
import semver from 'semver-lite';
import pckg from '../../pckg';

const config ={
    host:'https://plataformapuma-back.mstch.com.mx',
    commonPath:'api',
    credentials:'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse: handleResponse,
    getMetaDataFromResponse:(r)=>r.meta,
    strictMode: false,
    handleUnknownMethods: true,
    endpoints:[
        {
            name: 'error',
            preventDefaultMethods: true,
            customMethods: {
                send: function( error, stack, user, additionalInfo ){
                    let params = { stack,
                        error: error && error.message,
                        user: user && {id: user.id, username: user.username},
                        ...additionalInfo };
                    return this.apiCall( '/frontend_error', "error_sent", params, { method: "POST", queueable: true, useCommonPath: false }  )
                }
            }
        },
        {
            name:'appFiles',
            customMethods:{
                getUrl: function({id}){ return `${this.host}/api/file/${id}?token=${this.token}`},
                getDownloadUrl: function({id}){ return `${this.host}/api/file/download/${id}?token=${this.token}`},
            }
        },
        {
            name: 'notifications',
            customMethods: {
                markAsRead: function({params={}, id, customProp, ...config}={}){
                    return this.apiCall(`/notifications/${id}/mark_as_read`, customProp, params||{}, config);
                },
                markAllAsRead: function({params={}, id, customProp, ...config}={}){
                    return this.apiCall(`/notifications/mark_all_as_read`, customProp, params||{}, config);
                },
                getHeaderNotifications: function({params={}, id, customProp, ...config}={}){
                    return this.apiCall(`/notifications/get_header_notifications`, customProp, params||{}, config);
                },
            }    
        },
        {
            name: 'users',
            customMethods: {
                passwordRecovery: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/recover_password_request', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
                resetPassword: function ({customProp = "_", params, ...config}) {
                    return this.apiCall('/reset_password', customProp, params, {
                        method: "POST",
                        useCommonPath: false, ...config
                    })
                },
            }
        },
        {
            name: 'campaigns',
            customMethods: {
                createQuote: function ({customProp = "_", id, params, ...config}) {
                    return this.apiCall(`/campaigns/${id}/create_quote`, customProp, params, {
                        method: "POST",
                        ...config
                    });
                },
                getMaterialDetails: function ({customProp = "_", id, params, ...config}) {
                    return this.apiCall(`/campaigns/${id}/getWithMaterialsDetails`, customProp, params, {
                        method: "GET",
                        ...config
                    });
                },
                searchByGroupAndStore: function ({customProp = "_", search, params, ...config}) {
                    return this.apiCall(`/campaigns/search-by-group-and-store/${search}`, customProp, params, {
                        method: "GET",
                        ...config
                    });
                },
                getCampaignCountByStatus: function () {
                    return this.apiCall(`/campaigns/getCampaignCountByStatus`, {
                        method: "GET",
                    });
                },
                notifyClient: function ({customProp = "_", id, params, ...config}) {
                    return this.apiCall(`/campaigns/notify-client/${id}`, customProp, params, {
                        method: "GET",
                        ...config
                    });
                },
            }
        },
        {
            name: 'vendorWorkOrders',
            customMethods: {
                sendNotifications: function({params={}, id, customProp, ...config}={}){
                    return this.apiCall(`/vendorWorkOrders/sendNotifications`, customProp, params || {}, {method: 'POST', ...config});
                },
                getReport: function({id}){ 
                    return `${this.host}/api/work-orders/details/${id}/create-report`;
                },
                getVendorWorkOrdersWithDetails: function () {
                    return this.apiCall(`/vendorWorkOrders/getWorkOrdersWithDetails`, {
                        method: "GET",
                    });
                },
            }    
        },
        {
            name: 'storeAlerts',
            customMethods: {
                getAlerts: function({params={}, id, customProp, ...config}={}){
                    return this.apiCall(`/storeAlerts/getAlerts`, customProp, params || {}, {method: 'GET', ...config});
                }
            }    
        },
        {
            name: 'materials',
            customMethods: {
                getInstalledThisYear: function({params={}, id, customProp, ...config}={}){
                    return this.apiCall(`/materials/get-installed-this-year`, customProp, params || {}, {method: 'GET', ...config});
                }
            }
        },
        {
            name: 'reports',
            customMethods: {
                getReportCampaignExcel: function (initDate, endDate) {
                    return `${this.host}/api/reports/get_report_campaign_excel?token=${this.token}&initDate=${initDate}&endDate=${endDate}`
                },
                getReportMaterialsExcel: function (initDate, endDate) {
                    return `${this.host}/api/reports/get_report_materials_excel?token=${this.token}&initDate=${initDate}&endDate=${endDate}`
                },
                getReportWorkOrdersExcel: function (initDate, endDate) {
                    return `${this.host}/api/reports/get_report_work_orders_excel?token=${this.token}&initDate=${initDate}&endDate=${endDate}`
                },
                getReportWorkOrdersAmountsExcel: function (initDate, endDate) {
                    return `${this.host}/api/reports/get_report_work_orders_amount_excel?token=${this.token}&initDate=${initDate}&endDate=${endDate}`
                },
            }
        }
    ],
    login:{
        createBody: ( username, password )=>{
            let credentials = new FormData();
            credentials.append("_username", username );
            credentials.append("_password", password);
            credentials.append("client_data", window?.navigator?.userAgent||'no-data');
            return credentials;
        },
    },
};


function handleResponse(response, headers){

    try {
        let server = headers.get("X-App-Version");
        let force = !!server && server[server.length - 1] === 'f';
        if (force) server = server.slice(0, server.length - 1);

        if (semver.validate(pckg.version) && semver.validate(pckg.version) && semver.gt(server, pckg.version)) {
            if(!this.newerVersion)
                console.log("Newer version detected " + server);
            if (!this.newerVersion && window.swRegistration) {
                window.swRegistration.update()
                    .then(() => {
                        //This will broadcast the refresh message to all active tabs of the app through the sw
                        if (force)
                            window.navigator.serviceWorker.controller.postMessage('force_refresh');
                    });
            }
            this.newerVersion = pckg.version;
            if (force && !window.swRegistration)
                window.location.reload();
        }
    }
    catch(e){
        console.error('Error reading versions: '+e);
    }

    return response.data;
}

if(process.env.REACT_APP_BUILD === 'dev')
    config.host=localStorage.host||'https://mstch-publicidad-back.tide.company';
    // config.host=localStorage.host||'http://localhost:8000';

if(process.env.REACT_APP_BUILD === 'prod')
    config.host=localStorage.host||'https://plataformapuma-back.mstch.com.mx';

export default config;

export const ApiContext = React.createContext(null);
