import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye
} from '@fortawesome/free-solid-svg-icons';

export const getVendorWorkOrderColumns = ({showMaterials}) => [
  {
    Header: 'CAMPAÑA',
    accessor: 'campaign.name'
  },
  {
    Header: 'TIENDA',
    accessor: 'store.name'
  },
  {
    Header: 'HORARIO',
    accessor: 'schedules'
  },
  {
    Header: 'ESTATUS',
    accessor: 'status'
  },
  {
      Header: 'ACCIONES',
      accessor: vendorWorkOrder => 
      <span
        className='ActionSee d-flex justify-content-center align-items-center' 
      >
        <span
          onClick={() => showMaterials(vendorWorkOrder)}
          className="m-2 cursor-pointer"
          title="Ver Detalles"
        >
          <FontAwesomeIcon icon={faEye}/>
        </span>
      </span>
  }
];

export const vendorWorkOrderGroups = [
  'vendor_work_order_read',
  'vendor_work_order_read_campaign',
  'campaign_read',
  'vendor_work_order_read_store',
  'store_read',
  'vendor_work_order_read_cat_vendor',
  'cat_vendor_read'
];