import React, {useCallback, useState, useContext, useEffect} from 'react'
import { moneyFormatter } from '../../../../../services/numberUtils';
import _ from 'lodash';
import "./MaterialDetailRow.scss";
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';
import debounce from 'lodash.debounce';
import {SecurityContext} from "../../../../../services/SecurityManager";

export default function MaterialDetailRow(
  {details, vendors, onUpdated}
) {

  const [materials, setMaterials] = useState([])
  const api = useContext(ApiContext);
  //Don't show vendor data columns for client
  const security = useContext(SecurityContext);
  const onClientRole = security.isClient();
  const onVendorRole = security.isVendor();

  /**
   * Update cat material type default amount
   */
  const updateMaterialDefaults = useCallback((materialId, field, value) => {
    api.materials.get({
      id: materialId.toString(),
      params: {
        sGroups: ['material_read_cat_material_type', 'cat_material_type_read']
      }
    }).then(response => {
      if (field === 'amount') {
        field = 'clientAmount';
      }
      if (response.catMaterialType) {
        api.catMaterialTypes.update({
          id: response.catMaterialType.id,
          params: {
            [field]: value
          }
        }).then(() => {
          onUpdated(details);
          return getNotifier().success('Actualizado correctamente');
        }).catch(
          error => getNotifier().error(error.detail || error.message)
        );
      }
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.catMaterialTypes, api.materials, details, onUpdated]);

  /**
   * Update material info
   */
  const updateMaterialSelectedInfo = useCallback((field, materialId, theMaterials, value) => {
    api.campaignDetails.update({
      id: details.id,
      params: {
        materials: theMaterials
      }
    }).then(() => {
      updateMaterialDefaults(materialId, field, value);
      // onUpdated(details);
      // return getNotifier().success('Actualizado correctamente');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaignDetails, details, updateMaterialDefaults]);

  const debouncedChangeHandler = debounce(updateMaterialSelectedInfo, 1000);

  /**
   * Handle Input Change
   */
  const handleInputChange = useCallback((value, field, materialId) => {
    let theMaterials = [...materials];
    const theIndex = theMaterials.findIndex(element => element.id === materialId);
    if (theIndex !== undefined) {
      if (field === 'quantity') {
        theMaterials[theIndex].quantity = value;
      }
      if (field === 'amount') {
        theMaterials[theIndex].amount = value;
      }
      if (field === 'vendorAmount') {
        theMaterials[theIndex].vendorAmount = value;
      }
      theMaterials[theIndex].updated = new Date().toISOString().split('T')[0];
    }
    setMaterials(theMaterials);
    debouncedChangeHandler(field, materialId, theMaterials, value);
  }, [debouncedChangeHandler, materials]);

  /**
   * Update vendor selected
   */
  const vendorSelected = useCallback((value, materialId) => {
    let materialList = [];
    details.materials.forEach(material => {
      if (material.id === materialId) {
        material.vendor = value;
      }
      materialList.push(
        material
      );
    });
    api.campaignDetails.update({
      id: details.id,
      params: {
        materials: materialList
      }
    }).then(responseUpdate => {
      updateMaterialDefaults(materialId, 'vendor', value);
      // onUpdated(details);
      // return getNotifier().success('Actualizado correctamente');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaignDetails, details.id, details.materials, updateMaterialDefaults]);

  useEffect(() => {
    setMaterials(details.materials);
  }, [details.materials])
  

  return (
    <div className='MaterialDetailRow'>
      
      {_.map(materials, (material) => (
        <div
          className='detail-row'
          key={material.id}
        >
          {!onClientRole && !onVendorRole && (
            <div className='material-field'>
              <select
                className={material.vendor === 0 ? 'form-select border-error' : 'form-select border-success'}
                onChange={(e) => vendorSelected(e.target.value, material.id)}
                value={material.vendor ? material.vendor.toString() : '0'}
              >
                <option value="">Proveedor</option>
                {
                  _.map(vendors, (vendor) => (
                    <option
                      value={vendor.id}
                      key={vendor.id}
                    >
                      {vendor.name}
                    </option>
                  ))
                }
              </select>
            </div>
          )}
          {
            onVendorRole &&
            <div className='material-field'>
              <span>
                {vendors.find(vendor => vendor.id === parseInt(material.vendor)).name}
              </span>
            </div>
          }


          <div className='material-field'>
            {material.name}
          </div>
          <div className='material-field'>
            {material.description}
          </div>
          {
            !onClientRole && !onVendorRole &&
            <div
              className='material-field'
            >
              <div
                className='d-flex justify-content-between'
              >
                <input
                  type="number"
                  className="input-field"
                  onChange={(e) => handleInputChange(e.target.value, 'quantity', material.id)}
                  value={material.quantity}
                />
              </div>
            </div>
          }
          {
            (onClientRole || onVendorRole) &&
            <div
              className='material-field'
            >
              <span>
                {material.quantity}
              </span>
            </div>
          }

          {
            !onClientRole && !onVendorRole &&
            <div
              className='material-field'
            >
              <div
                className='d-flex justify-content-between'
              >
                <input
                  type="number"
                  value={material.amount}
                  className="input-field"
                  onChange={(e) => handleInputChange(e.target.value, 'amount', material.id)}
                />
              </div>
            </div>
          }

          {
            (onClientRole)  &&
            <div
              className='material-field'
            >
              <div
                className='d-flex justify-content-between'
              >
                <span>
                  ${moneyFormatter(parseFloat(material.amount))}
                </span>
              </div>
            </div>
          }

          {
            !onVendorRole &&
            <div className='material-field'>
              ${moneyFormatter(parseFloat(material.amount) * parseFloat(material.quantity))}
            </div>
          }

          {
            !onClientRole && !onVendorRole &&
            <div className='material-field'>
              <div className='d-flex justify-content-between'>
                <input
                  type="number"
                  value={material.vendorAmount}
                  className="input-field"
                  onChange={(e) => handleInputChange(e.target.value, 'vendorAmount', material.id)}
                />
              </div>
            </div>
          }
          
          
          {
            onVendorRole && (
            <div className='material-field'>
              <div className='d-flex justify-content-between'>
                <span>
                  ${moneyFormatter(parseFloat(material.vendorAmount))}
                </span>
              </div>
            </div>
          )}
          {!onClientRole && (
            <div className='material-field'>
              ${moneyFormatter(parseFloat(material.vendorAmount) * parseFloat(material.quantity))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
