import React, {useCallback, useContext} from 'react'
import Card from '../../../../../../components/Dashboard/Card/Card'
import TextInput from '../../../../../../components/forms/TextInput/TextInput'
import Button from '../../../../../../components/molecules/Button/Button'
import useFormState from '../../../../../../hooks/useFormState'
import { ApiContext } from '../../../../../../services/api/api-config'
import { getNotifier } from '../../../../../../services/notifier'
import { useSelector } from 'react-redux';
import "./AddMaterial.scss"

export default function AddMaterial({onSuccess}) {

  const { form, setForm, bindInput } = useFormState({});
  const api = useContext(ApiContext);
  const loadingId = `Grower.form.create`;

  /**
   * Handle Change amount
   */
  const handleChangeAmount = useCallback((event, name) => {
    const value = event.target.value;
    setForm({...form, [name]: value.slice(0, 8)});
  }, [form, setForm]);

  /**
   * Handle create or update material type
   */
  const handleCreateOrUpdateMaterialType = useCallback(() => {
    let materialType = null;

    try {
      materialType = form;
    } catch (error) {
      return getNotifier().error(error.message||error.detail);            
    }
    
    api.catMaterialTypes.create({ params: materialType })
      .then(materialStatus => {
        getNotifier().success(
          'Agregado correctamente'
        );
        onSuccess();
      }
    )
      .catch(error => getNotifier().error(error.message || error.detail));
  }, [api.catMaterialTypes, form, onSuccess]);

  const loading = useSelector(s => !!s.loadingIds[loadingId]);

  return (
    <div className='AddMaterial'>
      <div className='row'>
        <Card className='OrderStep1'>
          <div className='row mt-3'>
            <div className='col-12 margin-top-mobile'>
              <TextInput
                label="Nombre"
                placeholder=""
                {...bindInput('name')}
                inputProps={
                  {
                    maxLength: 200
                  }
                }
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 margin-top-mobile'>
              <TextInput
                label="Descripción"
                placeholder=""
                {...bindInput('description')}
                inputProps={
                  {
                    maxLength: 200
                  }
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <TextInput
                label="Costo Cliente"
                placeholder=""
                type="number"
                // {...bindInput('clientAmount')}
                onChange={(e) => handleChangeAmount(e, 'clientAmount')}
                value={form.clientAmount || ''}
              />
            </div>
            <div className='col-6'>
              <TextInput
                label="Costo Proveedor"
                placeholder=""
                type="number"
                {...bindInput('vendorAmount')}
                onChange={(e) => handleChangeAmount(e, 'vendorAmount')}
                value={form.vendorAmount || ''}
              />
            </div>
          </div>
          <div className='d-flex justify-content-start align-items-center buttons-container'>
            <Button 
              className={`btn-primary btnNextStep`}
              onClick={handleCreateOrUpdateMaterialType}
              disabled={loading}
            >
              Agregar
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
}
