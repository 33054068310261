import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle';
import Button from '../../../../components/molecules/Button/Button';
import Card from '../../../../components/Dashboard/Card/Card';
import "./EvidenceForm.scss"
import { useNavigate, useParams } from 'react-router-dom';
import useFormState from '../../../../hooks/useFormState';
import { ApiContext } from '../../../../services/api/api-config';
import { getNotifier } from '../../../../services/notifier';
import _ from "lodash";
import MultipleFileUpload, { PreviewImage } from '../../../../components/forms/MultipleFileUpload/MultipleFileUpload';
import { paths } from '../../../../services/routes/appRoutes';
import { evidenceMaterialStatus } from '../EvidenceStoreUtils';
import TextInput from '../../../../components/forms/TextInput/TextInput';

const imageFileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];
const maxFileSizeInMB = 50;

export default function EvidenceForm() {

  const { id } = useParams();
  const api = useContext(ApiContext);
  const navigate = useNavigate();
  const { form, setForm, bindInput } = useFormState({pictures: []});
  const [first, setFirst] = useState(true)
  const [campaigns, setCampaigns] = useState([]);
  const [stores, setStores] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [showUploadFiles, setShowUploadFiles] = useState(false)
  const [arrayPictures, setArrayPictures] = useState([]);

  /**
   * Get Campaigns
   */
  const getCampaigns = useCallback(() => {
    api.campaigns.get().then(response => {
      setCampaigns(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaigns]);

  /**
   * Get stores related to campaign selected
   */
  const getStores = useCallback((value) => {
    api.campaignDetails.get({
      params: {
        'campaign.id': value,
        sGroups: [
          'campaign_detail_read',
          'campaign_detail_read_store',
          'store_read'
        ]
      },
    }).then(response => {
      let theStores = [];
      response.forEach(element => {
        theStores.push(
          {
            store: element.store,
            materials: element.materials
          }
        );
      });
      setStores(theStores);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaignDetails]);

  /**
   * Get material details related to store selected
   */
  const getMaterialDetails = useCallback(async (theMaterials) => {
    let currentMaterials = [];
    for (const theMaterial of theMaterials) {
      let material = await api.materials.get(
        {
          id: theMaterial.id.toString(),
          params: {
            sGroups: [
              'material_read',
              'material_read_cat_material_type',
              'cat_material_type_read'
            ]
          }
        }
      );
      currentMaterials.push(material);
    }
    setMaterials(currentMaterials);
  }, [api.materials]);

  /**
   * Get materials related to store selected
   */
  const getMaterials = useCallback((value) => {
    const foundIndex = stores.findIndex(theStore => theStore.store.id === parseInt(value));
    if (foundIndex > -1) {
      getMaterialDetails(stores[foundIndex].materials);
    }
  }, [getMaterialDetails, stores]);

  /**
   * Handle fields change
   */
  const handleChange = useCallback((value, field) => {
    setForm({...form, [field]: value});
    if (field === 'campaign') {
      getStores(value);
    }
    if (field === 'store') {
      getMaterials(value);
    }
    if (field === 'material') {
      setShowUploadFiles(true);
    }
  }, [form, getMaterials, getStores, setForm]);

  /**
   * Handle on change pictures
   * 
   */
  const onChangePictures = useCallback((newFiles) => {
    setForm({...form, pictures: newFiles});
    setArrayPictures(newFiles);
  }, [form, setForm]);

  /**
   * Delete added file
   * 
   */
  const onDelete = useCallback((index) => {
    const newPictures = arrayPictures.filter((picture, i) => i !== index);
    setForm({...form, pictures: newPictures});
    setArrayPictures(newPictures);
  }, [arrayPictures, form, setForm]);

  const handleBack = useCallback(() => navigate(paths.evidences), [navigate]);

  /**
   * Update material status
   */
  const updateMaterialStatus = useCallback((materialId, status) => {
    api.materials.update({
      id: materialId,
      params: {
        status
      }
    }).then(response => {
      getNotifier().success('Agregado correctamente');
      handleBack();
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.materials, handleBack]);

  /**
   * Upload files added
   */
  const uploadFiles = useCallback((file) => {
    api.evidence.create({
      params: {
        campaign: form.campaign,
        store: form.store,
        material: form.material,
        status: form.status,
        comments: form.comments
      },
      files: file
    }).then(response => {
      updateMaterialStatus(form.material, form.status);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.evidence, form, updateMaterialStatus]);

  /**
   * handle save files
   */
  const saveFiles = useCallback(() => {
    if (form.pictures.length === 0) {
      getNotifier().error('No ha agregado ningún archivo');
      return;
    }
    form.pictures.forEach(picture => {
      uploadFiles(picture);
    });
  }, [form.pictures, uploadFiles]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getCampaigns();
    }
  }, [first, getCampaigns, getStores])
  
  return (
    <div className='EvidenceForm'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <SectionTitle>
            { !id ? 'Agregar Evidencia' : 'Editar Evidencia' }
          </SectionTitle>
          <div className='row'>
            <Card className='OrderStep1'>
              <div className='row mt-3'>
                <div className='col margin-top-mobile'>
                  <label htmlFor="">
                    Campaña
                  </label>
                  <select
                    className="form-select"
                    value={form.campaign || ''}
                    onChange={(e) => handleChange(e.target.value, 'campaign')}
                    placeholder="seleccionar"
                  >
                    <option
                      className='font-bold'
                      key={0}
                      value=""
                    >
                      Seleccionar Campaña
                    </option>
                    {
                      _.map(campaigns, (campaign) => (
                        <option
                          value={campaign.id}
                          key={campaign.id}
                        >
                          {campaign.name}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
              {
                stores.length > 0 &&
                <div className='row mt-2'>
                  <div className='col margin-top-mobile'>
                    <label htmlFor="">
                      Tienda
                    </label>
                    <select
                      className="form-select"
                      value={form.store || ''}
                      onChange={(e) => handleChange(e.target.value, 'store')}
                      placeholder="seleccionar"
                    >
                      <option
                        className='font-bold'
                        key={0}
                        value=""
                      >
                        Seleccionar Tienda
                      </option>
                      {
                        _.map(stores, (store, index) => (
                          <option
                            value={store.store.id}
                            key={index}
                          >
                            {store.store.name}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              }
              {
                materials.length > 0 &&
                <>
                  <div className='row mt-2'>
                    <div className='col margin-top-mobile'>
                      <label htmlFor="">
                        Material
                      </label>
                      <select
                        className="form-select"
                        value={form.material || ''}
                        onChange={(e) => handleChange(e.target.value, 'material')}
                        placeholder="seleccionar"
                      >
                        <option
                          className='font-bold'
                          key={0}
                          value=""
                        >
                          Seleccionar Material
                        </option>
                        {
                          _.map(materials, (material, index) => (
                            <option
                              value={material.id}
                              key={index}
                            >
                              {material.catMaterialType.name} {material.catMaterialType.description}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col margin-top-mobile'>
                      <label htmlFor="">
                        Estatus
                      </label>
                      <select
                        className="form-select"
                        value={form.status || ''}
                        onChange={(e) => handleChange(e.target.value, 'status')}
                        placeholder="seleccionar"
                      >
                        <option
                          className='font-bold'
                          key={0}
                          value=""
                        >
                          Seleccionar Estatus
                        </option>
                        {
                          _.map(evidenceMaterialStatus, (status, index) => (
                            <option
                              value={status}
                              key={index}
                            >
                              {status}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col margin-top-mobile'>
                      <label htmlFor="">
                        Comentarios
                      </label>
                      <TextInput
                        label="Comentarios"
                        placeholder=""
                        {...bindInput('comments')}
                      />
                    </div>
                  </div>
                </>
              }

              {
                showUploadFiles &&
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <div>
                        <div>Tipos de archivos: {imageFileTypes.map(f => `.${f.toLowerCase()}`).join(', ')}</div>
                        <div>Tamaño máximo: {maxFileSizeInMB} MB</div>
                      </div>
                      <MultipleFileUpload
                        fileTypes={imageFileTypes}
                        multiple={true}
                        legend={'Images'}
                        onChange={files => onChangePictures(files)}
                        files={form.pictures}
                        maxFileSizeInMB={maxFileSizeInMB}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className='col-12'>
                      {arrayPictures.map((picture, index) => 
                        picture.id
                        ? ''
                        : <PreviewImage key={index} picture={picture} onDelete={() => onDelete(index)} />
                      )}
                    </div>
                  </div>
                </>
              }
            </Card>
            <div className="row mt-4">
              <div className="col mt-4 d-flex justify-content-between">
                {
                  showUploadFiles &&
                  <Button 
                    className={`btn-primary btnNextStep`}
                    onClick={saveFiles}
                  >
                    Guardar
                  </Button>
                }
                
                <Button 
                  className={`btn-secondary btnNextStep`}
                  onClick={handleBack}
                  design="secondary"
                >
                  Volver
                </Button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}
