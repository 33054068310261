import React, { useCallback, useContext, useEffect, useState } from 'react'
import Card from '../../../../../../components/Dashboard/Card/Card'
import MultipleFileUpload from '../../../../../../components/forms/MultipleFileUpload/MultipleFileUpload'
import useFormState from '../../../../../../hooks/useFormState';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import "./AddPermissionToStore.scss"
import { getFiles } from '../../../CampaignUtils';
import { getNotifier } from '../../../../../../services/notifier';
import { ApiContext } from '../../../../../../services/api/api-config';
import Button from '../../../../../../components/molecules/Button/Button';

const imageFileTypes = ['JPG', 'PNG', 'GIF', 'PDF', 'DOC', 'XLS', 'XLSX'];
const maxFileSizeInMB = 50;

export default function AddPermissionToStore(
  {storeId, campaignId}
) {

  const { form, setForm } = useFormState({files: []});
  const [arrayFiles, setArrayFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([])
  const [first, setFirst] = useState(true)

  const api = useContext(ApiContext);

  /**
   * Handle on change pictures
   * 
   */
  const onChangePictures = useCallback((newFiles) => {
    setForm({files: newFiles});
    setArrayFiles(newFiles);
  }, [setForm]);

  /**
   * Delete added file
   * 
   */
  const onDeleteFile = useCallback((index) => {
    const newFiles = arrayFiles.filter((file, i) => i !== index);
    setForm({...form, files: newFiles});
    setArrayFiles(newFiles);
  }, [arrayFiles, form, setForm]);

  /**
   * Delete added file
   * 
   */
  const deleteFile = useCallback((theFile) => {
    api.permissionFiles.delete({id: theFile.id}).then(response => {
      const newFiles = currentFiles.filter((file, i) => file.id !== theFile.id);
      setForm({...form, files: newFiles});
      setCurrentFiles(newFiles);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.permissionFiles, currentFiles, form, setForm]);

  /**
   * Load files related to campaign
   */
  const loadFiles = useCallback(() => {
    api.permissionFiles.get(
      {
        params: {
          'campaign.id': campaignId,
          'store.id': storeId,
          sGroups: [
            'permission_file_read',
            'app_file_read'
          ]
        }
      }
      ).then(response => {
        setCurrentFiles(response);
    }).catch(error => getNotifier().error(error.detail || error.message));
  }, [api.permissionFiles, campaignId, storeId]);

  /**
   * Save files added to campaign
   */
  const saveFiles = useCallback(() => {
    let files = null;
    files = getFiles(form);
    files.forEach(file => {
      api.permissionFiles.create(
        {
          params: {
            campaign: campaignId,
            store: storeId
          },
          files: file
        }
      ).then(fileResponse => {
        getNotifier().success('Archivo(s) agregados correctamente');
        setArrayFiles([]);
        setForm({files: []});
        loadFiles();
      }).catch(error => getNotifier().error(error.detail || error.message));
    });
  }, [api.permissionFiles, campaignId, form, loadFiles, setForm, storeId]);

  /**
   * Handle download permission file
   * 
   */
  function downloadFile(fileId, originalName) {
    const downloadUrl = api.appFiles.getDownloadUrl(
      {id: fileId}
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', originalName);
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    if (first) {
      setFirst(false);
      loadFiles();
    }
  }, [first, loadFiles])
  

  return (
    <div className='AddPermissionToStore'>
      <Card>
        <MultipleFileUpload
          fileTypes={imageFileTypes}
          multiple={true}
          legend={'files'}
          onChange={files => onChangePictures(files)}
          files={form.files}
          maxFileSizeInMB={maxFileSizeInMB}
        />
        <div className="row">
          <div className='col'>
            {
              arrayFiles.map((file, index) => (
                <div className='d-flex justify-content-between file-row' key={index}>
                  <div>
                    {
                      <div className='file-name-preview'>
                        <FontAwesomeIcon
                          icon={faTrash}
                          className='cursor-pointer mr-2'
                          onClick={() => onDeleteFile(index)}
                        />
                        {file.name}
                      </div>
                    }
                  </div>
                </div>
              )
            )}
            {
              currentFiles.map((file, index) => (
                <div
                  className='d-flex justify-content-between file-row'
                  key={index}
                >
                  <div
                    className='file-name-preview'
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className='cursor-pointer mr-2'
                      onClick={() => deleteFile(file)}
                    />
                    {file.appFile.originalName}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faDownload}
                      className='cursor-pointer'
                      onClick={() => downloadFile(file.appFile.id, file.appFile.originalName)}
                    />
                  </div>
                </div>
                
              ))
            }
          </div>
        </div>
        <div className="row mt-4">
          <div className="col mt-4 d-flex justify-content-between">
            <Button
              className={`btn-primary btnNextStep`}
              onClick={saveFiles}
              disabled={arrayFiles.length > 0 ? false : true}
            >
              Guardar
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}
