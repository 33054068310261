import UserStatus from './components/UserStatus/UserStatus';
import UserAvatar from './components/UserAvatar/UserAvatar';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';
import {copyPropertyIdOrThrow, copyPropertyOrThrow, validateEmail} from "../../../services/formUtils";

export const getUsersTableColumns = (
  {onEdit}
) => [
  {
    Header: ' ',
    id: 'commands',            
    accessor: user => 
      <div className='actions-column'>
        <UserStatus user={user} />
      </div>
  },
  {
    Header: 'ID',
    accessor: 'id',
    disableSortBy: false,
  },
  {
    Header: 'NOMBRE',
    id: 'name',
    disableSortBy: false,
    accessor: user => <UserAvatar user={user} onEdit={onEdit}/>
  },
  {
      Header: 'CORREO ELECTRÓNICO',
      accessor: 'email',
      disableSortBy: false,
  },
  {
    Header: 'ROL',
    accessor: 'role.title',
    disableSortBy: false,
  },
  {
    Header: 'PROVEEDOR',
    accessor: 'vendor.name',
    disableSortBy: false,
  },
  {
      Header: 'FECHA DE CREACIÓN',
      accessor: user => 
        <span className='float-left'>
          {moment(user.createdDate).format( generalDatesFormat )}
        </span>
  }
];

export const userListSGroups = [
  'user_read_id',
  'user_read_full_name',
  'user_read_username',
  'user_read_email',
  'user_read_is_active',
  'user_read_vendor',
  'cat_vendor_read'
];

export const usersWithPermissionsGSGroups = [
  ...userListSGroups,
  'user_read_created_date',
  'user_read_role',
  'role_read'
];

export const getEmptyUser = () => {
  return {
    id: null,
    username: null,
    password: null,
    email: null,
    role: null,
    vendor: null
  }
}

export const prepareUserToServer = (form, role, vendor) => {
  const user = {};
  copyPropertyOrThrow(form, user, 'fullName', 'El nombre es requerido');
  copyPropertyOrThrow(form, user, 'email', 'El correo electrónico es requerido');
  validateEmail(form.email, 'El correo electrónico no es valido');
  if (!form.id) {
    copyPropertyOrThrow(form, user, 'password', 'La contraseña es requerida');
    copyPropertyOrThrow(form, user, 'passwordConfirm', 'La confirmación de la contraseña es requerida');
  } else {
    if (form.password && form.password !== '') {
      copyPropertyOrThrow(form, user, 'passwordConfirm', 'La confirmación de la contraseña es requerida');
      user.password = form.password;
    }
  }
  
  if (form.password && form.passwordConfirm && form.password !== form.passwordConfirm) {
    throw new Error("La confirmación de la contraseña es incorrecta.");
  }

  copyPropertyIdOrThrow( {role}, user, "role", "Por favor selecciona un rol");
  if (role === '3') {
    copyPropertyIdOrThrow( {vendor}, user, "vendor", "Por favor selecciona un proveedor");
  }


  user.username = form.email;

  return user;
}