import React, {useCallback, useState, useEffect, useContext} from 'react'
import Card from '../../../../../../components/Dashboard/Card/Card'
import TextInput from '../../../../../../components/forms/TextInput/TextInput'
import Button from '../../../../../../components/molecules/Button/Button'
import useFormState from '../../../../../../hooks/useFormState'
import { useSelector } from 'react-redux';
import { getNotifier } from '../../../../../../services/notifier'
import "./AddStore.scss"
import _ from 'lodash';
import { ApiContext } from '../../../../../../services/api/api-config'

export default function AddStore(
  {onSuccess}
) {

  const { form, setForm, bindInput } = useFormState({});
  const [active, setActive] = useState('');
  const [storeGroupSelected, setStoreGroupSelected] = useState('')
  const [storeGroups, setStoreGroups] = useState([])
  const loadingId = `Grower.form-store`;
  const api = useContext(ApiContext);

  const getStoreGroups = useCallback(() => {
    api.storeGroups.get().then(storeGroupsResponse => {
      setStoreGroups(storeGroupsResponse);
    } ).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.storeGroups]);

  useEffect(() => {
    if (storeGroups.length === 0) {
      getStoreGroups();
    }
  }, [getStoreGroups, storeGroups.length])
  
  /**
   * Handle change amount
   */
  const handleChangeAmount = useCallback((event, name) => {
    const value = event.target.value;
    setForm({...form, [name]: value.slice(0, 8)});
  }, [form, setForm]);

  /**
   * Handle change
   */
  const handleChange = useCallback((value) => {
    setActive(value);
  }, []);

  /**
   * Handle change store group
   */
  const handleChangeStoreGroup = useCallback((value) => {
    setStoreGroupSelected(value);
  }, []);

  /**
   * Handle create or update store
   */
  const handleCreateOrUpdateStore = useCallback(() => {
    let store = null;

    try {
      store = form;
      store.active = active === '1' ? true : false;
      store.storeGroup = storeGroupSelected;
    } catch (error) {
      return getNotifier().error(error.message || error.detail);            
    }
    
    api.stores.create({ params: store })
      .then(store => {
        getNotifier().success(
          'Agregado correctamente'
        );
        onSuccess(store);
      }
    )
      .catch(error => getNotifier().error(error.message || error.detail));
  }, [active, api.stores, form, onSuccess, storeGroupSelected]);

  const loading = useSelector(s => !!s.loadingIds[loadingId]);

  return (
    <div className='AddStore'>
      <div className='row'>
        <Card className='OrderStep1'>
          <div className='row mt-3'>
            <div className='col-12 margin-top-mobile'>
              <select
                className='form-select'
                value={storeGroupSelected}
                onChange={(e) => handleChangeStoreGroup(e.target.value)}
              >
                <option value="">Cadena de Tiendas</option>
                {_.map(storeGroups, (storeGroup) => (
                  <option
                    value={storeGroup.id}
                    key={storeGroup.id}
                  >
                    {storeGroup.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 margin-top-mobile'>
              <TextInput
                label="Nombre"
                placeholder=""
                {...bindInput('name')}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 margin-top-mobile'>
              <TextInput
                label="Dirección"
                placeholder=""
                {...bindInput('address')}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 margin-top-mobile'>
              <TextInput
                label="Contacto"
                placeholder=""
                {...bindInput('manager')}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-4 margin-top-mobile'>
              <TextInput
                label="Nombre del visual"
                placeholder=""
                {...bindInput('visualName')}
              />
            </div>
            <div className='col-4 margin-top-mobile'>
              <TextInput
                label="Teléfono del visual"
                placeholder=""
                {...bindInput('visualPhone')}
              />
            </div>
            <div className='col-4 margin-top-mobile'>
              <select
                className="form-select"
                value={active || ''}
                onChange={(e) => handleChange(e.target.value)}
                placeholder="seleccionar"
              >
                <option
                    className='font-bold'
                    key={0}
                    value=""
                >
                    Estatus
                </option>
                <option
                    className='font-bold'
                    key={1}
                    value="1"
                >
                    Activo
                </option>
                <option
                    className='font-bold'
                    key={2}
                    value="0"
                >
                    Inactivo
                </option>
              </select>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-4 margin-top-mobile'>
                <TextInput
                  label="Costo de Instalación"
                  placeholder=""
                  type="number"
                  onChange={(e) => handleChangeAmount(e, 'installationAmount')}
                  value={form.installationAmount || ''}
                />
              </div>
          </div>
                  
          <div className='d-flex justify-content-start align-items-center buttons-container'>
            <Button 
              className={`btn-primary btnNextStep`}
              onClick={handleCreateOrUpdateStore}
              disabled={loading}
            >
              Agregar
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
}
