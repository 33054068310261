import React, { useContext } from 'react'
import { getFileUrl } from "../../../../services/models/campaignFilesUtils";
import './EvidenceImage.scss'
import { ApiContext } from '../../../../services/api/api-config';

export default function EvidenceImage({imageId}) {

  const api = useContext(ApiContext);

  return (
    <div className='EvidenceImage'>
      <div>
        <img src={getFileUrl(imageId, api) + '&custom=preview'} alt="Imagen" className='preview-image'/>
      </div>
    </div>
  )
}
