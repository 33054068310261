import { Link } from "react-router-dom";
import { paths } from "../../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import { moneyFormatter } from "../../../../services/numberUtils";


export const getCatMaterialTypeTableColumns = (
    {onRemoveClick}
  ) => [
    {
        Header: 'ID',
        accessor: materialType =>
            <div className='text-center'>
                {materialType.id}
            </div>
    },
    {
        Header: 'NOMBRE',
        accessor: materialType =>
            <div className='text-center'>
                {materialType.name}
            </div>
    },
    {
        Header: 'DESCRIPCIÓN',
        accessor: materialType =>
            <div className='text-center'>
                {materialType.description}
            </div>
    },
    {
        Header: 'COSTO CLIENTE',
        accessor: materialType =>
            <div className='text-center'>
                {moneyFormatter(materialType.clientAmount)}
            </div>
    },
    {
        Header: 'COSTO PROVEEDOR',
        accessor: materialType =>
            <div className='text-center'>
                {moneyFormatter(materialType.vendorAmount)}
            </div>
    },
    {
        Header: 'ACCIONES',
        accessor: materialType => 
        <span
            className='ActionSee d-flex justify-content-center align-items-center' 
        >
            <Link
                to={ paths.catMaterialTypeFormEdit.replace(":id", materialType.id) }
                className="m-2"
                title="Editar"
            >
                <FontAwesomeIcon icon={faEdit}/>
            </Link>
            <span
                onClick={() => {
                    onRemoveClick(materialType.id)
                }} 
                className="m-2 error cursor-pointer"
                title="Eliminar"
            >
                <FontAwesomeIcon icon={faTrash}/>
            </span>
        </span>
    }
];

export const catMaterialTypeTableSGroups = [
    'cat_material_type_read'
];