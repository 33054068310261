import { Link } from "react-router-dom";
import { paths } from "../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit,
    faEye
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';


export const getCampaignTableColumns = (
  {onRemoveClick, onClientRole}
) => [
  {
      Header: 'ID',
      accessor: campaign =>
        <div className="text-center">
          {campaign.id}
        </div>
  },
  {
    Header: 'CLIENTE',
    accessor: campaign =>
      <div className="text-center">
        {campaign.user.username}
      </div>
  },
  {
      Header: 'NOMBRE',
      accessor: campaign =>
        <div className="text-center">
          {campaign.name}
        </div>
  },
  {
    Header: 'FECHA DE CREACIÓN',
    accessor: campaign =>
      <div className="text-center">
        {moment(campaign.createdDate).format( generalDatesFormat )}
      </div>
  },
  {
    Header: 'FECHA DE PUBLICACIÓN',
    accessor: campaign =>
      <div className="text-center">
        {moment(campaign.publishDate).format( generalDatesFormat )}
      </div>
  },
  {
    Header: 'MASTER',
    accessor: campaign =>
      <div className="text-center">
        {campaign.master}
      </div>
  },
  {
      Header: 'ESTATUS',
      accessor: campaign =>
        <div className="text-center">
          {campaign.status}
        </div>
  },
  {
    Header: 'ESTATUS FINANCIERO',
    accessor: campaign =>
        <div className='text-center'>
          {campaign.invoiced ? 'Facturada' : 'Pendiente de folio'}
        </div>
  },
  {
      Header: 'ACCIONES',
      accessor: campaign => 
      <span
          className='ActionSee d-flex justify-content-center align-items-center' 
      >
        {
          !onClientRole &&
          <Link
            to={ paths.campaignFormEdit.replace(":id", campaign.id) }
            className="m-2"
            title="Editar"
          >
            <FontAwesomeIcon icon={faEdit}/>
          </Link>
        }
          
          {
            campaign.campaignDetails.length === 0 &&
            <span
              onClick={() => {
                onRemoveClick(campaign.id)
              }} 
              className="m-2 error cursor-pointer"
              title="Eliminar"
            >
              <FontAwesomeIcon icon={faTrash}/>
            </span>
          }
          <Link
              to={ paths.campaignDetails.replace(":id", campaign.id) }
              className="m-2 details-link"
              title="Ver Detalles"
          >
              <FontAwesomeIcon icon={faEye}/>
          </Link>
      </span>
  }
];

export const getCampaignTableColumnsVendor = (
  {onRemoveClick}
) => [
  {
      Header: 'ID',
      accessor: campaign =>
        <div className="text-center">
          {campaign.id}
        </div>
  },
  {
    Header: 'CLIENTE',
    accessor: campaign =>
      <div className="text-center">
        {campaign.user.username}
      </div>
  },
  {
      Header: 'NOMBRE',
      accessor: campaign =>
        <div className="text-center">
          {campaign.name}
        </div>
  },
  {
    Header: 'FECHA DE CREACIÓN',
    accessor: campaign =>
      <div className='text-center'>
        {moment(campaign.createdDate).format( generalDatesFormat )}
      </div>
  },
  {
    Header: 'FECHA DE PUBLICACIÓN',
    accessor: campaign =>
      <div className='text-center'>
        {moment(campaign.publishDate).format( generalDatesFormat )}
      </div>
  },
  {
    Header: 'MASTER',
    accessor: campaign =>
      <div className='text-center'>
        {campaign.master}
      </div>
  },
  {
      Header: 'ESTATUS',
      accessor: campaign =>
        <div className='text-center'>
          {campaign.status}
        </div>
  },
  {
      Header: 'ACCIONES',
      accessor: campaign => 
      <span
          className='ActionSee d-flex justify-content-center align-items-center' 
      >
          <Link
              to={ paths.campaignFormEdit.replace(":id", campaign.id) }
              className="m-2"
              title="Editar"
          >
              <FontAwesomeIcon icon={faEdit}/>
          </Link>
          {
            campaign.campaignDetails.length === 0 &&
            <span
              onClick={() => {
                onRemoveClick(campaign.id)
              }} 
              className="m-2 error cursor-pointer"
              title="Eliminar"
            >
              <FontAwesomeIcon icon={faTrash}/>
            </span>
          }
          <Link
              to={ paths.campaignDetails.replace(":id", campaign.id) }
              className="m-2 details-link"
              title="Ver Detalles"
          >
              <FontAwesomeIcon icon={faEye}/>
          </Link>
      </span>
  }
];

export const getCampaignPictures = (form) => {
  const pictures = [];
  if (form.pictures) {
      form.pictures.forEach(picture => {
          pictures.push( picture );
      });
  }

  return pictures;
}

export const getFiles = (form) => {
  const files = [];
  if (form.files) {
      form.files.forEach(picture => {
        files.push( picture );
      });
  }

  return files;
}

export const campaignSGroups = [
  'campaign_read',
  'campaign_read_user',
  'user_read',
  'campaign_read_campaign_details',
  'campaign_detail_read',
  'campaign_detail_read_store',
  'store_read'
];

export const campaignFilesSGroups = [
  'campaign_read',
  'campaign_read_material_files',
  'material_file_read',
  'read_file',
];

export const storeMaterialSGroups = [
  'material_read',
  'material_read_cat_material_type',
  'cat_material_type_read',
];
