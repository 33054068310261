import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';
import NotificationCount from './components/NotificationCount/NotificationCount';
import './NotificationsBar.scss';
import NotificationsListRightBar from './components/NotificationsListRightBar/NotificationsListRightBar';
const customProp = 'notifications';

const NotificationsBar = () => {
    const api = useContext(ApiContext);
    const [notifications, setNotifications] = useState([]);
    const [showNotificationsModal, setShowNotificationsModal] = useState();

    useEffect(() => {
        api.notifications.getHeaderNotifications({params: {page: 1,itemsPerPage: 30}, customProp}).then((res) => {
            setNotifications(res);
        }).catch((err) => getNotifier().error(err.message||err.detail));
    }, [api]);

    const reloadNitifications = async () => {
        const _notifications = await api.notifications.get_header_notifications({params: {}, customProp})
        setNotifications(_notifications);
    }

    return (
        <div className='NotificationsBar'>
            <NotificationCount 
                notificationsTotal={notifications.length} 
                setShowNotificationsModal={() => setShowNotificationsModal(true)}
            />

            {showNotificationsModal &&
                <NotificationsListRightBar 
                    notifications={notifications} 
                    onHideBar={() => setShowNotificationsModal(false)}
                    reloadNitifications={reloadNitifications}
                />}
        </div>
    )
}


export default NotificationsBar;