import React, {useContext, useEffect, useState} from 'react'
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';
import debounce from 'lodash.debounce';
import "./InstallationRow.scss"

export default function InstallationRow({storeId, onChangeAmount}) {

  const api = useContext(ApiContext);
  const [store, setStore] = useState(null)
  const [first, setFirst] = useState(true)

  const debouncedChangeHandler = debounce(onChangeAmount, 1000);

  useEffect(() => {
    if (first) {
      setFirst(false);
      api.stores.get({id: storeId.toString()}).then(response => {
        setStore(response);
      }).catch(
        error => getNotifier().error(error.message)
      );
    }
  }, [api.stores, first, onChangeAmount, storeId])

  return (
    <div className='InstallationRow'>
      <div className='d-flex justify-content-between'>
        <div>
          Costo de instalación
        </div>
        <div>
          {
            store &&
            <input
              type="number"
              defaultValue={store.installationAmount}
              className="input-field"
              onChange={(e) => debouncedChangeHandler(e.target.value, storeId)}
            />
          }
        </div>
      </div>
    </div>
  )
}
