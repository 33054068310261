import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';
import { getFileUrl } from '../../../../../services/models/campaignFilesUtils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload
} from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import "./CampaignFiles.scss"

export default function CampaignFiles({campaign}) {

  const api = useContext(ApiContext);
  const [first, setFirst] = useState(true)
  const [files, setFiles] = useState([])

  const getCampaignFiles = useCallback(() => {
    api.materialFiles.get({
      params: {
        'campaign.id': campaign,
        sGroups: [
          'material_file_read',
          'material_file_read_image',
          'app_file_read'
        ]
      }
    }).then(response => {
      setFiles(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.materialFiles, campaign]);

  function downloadFile(fileId, originalName) {
    const downloadUrl = api.appFiles.getDownloadUrl(
      {id: fileId}
    );
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', originalName);
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    if (first) {
      setFirst(false);
      getCampaignFiles();
    }
  }, [first, getCampaignFiles])
  

  return (
    <div className='CampaignFiles'>
      <div className="row">
        {
          _.map(files, (file) => (
            <div
              className="col-6"
              key={file.id}
            >
              <div className='mt-2'>
                <FontAwesomeIcon
                  icon={faDownload}
                  className='cursor-pointer'
                  onClick={() => downloadFile(file.image.id, file.image.originalName)}
                />
              </div>
              <img
                src={getFileUrl(file.image.id, api)}
                alt="File"
                className='image-thumbnail'
                width={200}
              />
            </div>
          ))
        }
      </div>
    </div>
  )
}
