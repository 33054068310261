import React, {useCallback, useContext} from 'react';
import './Login.scss';
import {ApiContext} from "../../../services/api/api-config";
import useFormState from "../../../hooks/useFormState";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import HalfScreenLayout from "../../../components/layout/HalfScreenLayout/HalfScreenLayout";
import background from './../../../assets/images/backgrounds/Intersectlogin.jpg';
import TextInput from "../../../components/forms/TextInput/TextInput";
import {Link} from "react-router-dom";
import Button from "../../../components/molecules/Button/Button";
import PasswordInput from "../../../components/forms/PasswordInput/PasswordInput";
import {paths} from "../../../services/routes/notLoggedRoutes";
import logoPumaMostacho from '../../../assets/images/logos/logo_puma_mstch.png';


const Login=()=>{

  const api = useContext(ApiContext);
  const {t} = useTranslation();

  const {form, handleInputChange} = useFormState({});

  const login = useCallback((e) => {
    e.preventDefault();
    if (!form.user || !form.pass) {
      return;
    }
    api.login(form.user,form.pass);
  }, [api, form]);

  // --- login state message ------
  const loginState = useSelector(({api}) => api.loggedIn);
  const loading = useSelector(({loadingIds}) => loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);

  let loginMsg = null;

  switch (loginState) {
    case LOGIN_STATE.LOGIN_ERROR:
      loginMsg= 'Lo sentimos, hubo un error al iniciar sesión.';
      break;
    case LOGIN_STATE.BAD_CREDENTIALS:
      loginMsg= 'El usuario y contraseña no coinciden';
      break;
    default:
      loginMsg = null;
      break;
  }

  return (
    <HalfScreenLayout
      className='Login'
      rightBackground={background}
    >
      {
        [
          <div
            className='form-container text-center'
            key={'form'}
          >
            <div className='row'>
              <div>
                <img src={logoPumaMostacho} alt="" className='logo-img'/>
              </div>
            </div>
            <h1 className='title'>
              { t("Log in") }
            </h1>
            <div className="row">&nbsp;</div>
            <form
              onSubmit={login}
              className='form text-left'
            >
              <TextInput
                label={ t("Email") }
                placeholder={t("Enter-email-here")}
                onChange={handleInputChange('user')}
                value={form.user || ''} 
                className={'TextInput--small'}
              />
              <PasswordInput
                placeholder={ t("Password") }
                onChange={handleInputChange('pass')}
                value={form.pass || ''}
              />
              <p className='forgot-section'>
                <Link to={paths.forgotPassword}>
                  { t("Forget-password") }
                </Link>
              </p>

              <div className='but-container'>
                <Button
                  className={'submit'}
                  type='submit'
                  disabled={loading}
                  design="secondary"
                >
                  { t("Log in") }
                </Button>
              </div>
              {
                loginMsg &&
                <p className='error'>
                  {loginMsg}
                </p>
              }
            </form>
          </div>
        ]
      }
    </HalfScreenLayout>
  );
};

export default Login;
