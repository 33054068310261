import React, {useEffect, useContext, useCallback} from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getNotifier } from '../../../../../services/notifier';
import { ApiContext } from '../../../../../services/api/api-config';
import  useFormState from '../../../../../hooks/useFormState';
import { SideMenu } from '../../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import Card from '../../../../../components/Dashboard/Card/Card';
import TextInput from '../../../../../components/forms/TextInput/TextInput';
import Button from '../../../../../components/molecules/Button/Button';
import { paths } from '../../../../../services/routes/appRoutes';
import {catMaterialTypeTableSGroups} from '../CatMaterialTypeUtils';
import "./CatMaterialTypeForm.scss"

export default function CatMaterialTypeForm() {

  const { id } = useParams();
  const api = useContext(ApiContext);
  const navigate = useNavigate();
  const { form, setForm, bindInput } = useFormState({});
  const loadingId = `Grower.form.${id}`;

  
  useEffect(() => {
    if (id) {
      api.catMaterialTypes.get(
        {
          id,
          params: {sGroups: catMaterialTypeTableSGroups}
        }
      )
        .then(async(materialType) => {
          setForm( materialType );
        })
        .catch(error => getNotifier().error(error.detail || error.message));
    }
  }, [api, id, setForm]);

  /**
   * Handle create or update material type
   */
  const handleCreateOrUpdateMaterialType = useCallback(() => {
    const {id} = form;
    const method = id ? 'update' : 'create';
    let materialType = null;

    try {
      materialType = form;
    } catch (error) {
      return getNotifier().error(error.message||error.detail);            
    }
    
    api.catMaterialTypes[method]({ id, params: materialType })
      .then(materialStatus => {
        getNotifier().success(
          id ? 'Actualizado correctamente' : 'Agregado correctamente'
        );
        navigate(paths.catMaterialType);
      }
    )
      .catch(error => {
        const message = error.detail.replace('name', 'Nombre')
          .replace('description', 'Descripción')
          .replace('clientAmount', 'Costo Cliente')
          .replace('vendorAmount', 'Costo Proveedor');
        getNotifier().error(error.message || message)
      });
  }, [api, form, navigate]);

  const handleChangeAmount = useCallback((event, name) => {
    const value = event.target.value;
    setForm({...form, [name]: value.slice(0, 8)});
  }, [form, setForm]);

  const loading = useSelector(s => !!s.loadingIds[loadingId]);

  return (
    <div className="CatMaterialTypeForm dashboard-container">
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <SectionTitle>
            { !id ? 'AGREGAR TIPO DE MATERIAL' : 'EDITAR TIPO DE MATERIAL' }
          </SectionTitle>
          <div className='row'>
            <Card className='OrderStep1'>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Nombre"
                    placeholder=""
                    {...bindInput('name')}
                    inputProps={
                      {
                        maxLength: 200
                      }
                    }
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 margin-top-mobile'>
                  <TextInput
                    label="Descripción"
                    placeholder=""
                    {...bindInput('description')}
                    inputProps={
                      {
                        maxLength: 200
                      }
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <TextInput
                    label="Costo Cliente"
                    placeholder=""
                    type="number"
                    // {...bindInput('clientAmount')}
                    onChange={(e) => handleChangeAmount(e, 'clientAmount')}
                    value={form.clientAmount || ''}
                  />
                </div>
                <div className='col-6'>
                  <TextInput
                    label="Costo Proveedor"
                    placeholder=""
                    type="number"
                    // {...bindInput('vendorAmount')}
                    onChange={(e) => handleChangeAmount(e, 'vendorAmount')}
                    value={form.vendorAmount || ''}
                  />
                </div>
              </div>
                      
              <div className='d-flex justify-content-center align-items-center buttons-container'>
                <Button
                  className={'btn-primary btn-outline'}
                  onClick={() => window.location.href = paths.catMaterialType }
                  design={'clear'}
                >
                  Cancel
                </Button>
                <Button 
                  className={`btn-primary btnNextStep`}
                  onClick={handleCreateOrUpdateMaterialType}
                  disabled={loading}
                >
                  { id ? 'Actualizar' : 'Agregar' }
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
