import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import { getFileUrl } from "../../../services/models/campaignFilesUtils";
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';

export const getEvidencesTableColumns = (
  {onRemoveClick, api, openImage}
) => [
  {
      Header: 'CAMPAÑA',
      accessor: evidence =>
      <div className='text-center'>
        {evidence.campaign.name}
      </div>
  },
  {
      Header: 'TIENDA',
      accessor: evidence =>
      <div className='text-center'>
        {evidence.store.name}
      </div>
  },
  {
      Header: 'MATERIAL',
      accessor: evidence =>
      <div className='text-center'>
        {evidence.material.catMaterialType.name + ' - ' + evidence.material.catMaterialType.description}
      </div>
  },
  {
    Header: 'ESTATUS',
    accessor: evidence =>
    <div className='text-center'>
      {evidence.status}
    </div>
  },
  {
    Header: 'FECHA',
    accessor: evidence =>
    <div className='text-center' 
    >
      {moment(evidence.createdDate).format(generalDatesFormat)}
    </div>
  },
  {
    Header: 'COMENTARIOS',
    accessor: evidence =>
    <div className='text-center'>
      {evidence.comments}
    </div>
  },
  {
      Header: 'EVIDENCIAS',
      accessor: evidence => <div
        style={{
          backgroundImage: `url(${getFileUrl(evidence.appFile.id, api)})`,
          width: '100px',
          height: '100px',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          cursor: 'pointer'
        }}
        onClick={() => openImage(evidence.appFile.id)}
      ></div>
  },
  {
      Header: 'ACCIONES',
      accessor: evidence => 
      <div className='text-center'>
        <span
          onClick={() => {
            onRemoveClick(evidence)
          }} 
          className="m-2 error cursor-pointer"
          title="Eliminar"
        >
          <FontAwesomeIcon icon={faTrash}/>
        </span>
      </div>
  }
];

export const getClientEvidencesTableColumns = (
  {api, openImage}
) => [
  {
      Header: 'Campaña',
      accessor: evidence =>
        <div className='text-center'>
          {evidence.campaign.name}
        </div>
  },
  {
      Header: 'Tienda',
      accessor: evidence =>
        <div className='text-center'>
          {evidence.store.name}
        </div>
  },
  {
      Header: 'Material',
      accessor: evidence =>
      <div className='text-center'>
        {evidence.material.catMaterialType.name + ' - ' + evidence.material.catMaterialType.description}
      </div>
  },
  {
    Header: 'Estatus',
    accessor: evidence =>
    <div className='text-center'>
      {evidence.status}
    </div>
  },
  {
    Header: 'Fecha',
    accessor: evidence =>
    <div className='text-center'>
      {moment(evidence.createdDate).format(generalDatesFormat)}
    </div>
  },
  {
    Header: 'Comentarios',
    accessor: evidence =>
    <div className='text-center'>
      {evidence.comments}
    </div>
  },
  {
      Header: 'Evidencias',
      accessor: evidence => <div
        style={{
          backgroundImage: `url(${getFileUrl(evidence.appFile.id, api)})`,
          width: '100px',
          height: '100px',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          cursor: 'pointer'
        }}
        onClick={() => openImage(evidence.appFile.id)}
      ></div>
  }
];

export const evidencesSGroups = [
  'evidence_read',
  'evidence_read_campaign',
  'campaign_read',
  'evidence_read_store',
  'store_read',
  'evidence_read_material',
  'material_read',
  'material_read_cat_material_type',
  'cat_material_type_read',
  'evidence_read_app_file',
  'app_file_read',
  'read_created_date'
];

export const evidenceMaterialStatus = [
  'Cancelado',
  'Entregado al VM',
  'Instalado',
  'Medidas Actualizadas',
  'No instalado',
  'No hay espacio asignado',
  'Tienda recibe'
];