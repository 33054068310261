import React from 'react'
import _ from "lodash";
import { moneyFormatter } from '../../../../../services/numberUtils';
import "./WorkOrderMaterials.scss"

export default function WorkOrderMaterials({workOrder, handleUpdateSpecifications}) {

  return (
    <div className='WorkOrderMaterials'>
      <div
        className="row material-header"
      >
        <div className="col-1">
          Cantidad
        </div>
        <div className="col-3">
          Nombre
        </div>
        <div className="col-3">
          Descripción
        </div>
        <div className="col-3">
          Especificaciones
        </div>
        <div className="col-2">
          Costo total
        </div>
      </div>
      {
        _.map(workOrder.materials, (material) => (
          <div
            className="row mb-2 mt-2"
            key={material.id}
          >
            <div className="col-1">
              <h3>
                {material.quantity}
              </h3>
            </div>
            <div className="col-3">
              {material.name}
            </div>
            <div className="col-3">
              {material.description}
            </div>
            <div className="col-3">
              <textarea
                onChange={(e) => handleUpdateSpecifications(e.target.value, material, workOrder.id)}
                value={material.specifications}
              ></textarea>
            </div>
            <div className="col-2">
              ${moneyFormatter(material.vendorAmount * material.quantity)}
            </div>
          </div>
        ))
      }
    </div>
  )
}
