import React, { useContext, useState } from 'react'
import {ReactComponent as EnableIcon} from "../../../../../assets/images/icons/enable.svg";
import {ReactComponent as DisableIcon} from "../../../../../assets/images/icons/disable.svg";
import Modal from '../../../../../components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../../../../services/api/api-config';
import { useSelector } from 'react-redux';
import './UserStatus.scss';
import { getNotifier } from '../../../../../services/notifier';

const UserStatus = ({ user }) => {
    const {t} = useTranslation();
    const api = useContext(ApiContext);
    const me = useSelector(({api})=>api.me);
    const [showingDisableModal, setShowingDisableModal] = useState();
    const [showingEnableModal, setShowingEnableModal] = useState();

    const handleDisableUser = () => {
        const disableParams = {isActive: false};
        api.users.update({id: user.id.toString(), params: disableParams})
            .then(() => {
                user.isActive=false;
                setShowingDisableModal(false);
                getNotifier().success(t('User-disabled'));
            })
            .catch((error) => getNotifier().error(error.message||error.details));
    }

    const handleEnableUser = () => {
        const disableParams = {isActive: true};
        api.users.update({id: user.id.toString(), params: disableParams})
            .then(() => {
                user.isActive=true;
                setShowingEnableModal(false);
                getNotifier().success(t('User-activated'));
            })
            .catch((error) => getNotifier().error(error.message||error.details));
    }

    return (
        <div className='UserStatus'>
            { user.id===me.id ? '':
                user.isActive ? 
                    <div 
                        onClick={() => setShowingDisableModal(true)} 
                        className="button"
                        data-tooltip={t('Disable-user')}
                    >
                        <DisableIcon /> 
                    </div>
                    : 
                    <div 
                        onClick={() => setShowingEnableModal(true)}
                        className="button"
                        data-tooltip={t('Activate-user')}
                    >
                        <EnableIcon />  
                    </div>}
            
            {showingDisableModal && 
                <Modal 
                    title={t('Disable-user')}
                    show={true}
                    onClose={() => setShowingDisableModal(false)}
                    mainButtonText={t('Disable')}
                    mainButtonAction={handleDisableUser}
                    secondaryButtonAction={() => setShowingDisableModal(false)}
                    secondaryButtonText={t('Cancelar')}
                    mainButtonColor='danger'
                >
                    <div className='UserStatus__message'>
                        {t('Are-you-sure-you-want-to-disable-this-user')}
                    </div>
                </Modal>}

            {showingEnableModal && 
                <Modal 
                    title={t('Activate-user')}
                    show={true}
                    onClose={() => setShowingEnableModal(false)}
                    mainButtonText={t('Activate')}
                    mainButtonAction={handleEnableUser}
                    secondaryButtonAction={() => setShowingEnableModal(false)}
                    secondaryButtonText={t('Cancel')}
                >
                    <div className='UserStatus__message'>
                        {t('Are-you-sure-you-want-to-activate-this-user')}
                    </div>
                </Modal>}
        </div>
    );
}

export default UserStatus