import React, {useContext, useEffect, useState, useCallback} from 'react'
import { SectionTitle } from '../../../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../../components/Dashboard/TopBar/TopBar';
import Card from '../../../../../components/Dashboard/Card/Card';
import "./QuoteDetails.scss";
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../../../../services/api/api-config';
import { getNotifier } from '../../../../../services/notifier';
import MaterialDetailRow from '../MaterialDetailRow/MaterialDetailRow';
import Button from '../../../../../components/molecules/Button/Button';
import { paths } from '../../../../../services/routes/appRoutes';
import { moneyFormatter } from '../../../../../services/numberUtils';
import InstallationRow from '../InstallationRow/InstallationRow';
import _ from 'lodash';
import { SecurityContext } from "../../../../../services/SecurityManager";

export default function QuoteDetails() {
  const { id } = useParams();
  const [campaignDetails, setCampaignDetails] = useState([])
  const [vendors, setVendors] = useState([]);
  const [campaignInfo, setCampaignInfo] = useState({})
  const [amountDetails, setAmountDetails] = useState({})
  const [storeGroups, setStoreGroups] = useState([])
  const [storeGroupDetails, setStoreGroupDetails] = useState([])
  const [installationAmounts, setInstallationAmounts] = useState([])
  const [hasWorkOrders, setHasWorkOrders] = useState(false)
  const initialShowValue = false;
  const api = useContext(ApiContext)

  //Only show buttons and info for actions available for the current role
  const security = useContext(SecurityContext);
  const onClientRole = security.isClient();
  const onVendorRole = security.isVendor();

  const checkHasWorkOrders = useCallback(() => {
    api.vendorWorkOrders.get(
      {
        params: {
          'campaign.id': id
        }
      }
    ).then(response => {
      if (response.length !== 0) {
        setHasWorkOrders(true);
      } else {
        setHasWorkOrders(false);
      }
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders, id]);

  /**
   * Get store groups
   */
  const getStoreGroups = useCallback((materialDetails) => {
    if (storeGroups.length === 0) {
      let storeDetailsByGroup = [];
      let storeDetailsWithoutGroup = [];
      api.storeGroups.get().then(storeGroupsResponse => {
        setStoreGroups(storeGroupsResponse);
        storeGroupsResponse.forEach((storeGroup, index) => {
          let theDetails = [];
          materialDetails.forEach(campaignDetail => {
            if (campaignDetail.storeGroup) {
              if (campaignDetail.storeGroup.id === storeGroup.id) {
                theDetails.push(
                  campaignDetail
                );
              }
            } else {
              if (index === 0) {
                storeDetailsWithoutGroup.push(campaignDetail)
              }
            }
          });
          if (theDetails.length > 0) {
            storeDetailsByGroup.push(
              {
                storeGroupId: storeGroup.id, 
                storeGroup: storeGroup.name,
                details: theDetails,
                show: initialShowValue
              }
            );
          }
        });
        if (storeDetailsWithoutGroup.length> 0) {
          storeDetailsByGroup.push(
            {
              storeGroupId: 0,
              storeGroup: 'Sin Cadena de Tienda',
              details: storeDetailsWithoutGroup,
              show: initialShowValue
            }
          );
        }
        setStoreGroupDetails(storeDetailsByGroup);
      } ).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.storeGroups, initialShowValue, storeGroups]);

  /**
   * Get vendors
   */
  const getVendors = useCallback(() => {
    if (vendors.length === 0 && !onClientRole) {
      api.catVendors.get().then((vendorsResponse) => {
        setVendors(vendorsResponse);
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.catVendors, vendors, onClientRole]);

  /**
   * Set campaign amounts
   */
  const updateCampaignAmounts = useCallback((theCampaign) => {
    let clientAmount = 0;
    let vendorAmount = 0;
    let installationAmount = 0;
    theCampaign.forEach(detail => {
      installationAmount += parseFloat(detail.installationAmount);
      detail.materials.forEach(materialDetail => {
        clientAmount += (parseFloat(materialDetail.amount) * parseFloat(materialDetail.quantity));
        vendorAmount += (parseFloat(materialDetail.vendorAmount) * parseFloat(materialDetail.quantity));
      });
    });
    setAmountDetails(
      {
        clientAmount: clientAmount,
        vendorAmount: vendorAmount,
        installationAmount: installationAmount
      }
    );
  }, []);

  /**
   * Get materials details
   */
  const getMaterials = useCallback(() => {
    if (campaignDetails.length === 0) {
      let materialDetailsResult = [];
      api.campaigns.getMaterialDetails({
        id
      }).then(campaignDetailsResponse => {
        campaignDetailsResponse.forEach(element => {
          element.show = initialShowValue;
          materialDetailsResult.push(element);
        });
        setCampaignDetails(
          materialDetailsResult
        );
        updateCampaignAmounts(campaignDetailsResponse);
        getStoreGroups(materialDetailsResult);
        getVendors();
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.campaigns, campaignDetails, getStoreGroups, getVendors, id, initialShowValue, updateCampaignAmounts]);

  /**
   * Get campaign info
   */
  const getCampaignInfo = useCallback(() => {
    if (!campaignInfo.id) {
      api.campaigns.get({id}).then(responseCampaignInfo => {
        setCampaignInfo(responseCampaignInfo);
        getMaterials();
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.campaigns, campaignInfo, getMaterials, id]);

  /**
   * Approve Quote
   */
  const approveQuote = useCallback(() => {
    api.campaigns.update({
      id,
      params: {
        quoteApproved: true
      }
    }).then(quoteResponse => {
      return getNotifier().success('Cotización aprobada correctamente');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaigns, id]);

  /**
   * Handle approve quote click
   */
  const handleApproveQuote = useCallback(() => {
    let hasError = false;
    campaignDetails.forEach(campaignDetail => {
      campaignDetail.materials.forEach(theMaterial => {
        if (theMaterial.vendor === 0) {
          hasError = true;
        }
      });
    });
    if (hasError) {
      return getNotifier().error('No se ha asignado el proveedor en todos los materiales');
    } else {
      approveQuote();
    }
  }, [approveQuote, campaignDetails]);
  
  /**
   * Handle Store Group Click
   */
  const handleStoreGroupClick = useCallback((storeGroupId) => {
    let newStoreGroupDetails = [];
    storeGroupDetails.forEach(element => {
      if (element.storeGroupId === storeGroupId) {
        element.show = !element.show;
      }
      newStoreGroupDetails.push(element);
    });
    setStoreGroupDetails(newStoreGroupDetails);
  }, [storeGroupDetails]);

  /**
   * Handle Store click
   */
  const handleStoreClick = useCallback( store => {
    let newStoreGroupDetails = [];
    storeGroupDetails.forEach(theStoreGroup => {
      let newDetails = [];
      theStoreGroup.details.forEach(element => {
        if (element.id === store.id) {
          element.show = !element.show;
        }
        newDetails.push(element);
      });
      theStoreGroup.details = newDetails;
      newStoreGroupDetails.push(theStoreGroup);
    });
    setStoreGroupDetails(newStoreGroupDetails);
  }, [storeGroupDetails]);

  /**
   * Handle onUpdated Row
   */
  const onUpdated = useCallback((detailUpdated) => {
    updateCampaignAmounts(campaignDetails);
  }, [campaignDetails, updateCampaignAmounts]);

  const updateInstallationAmount = useCallback((amount, storeId) => {
    api.stores.update(
      {
        id: storeId,
        params: {
          installationAmount: amount
        }
      }
    ).then(() => {
      let newInstallationAmount = 0;
      storeGroupDetails.forEach(storeGroupDetail => {
        storeGroupDetail.details.forEach(detail => {
          if (storeId === detail.storeId) {
            newInstallationAmount += parseFloat(amount);
          } else {
            newInstallationAmount += parseFloat(detail.installationAmount)
          }
        });
      });
      let newAmountDetails = {...amountDetails};
      newAmountDetails.installationAmount = newInstallationAmount;
      setAmountDetails(newAmountDetails);
      getNotifier().success('Monto de instalación actualizado para la tienda');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [amountDetails, api.stores, storeGroupDetails]);

  /**
   * Handle on change amount
   */
  const onChangeAmount = useCallback((amount, storeId) => {
    let amounts = [...installationAmounts];
    const found = amounts.find((element) => element.storeId === storeId);
    if (!found) {
      amounts.push({
        storeId: storeId,
        installationAmount: amount
      });
    } else {
      const theIndex = amounts.findIndex((element) => element.storeId === storeId);
      if (amounts[theIndex].installationAmount !== amount) {
        amounts[theIndex].installationAmount = amount;
      }
    }
    setInstallationAmounts(amounts);
    updateInstallationAmount(amount, storeId);
  }, [installationAmounts, updateInstallationAmount]);

  /**
   * Create Vendor Work Order
   */
  const createVendorWorkOrder = useCallback((vendor) => {
    api.vendorWorkOrders.create({
      params: {
        campaign: vendor.campaign,
        store: vendor.store,
        materials: vendor.materials,
        catVendor: vendor.vendor,
        schedules: ' '
      }
    }).then(() => {
      getNotifier().success('Actualizado correctamente');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders]);

  /**
   * Update Vendor Work Order
   */
  const updateVendorWorkOrder = useCallback((response, vendor) => {
    api.vendorWorkOrders.update({
      id: response.id,
      params: {
        campaign: vendor.campaign,
        store: vendor.store,
        materials: vendor.materials,
        catVendor: vendor.vendor,
        schedules: response.schedules
      }
    }).then(() => {
      getNotifier().success('Actualizado correctamente');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders]);

  /**
   * Remove vendor work order
   */
  const removeVendorWorkOrder = useCallback((vendor) => {
    api.vendorWorkOrders.delete({id: vendor.id}).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders]);

  /**
   * Handle send notifications to vendor when vendor work orders are generated
   */
  const handleSendMailNotifications = useCallback(() => {
    api.vendorWorkOrders.sendNotifications({
      params: {
        campaign: id,
        hasWorkOrders: hasWorkOrders ? '1' : '0'
      }
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders, hasWorkOrders, id]);

  /**
   * Handle remove vendor work order
   */
  const handleRemoveVendorWorkOrders = useCallback((vendorStore) => {
    api.vendorWorkOrders.get({
      params: {
        'campaign.id': id,
        sGroups: [
          'vendor_work_order_read',
          'vendor_work_order_read_store',
          'store_read',
          'vendor_work_order_read_cat_vendor',
          'cat_vendor_read',
          'vendor_work_order_read_campaign',
          'campaign_read'
        ]
      }
    }).then(response => {
      if (response.length > 0) {
        response.forEach(elementResponse => {
          const found = vendorStore.find(vendor => elementResponse.campaign.id === parseInt(vendor.campaign) && elementResponse.store.id === vendor.store && elementResponse.catVendor.id === parseInt(vendor.vendor));
          if (!found) {
            removeVendorWorkOrder(elementResponse);
          }
        });
        vendorStore.forEach(vendor => {
          const found = response.find(element => element.campaign.id === parseInt(vendor.campaign) && element.store.id === vendor.store && element.catVendor.id === parseInt(vendor.vendor));
          if (!found) {
            removeVendorWorkOrder(vendor);
          }
        }); 
      }
      handleSendMailNotifications();
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders, handleSendMailNotifications, id, removeVendorWorkOrder]);

  /**
   * Handle create or update vendor work order
   */
  const createOrUpdateVendorWorkOrders = useCallback((vendorStore) => {
    vendorStore.forEach(element => {
      api.vendorWorkOrders.get({
        params: {
          'campaign.id': element.campaign,
          'store.id': element.store,
          'catVendor.id': element.vendor
        }
      }).then(response => {
        if (response.length === 0) {
          createVendorWorkOrder(element);
        } else {
          updateVendorWorkOrder(response[0], element);
        }
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    });
    setTimeout(() => {
      handleRemoveVendorWorkOrders(vendorStore);
    }, 2000);
  }, [api.vendorWorkOrders, createVendorWorkOrder, handleRemoveVendorWorkOrders, updateVendorWorkOrder]);

  /**
   * Handle Create work orders click
   */
  const handleCreateWorkOrders = useCallback(() => {
    let vendorStore = [];
    let allMaterialsAssignedToVendor = true;
    storeGroupDetails.forEach(storeGroup => {
      storeGroup.details.forEach(detail => {
        detail.materials.forEach(material => {
          if (material.vendor === 0) {
            allMaterialsAssignedToVendor = false;
          }
          const theIndex = vendorStore.findIndex((vendor) => vendor.vendor === material.vendor && vendor.store === detail.storeId);
          if (theIndex === -1) {
            vendorStore.push(
              {
                vendor: material.vendor,
                campaign: id,
                store: detail.storeId,
                materials: [material]
              }
            );
          } else {
            vendorStore[theIndex].materials = [...vendorStore[theIndex].materials, material]
          }
        });
      });
    });
    if (!allMaterialsAssignedToVendor) {
      getNotifier().error('No se han asignado todos los proveedores a los materiales');
      return;
    }
    if (vendorStore.length > 0) {
      createOrUpdateVendorWorkOrders(vendorStore);
    }
  }, [createOrUpdateVendorWorkOrders, id, storeGroupDetails]);

  useEffect(() => {
    if (!campaignInfo.id) {
      getCampaignInfo();
      //Don't check for work orders if on client role, client can't create work orders
      if(!onClientRole)
        checkHasWorkOrders();
    }
  }, [campaignInfo.id, checkHasWorkOrders, getCampaignInfo, getMaterials, getVendors, onClientRole])
  
  return (
    <div className='QuoteDetails'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <div className='section-title-campaign'>
              <SectionTitle className="m-0 p-0">
                COTIZACIÓN DE {campaignInfo.name ? campaignInfo.name.toUpperCase() : ''}
              </SectionTitle>
            </div>
            <div className='subtotal d-flex flex-column'>
              {!onVendorRole && (
                <div className='d-flex'>
                  <div className='font-bold'>
                    Subtotal Cliente
                  </div>
                  <div className='ml-2'>
                    ${moneyFormatter(amountDetails?.clientAmount)}
                  </div>
                </div>
              )}
              {!onClientRole && (
                <div  className='d-flex'>
                  <div className='font-bold'>
                    Subtotal Proveedor
                  </div>
                  <div  className='ml-2'>
                    ${moneyFormatter(amountDetails?.vendorAmount)}
                  </div>
                </div>
              )}
              {
                !onVendorRole && !onClientRole &&
                <div  className='d-flex'>
                  <div className='font-bold'>
                    Total Instalación
                  </div>
                  <div  className='ml-2'>
                    ${moneyFormatter(amountDetails?.installationAmount)}
                  </div>
                </div>
              }
            </div>
            <div className='subtotal'>
              {campaignDetails.length} {campaignDetails.length > 1 ? 'Tiendas' : 'Tienda'}
            </div>
            {
              !onClientRole &&
              <Button
                className={'btn-primary btn-outline btn-sm'}
                design={'clear'}
              >
                {campaignInfo?.status}
              </Button>
            }
          </div>
          <div className="row mt-3">
            <Card className='overflow-y-auto'>
              {_.map(storeGroupDetails, (groupDetails) => (
                <div
                  className='store-group-row'
                  key={groupDetails.storeGroupId}
                >
                  <div 
                    className='d-flex justify-content-between cursor-pointer'
                    onClick={() => handleStoreGroupClick(groupDetails.storeGroupId)}
                  >
                    <div>
                      {groupDetails.storeGroup}
                    </div>
                    <div>
                      {groupDetails.details.length} {groupDetails.details.length > 1 ? 'Tiendas' : 'Tienda'}
                    </div>
                  </div>
                  {
                    groupDetails.show &&
                    <div className='details-row'>
                      {_.map(groupDetails.details, (theDetails) => (
                        <div key={theDetails.id}>
                          <div
                            className='store-name-row'
                          >
                            <div
                              onClick={() => handleStoreClick(theDetails)}
                              className={theDetails.show ? 'store-name-row-active cursor-pointer' : 'cursor-pointer'}
                            >
                              {theDetails.store}
                            </div>
                            {
                              theDetails.show &&
                              <>
                                <div
                                  className='detail-row-header'
                                >
                                  {!onClientRole && (
                                    <div className='material-field-header'>
                                      Proveedor
                                    </div>
                                  )}
                                  <div className='material-field-header'>
                                    Nombre
                                  </div>
                                  <div className='material-field-header'>
                                    Descripción
                                  </div>
                                  <div className='material-field-header'>
                                    Cantidad
                                  </div>
                                  {
                                    !onVendorRole &&
                                    <div className='material-field-header'>
                                      Precio unitario Cliente
                                    </div>
                                  }{
                                    !onVendorRole &&
                                    <div className='material-field-header'>
                                      Subtotal
                                    </div>
                                  }
                                  
                                  {!onClientRole && (
                                    <div className='material-field-header'>
                                      Precio unitario Proveedor
                                    </div>
                                  )}
                                  {!onClientRole && (
                                    <div className='material-field-header'>
                                    Subtotal
                                    </div>
                                    )}
                                </div>
                                {
                                  !onVendorRole && !onClientRole &&
                                  <InstallationRow
                                    storeId={theDetails.storeId}
                                    onChangeAmount={onChangeAmount}
                                  ></InstallationRow>
                                }
                                
                                <MaterialDetailRow
                                  details={theDetails}
                                  vendors={vendors}
                                  onUpdated={onUpdated}
                                ></MaterialDetailRow>
                              </>
                            }
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
              ))}
              <div
                className='d-flex flex-row'
              >
                <div className='material-field-total'>
                  &nbsp;
                </div>
                {!onVendorRole && (
                  <div className='material-field-total'>
                    <table className='table'>
                      <tbody>
                      <tr>
                        <td className='cell-label'>
                          Subtotal Cliente
                        </td>
                        <td className='cell-amount'>
                          {
                            !onClientRole &&
                            <span>
                              ${moneyFormatter(amountDetails?.clientAmount + amountDetails?.installationAmount)}
                            </span>
                          }
                          {
                            onClientRole &&
                            <span>
                              ${moneyFormatter(amountDetails?.clientAmount)}
                            </span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='cell-label'>
                          IVA
                        </td>
                        <td className='cell-amount'>
                          {
                            !onClientRole &&
                            <span>
                              ${moneyFormatter(
                                amountDetails.clientAmount
                                  ? (amountDetails.clientAmount + amountDetails?.installationAmount) * 0.16
                                  : 0
                              )}
                            </span>
                          }
                          {
                            onClientRole &&
                            <span>
                              ${moneyFormatter(
                                amountDetails.clientAmount
                                  ? (amountDetails.clientAmount) * 0.16
                                  : 0
                              )}
                            </span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='cell-label'>
                          Total Cliente
                        </td>
                        <td className='cell-amount'>
                          {
                            !onClientRole &&
                            <span>
                              ${moneyFormatter(
                                amountDetails.clientAmount
                                  ? ((amountDetails.clientAmount + amountDetails?.installationAmount) * 0.16 + (amountDetails.clientAmount + amountDetails?.installationAmount))
                                  : 0
                              )}
                            </span>
                          }
                          {
                            onClientRole &&
                            <span>
                              ${moneyFormatter(
                                amountDetails.clientAmount
                                  ? ((amountDetails.clientAmount) * 0.16 + (amountDetails.clientAmount))
                                  : 0
                              )}
                            </span>
                          }
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {!onClientRole && (
                  <div className='material-field-total'>
                    <table className='table'>
                      <tbody>
                      <tr>
                        <td className='cell-label'>
                          Subtotal Proveedor
                        </td>
                        <td className='cell-amount'>
                          ${moneyFormatter(amountDetails?.vendorAmount)}
                        </td>
                      </tr>
                      <tr>
                        <td className='cell-label'>
                          IVA
                        </td>
                        <td className='cell-amount'>
                          ${moneyFormatter(
                          amountDetails.vendorAmount
                            ? (amountDetails.vendorAmount) * 0.16
                            : 0
                        )}
                        </td>
                      </tr>
                      <tr>
                        <td className='cell-label'>
                          Total Proveedor
                        </td>
                        <td className='cell-amount'>
                          ${moneyFormatter(
                          amountDetails.vendorAmount
                            ? ((amountDetails.vendorAmount) * 0.16 + amountDetails.vendorAmount)
                            : 0
                        )}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="row mt-4">
                <div className="col">
                  <div className='d-flex justify-content-between'>
                    <Button
                      className={'btn-primary btn-outline'}
                      onClick={() => window.location.href = paths.quote }
                      design={'clear'}
                    >
                      Volver
                    </Button>
                    {!onVendorRole && (
                      <Button
                        className={'btn-primary btn-outline'}
                        onClick={handleApproveQuote}
                      >
                        Aprobar Cotización
                      </Button>
                    )}
                    {(!onClientRole && !onVendorRole) && (
                      <Button
                        className={'btn-secondary btn-outline'}
                        onClick={handleCreateWorkOrders}
                        design={'clear'}
                      >
                        Generar Órdenes de Trabajo
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
