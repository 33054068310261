import React, { useCallback, useContext, useEffect, useState } from 'react'
import "./StoreAlertForm.scss"
import { ApiContext } from '../../../../../../services/api/api-config'
import { getNotifier } from '../../../../../../services/notifier'
import _ from "lodash"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import Switch from '../../../../../../components/forms/Switch/Switch'

export default function StoreAlertForm() {

  const [stores, setStores] = useState([])
  const [first, setFirst] = useState(true)

  const api = useContext(ApiContext)

  /**
   * Handle Store show or hide
   */
  const handleStoreShow = useCallback((storeGroupId) => {
    let storesTemp = [...stores];
    const theIndex = storesTemp.findIndex(element => element.storeGroupId === storeGroupId);
    storesTemp[theIndex].show = !storesTemp[theIndex].show;
    setStores(storesTemp);
  }, [stores]);

  /**
   * Create or update store alert
   */
  const createOrUpdate = useCallback((storesTemp, store) => {
    api.storeAlerts.get({
      params: {
        'store.id': store.id
      }
    }).then(response => {
      if (response.length === 0) {
        api.storeAlerts.create({
          params: {
            store: store.id,
            active: store.storeAlert
          }
        });
      } else {
        api.storeAlerts.update({
          id: response[0].id,
          params: {
            active: store.storeAlert
          }
        });
      }
      setStores(storesTemp);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.storeAlerts]);

  /**
   * Handle switch change
   */
  const handleSwitchChange = useCallback((storeGroupId, storeId) => {
    let storesTemp = [...stores];
    const storeGroupIndex = storesTemp.findIndex(element => element.storeGroupId === storeGroupId);
    const storeIndex = storesTemp[storeGroupIndex].stores.findIndex(element => element.id === storeId);
    storesTemp[storeGroupIndex].stores[storeIndex].storeAlert = !storesTemp[storeGroupIndex].stores[storeIndex].storeAlert;
    createOrUpdate(storesTemp, storesTemp[storeGroupIndex].stores[storeIndex]);
  }, [createOrUpdate, stores]);

  const handleAllSelected = useCallback((store) => {
    const isAllSelected = !store.allSelected;
    let storesTemp = [...stores];
    const storeGroupIndex = storesTemp.findIndex(element => element.storeGroupId === store.storeGroupId);
    storesTemp[storeGroupIndex].allSelected = !storesTemp[storeGroupIndex].allSelected;
    store.stores.forEach(element => {
      if (element.storeAlert !== isAllSelected) {
        element.storeAlert = isAllSelected;
        createOrUpdate(storesTemp, element);
      }
    });
  }, [createOrUpdate, stores]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      api.stores.get({
        params: {
          sGroups: [
            'store_read_id',
            'store_read_name',
            'store_read_store_alert',
            'store_alert_read',
            'store_read_store_group',
            'store_group_read'
          ],
          'order[storeGroup.id]': 'ASC',
          'order[name]': 'ASC',
          pagination: false
        }
      }).then(response => {
        let theStores = [];
        let storeGroup = {
          id: 0,
          name: ''
        };
        response.forEach(element => {
          if (element.storeGroup.id !== storeGroup.id) {
            storeGroup.id = element.storeGroup.id;
            storeGroup.name = element.storeGroup.name;
            theStores.push(
              {
                show: false,
                allSelected: false,
                storeGroupId: element.storeGroup.id,
                storeGroupName: element.storeGroup.name,
                stores: [
                  {
                    id: element.id,
                    name: element.name,
                    storeAlert: element.storeAlerts.length > 0 ? element.storeAlerts[0].active : false
                  }
                ]
              }
            );
          } else {
            const theIndex = theStores.findIndex(theStore => theStore.storeGroupId === element.storeGroup.id);
            theStores[theIndex].stores.push(
              {
                id: element.id,
                name: element.name,
                storeAlert: element.storeAlerts.length > 0 ? element.storeAlerts[0].active : false
              }
            );
          }
        });
        setStores(theStores);
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.storeAlerts, api.stores, first])
  

  return (
    <div className='StoreAlertForm'>
      {
        _.map(stores, (store, index) => (
          <div
            className='d-flex flex-column group-row'
            key={index}
          >
            <div className='d-flex justify-content-between'>
              <div className='store-group-name'>
                {
                  store.storeGroupName
                }
              </div>
              <div className=''>
                {
                  store.show &&
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className='menu-icon cursor-pointer float-right'
                    onClick={() => handleStoreShow(store.storeGroupId)}
                  />
                }
                {
                  !store.show &&
                  <FontAwesomeIcon
                    icon={faEye}
                    className='menu-icon cursor-pointer float-right'
                    onClick={() => handleStoreShow(store.storeGroupId)}
                  />
                }
              </div>
            </div>
            {
              store.show &&
              <div>
                <div className='d-flex justify-content-end'>
                  <div className='d-flex'>
                    <div className='select-all-label'>
                      Seleccionar todo
                    </div>
                    <Switch
                      label="Seleccionar todo"
                      value={store.allSelected}
                      onChange={() => handleAllSelected(store) }
                    />
                  </div>
                </div>
                <div className='store-list mt-4'>
                  {
                    _.map(store.stores, (theStore) => (
                      <div
                        key={theStore.id}
                        className='d-flex justify-content-between stores-row'
                      >
                        <div>
                          {theStore.name}
                        </div>
                        <div>
                        <Switch
                          id={theStore.id}
                          label={'Activo'}
                          value={theStore.storeAlert}
                          onChange={() => handleSwitchChange(store.storeGroupId, theStore.id) }
                        />
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
          </div>
        ))
      }
    </div>
  )
}
