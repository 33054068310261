import React, { useCallback, useContext, useEffect, useState } from 'react'
import "./MaterialsModal.scss"
import { ApiContext } from '../../../../../../services/api/api-config';
import { getNotifier } from '../../../../../../services/notifier';
import { getDiffDaysFromNow } from '../../../../../../services/dateFormatUtils';
import {generalDatesFormat} from '../../../../../../services/generalConfig';
import _ from "lodash";
import moment from 'moment';

export default function MaterialsModal(
  {store}
) {

  const [first, setFirst] = useState(true);
  const [materials, setMaterials] = useState([])

  const api = useContext(ApiContext);

  const getMaterials = useCallback(() => {
    api.materials.get({
      params: {
        'store.id': store.id,
        sGroups: [
          'material_read',
          'material_read_cat_material_type',
          'cat_material_type_read',
          'update_date'
        ]
      }
    }).then(response => {
      setMaterials(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.materials, store.id]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getMaterials();
    }
  }, [first, getMaterials]);
  

  return (
    <div className='MaterialsModal'>
      <table className='table'>
        <thead>
          <tr>
            <th>
              Nombre
            </th>
            <th>
              Estatus
            </th>
            <th>
              Última modificación
            </th>
            <th>
              Días sin modificación
            </th>
          </tr>
        </thead>
        <tbody>
          {
            _.map(materials, (material) => (
              <tr
                key={material.id}
              >
                <td>
                  {material.catMaterialType.name} {material.catMaterialType.description}
                </td>
                <td>
                  {material.status ? material.status : 'n/a'}
                </td>
                <td>
                  {
                    moment(material.updatedDate).format( generalDatesFormat )
                  }
                </td>
                <td
                >
                  <div
                    className={getDiffDaysFromNow(material.updatedDate) >= 90 ? 'pill-danger' : getDiffDaysFromNow(material.updatedDate) >= 60 ? 'pill-warning' : ''}
                  >
                    {getDiffDaysFromNow(material.updatedDate)}
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}
