import React, { useCallback, useContext, useMemo, useState } from 'react'
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import Button from '../../../components/molecules/Button/Button';
import Card from '../../../components/Dashboard/Card/Card';
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import { paths } from '../../../services/routes/appRoutes';
import TideReactTable from '../../../components/utility/TideReactTable/TideReactTable';
import "./Evidences.scss"
import { useNavigate } from 'react-router-dom';
import useFormState from '../../../hooks/useFormState';
import { ApiContext } from '../../../services/api/api-config';
import useTideTable from '../../../components/utility/TideReactTable/useTideTable';
import Modal from '../../../components/Modal/Modal';
import { getNotifier } from '../../../services/notifier';
import { getEvidencesTableColumns, evidencesSGroups, getClientEvidencesTableColumns } from './EvidenceStoreUtils';
import EvidenceImage from './EvidenceImage/EvidenceImage';
import {SecurityContext} from "../../../services/SecurityManager";

export default function Evidences() {

  const navigate = useNavigate();
  const {form, setForm} = useFormState({});
  const [showingDeleteModalConfirm, setShowingDeleteModalConfirm] = useState();
  const [imageToShow, setImageToShow] = useState()
  const [evidenceToDelete, setEvidenceToDelete] = useState(null)

  const api = useContext(ApiContext);
  const customProp = 'campaignList';

  //Don't show add client, ROLE CLIENT does not have permissions
  const security = useContext(SecurityContext);
  const onClientRole = security.role === 'ROLE_CLIENT';

  /**
   * Handle new user click
   */
  const handleNewEvidence = useCallback( () => navigate(paths.evidenceForm), [navigate]);

  /**
   * Show modal on remove click
   */
  const onRemoveClick = useCallback((evidence) => {
    setEvidenceToDelete(evidence);
    setShowingDeleteModalConfirm(true);
  }, []);

  const openImage = useCallback((imageId) => {
    setImageToShow(imageId);
  }, []);

  /**
   * Get evidence table columns
   */
  const evidencesTableColumns = useMemo(() => getEvidencesTableColumns({onRemoveClick, api, openImage}), [api, onRemoveClick, openImage]);
  const evidencesClientTableColumns = useMemo(() => getClientEvidencesTableColumns({api, openImage}), [api, openImage]);

  /**
   * Request filters
   */
  const requestFilters = useMemo(() => ({
    sGroups: evidencesSGroups,
    search: form.email,
    'order[id]': 'desc'
  }), [form.email]);

  const requestOptions = useMemo(() => ({ customProp }), []);

  /**
   * Create evidences tide table
   */
  const table = useTideTable({
    entity: 'evidence',
    columns: onClientRole ? evidencesClientTableColumns : evidencesTableColumns,
    requestFilters,
    requestOptions
  });

  const getEvidences = useCallback(() => {
    api.evidence.get({
      params: {
        sGroups: evidencesSGroups
      },
      customProp
    });
  }, [api.evidence]);

  const updateMaterialStatus = useCallback(() => {
    console.log('evidenceToDelete: ', evidenceToDelete);
    api.materials.update({id: evidenceToDelete.material.id, params: {status: ''}}).then(() => {
      setEvidenceToDelete(null);
      setShowingDeleteModalConfirm(false);
      getNotifier().success('Registro eliminado correctamente');
      getEvidences();
    }).catch(() => {
      getNotifier().error('Ocurrió un error al actualizar el status del material');
      setEvidenceToDelete(null);
    });
  }, [api.materials, getEvidences, evidenceToDelete]);

  /**
   * Handle remove confirm
   */
  const handleRemove = useCallback(() => {
    if (evidenceToDelete) {
      api.evidence.delete(
        {id: evidenceToDelete.id}
      ).then(response => {
        updateMaterialStatus();
      })
      .catch(() => {
        getNotifier().error('Ocurrió un error al eliminar el registro');
        setEvidenceToDelete(null);
      });
    }
  }, [api.evidence, evidenceToDelete, updateMaterialStatus]);

  /**
   * Close modal on cancel
   */
  const closeModal = useCallback(() => {
    setEvidenceToDelete(null);
    setShowingDeleteModalConfirm(false);
  }, []);

  return (
    <div className='Evidences'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              EVIDENCIAS
            </SectionTitle>
            {!onClientRole && (
              <Button
                className={'submit btnNewProducer'}
                type='button'
                onClick={handleNewEvidence}
                id="add-new-store"
              >
                + Agregar Evidencia
              </Button>
            )}
          </div>

          <div className="row mt-3">
            <Card>
              <div className='d-flex justify-content-between align-items-center gap-3 mt-3 mb-3'>
                <SearchTextbox
                    placeholder="Búsqueda"
                    value={form.email || ''}
                    onChange={e => setForm({email: e.target.value})}
                />
              </div>
              <TideReactTable 
                  {...table.tableProps}
                  className="users-table"
              />
              {
                showingDeleteModalConfirm &&
                <Modal
                  title="Confirmar Eliminación"
                  mainButtonText="Confirmar"
                  mainButtonColor='danger'
                  mainButtonAction={handleRemove}
                  secondaryButtonText={'Cancelar'}
                  secondaryButtonAction={() => {
                      setShowingDeleteModalConfirm(false);
                      setEvidenceToDelete(null);
                  }}
                  onClose={closeModal}
                >
                  <div className='GrowerChangeStatusButtons__modal-content'>
                    <div className='GrowerChangeStatusButtons__modal-content-title'>
                      ¿Está seguro de eliminar el registro?
                    </div>
                  </div>
                </Modal>
              }

              {
                imageToShow &&
                <Modal
                  title="Foto de evidencia"
                  secondaryButtonText={'Cerrar'}
                  secondaryButtonAction={() => {
                    setImageToShow(null);
                  }}
                  onClose={() => setImageToShow(null)}
                  large={true}
                >
                  <EvidenceImage
                    imageId={imageToShow}
                  />
                </Modal>
              }
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
