import React, { useCallback, useContext, useEffect, useState } from 'react'
import "./StoreAlertReport.scss"
import { SideMenu } from '../../../../../../components/Dashboard/SideMenu/SideMenu'
import TopBar from '../../../../../../components/Dashboard/TopBar/TopBar'
import { SectionTitle } from '../../../../../../components/Dashboard/SectionTitle/SectionTitle'
import Button from '../../../../../../components/molecules/Button/Button'
import Modal from '../../../../../../components/Modal/Modal'
import StoreAlertForm from '../StoreAlertForm/StoreAlertForm'
import Card from '../../../../../../components/Dashboard/Card/Card'
import { ApiContext } from '../../../../../../services/api/api-config'
import { getNotifier } from '../../../../../../services/notifier'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye
} from '@fortawesome/free-solid-svg-icons';
import _ from "lodash"
import MaterialsModal from '../MaterialsModal/MaterialsModal'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../../../../services/routes/appRoutes'

export default function StoreAlertReport() {

  const [showModal, setShowModal] = useState(false)
  const [storeSelected, setStoreSelected] = useState(null)
  const [first, setFirst] = useState(true)
  const [stores, setStores] = useState([])
  const navigate = useNavigate();

  const api = useContext(ApiContext);

  const getStores = useCallback(() => {
    api.storeAlerts.get({
      params: {
        active: true,
        sGroups: [
          'store_alert_read',
          'store_alert_read_store',
          'store_read'
        ]
      }
    }).then(response => {
      setStores(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.storeAlerts]);

  const showMaterials = useCallback((store) => {
    setStoreSelected(store);
  }, []);

  const goToExpired = useCallback(() => {
    navigate(paths.reportStoreAlertExpired);
  }, [navigate]);

  useEffect(() => {
    if (first) {
      setFirst(false)
      getStores();
    }
  }, [first, getStores])
  

  return (
    <div className='StoreAlertReport'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              ALERTA DE MATERIALES POR TIENDA
            </SectionTitle>
            <div className='d-flex'>
              <Button 
                className={`btn-primary mr-4`}
                onClick={() => setShowModal(true)}
              >
                Configurar Alertas
              </Button>
              <Button 
                className={`btn-secondary`}
                onClick={() => goToExpired()}
              >
                Tiendas con Materiales vencidos
              </Button>
            </div>
          </div>

          <div className='mt-4'>
            <Card>
              {
                _.map(stores, (store) => (
                  <div
                    className='d-flex justify-content-between custom-row'
                    key={store.id}
                  >
                    <div>
                      {store.store.name}
                    </div>
                    <div>
                    <FontAwesomeIcon
                      icon={faEye}
                      className='cursor-pointer mr-2'
                      onClick={() => showMaterials(store.store)}
                    />
                    </div>
                  </div>
                ))
              }
            </Card>
          </div>

          {
            showModal &&
            <Modal
              title="Alertas por Tienda"
              onClose={() => setShowModal(false)}
            >
              <StoreAlertForm></StoreAlertForm>
            </Modal>
          }

          {
            storeSelected &&
            <Modal
              title={`${storeSelected.name}`}
              onClose={() => setStoreSelected(null)}
            >
              <MaterialsModal
                store={storeSelected}
              ></MaterialsModal>
            </Modal>
          }
        </div>
      </div>
    </div>
  )
}
