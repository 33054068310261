import { Link } from "react-router-dom";
import { paths } from "../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { generalDatesFormat } from '../../../services/generalConfig';


export const getQuoteTableColumns = () => [
  {
      Header: 'ID',
      accessor: quote =>
      <div className='text-center'>
        {quote.id}
      </div>
  },
  {
    Header: 'CAMPAÑA',
    accessor: quote =>
      <div className='text-center'>
        {quote.campaign.name}
      </div>
  },
  {
      Header: 'TIENDA',
      accessor: quote =>
        <div className='text-center'>
          {quote.store.name}
        </div>
  },
  {
    Header: 'MONTO',
    accessor: quote =>
      <div className='text-center'>
        {quote.amount}
      </div>
  },
  {
    Header: 'MONTO DE INSTALACIÓN',
    accessor: quote =>
      <div className='text-center'>
        {quote.amount_installation}
      </div>
  },
  {
    Header: 'ESTATUS',
    accessor: quote =>
      <div className='text-center'>
        {quote.status}
      </div>
  },
  {
    Header: 'FECHA DE CREACIÓN',
    accessor: quote =>
      <div className='text-center'>
        {moment(quote.createdDate).format( generalDatesFormat )}
      </div>
  },
  {
      Header: 'ACCIONES',
      accessor: quote => 
      <span
        className='ActionSee d-flex justify-content-center align-items-center' 
      >
        <Link
            to={ paths.quoteDetails.replace(":id", quote.id) }
            className="m-2 details-link"
            title="Ver Detalles"
        >
            <FontAwesomeIcon icon={faEye}/>
        </Link>
      </span>
  }
];

export const getCampaignQuoteTableColumns = () => [
  {
    Header: 'CAMPAÑA',
    accessor: campaign =>
      <div className='text-center'>
        {campaign.name}
      </div>
  },
  {
    Header: 'MASTER',
    accessor: campaign =>
      <div className='text-center'>
        {campaign.master}
      </div>
  },
  {
    Header: 'FECHA DE PUBLICACIÓN',
    accessor: campaign =>
      <div className='text-center'>
        {moment(campaign.publishDate).format( generalDatesFormat )}
      </div>
  },
  {
      Header: 'ACCIONES',
      accessor: campaign => 
      <span
        className='ActionSee d-flex justify-content-center align-items-center' 
      >
        <Link
            to={ paths.quoteDetails.replace(":id", campaign.id) }
            className="m-2 details-link"
            title="Ver Detalles"
        >
            <FontAwesomeIcon icon={faEye}/>
        </Link>
      </span>
  }
];

export const quoteSGroups = [
  'quote_read',
  'quote_read_campaign',
  'campaign_read',
  'quote_read_store',
  'store_read'
];

export const campaignQuoteSGroups = [
  'campaign_read',
  'campaign_read_campaign_details',
  'campaign_detail_read'
];

export const campaignDetailsQuoteSGroups = [
  'campaign_detail_read',
  'campaign_detail_read_store',
  'store_read',
  'campaign_detail_read_material_with_names'
];