import React, {useCallback, useContext, useState, useEffect} from 'react'
import Card from '../../../../../../components/Dashboard/Card/Card'
import TextInput from '../../../../../../components/forms/TextInput/TextInput'
import Button from '../../../../../../components/molecules/Button/Button'
import useFormState from '../../../../../../hooks/useFormState'
import { ApiContext } from '../../../../../../services/api/api-config'
import { getNotifier } from '../../../../../../services/notifier'
import { prepareStoreMaterialToServer } from '../../../../Store/StoreUtils'
import TideEntitySelect from '../../../../../../components/utility/TideEntitySelect/TideEntitySelect'
import "./AddMaterialToStore.scss"

export default function AddMaterialToStore(
  {onSuccess, storeId}
) {
  const { form, setForm, bindInput } = useFormState();
  const [materialType, setMaterialType] = useState('');
  const [store, setStore] = useState(null)
  const [active, setActive] = useState(null)

  const api = useContext(ApiContext);

  /**
   * Get store info
   */
  const getStore = useCallback(() => {
    api.stores.get({id: storeId.toString()}).then(response => {
      setStore(response);
    }).catch((error) => {
      getNotifier().error('Ocurrió un error al obtener La información de la tienda: ', error);
    });
  }, [api, storeId]);

  /**
   * Handle change
   */
  const handleChange = useCallback((value) => {
    setMaterialType(value);
    setForm({...form, materialType: value.id});
  }, [form, setForm]);

  /**
   * Handle change active or inactive
   */
  const handleChangeActive = useCallback((value) => {
    setActive(value);
  }, []);

  /**
   * Handle add material
   */
  const handleAddMaterial = useCallback(async (value) => {
    let material = null;
    try {
      material = prepareStoreMaterialToServer(form, active, materialType, store, false);    
    } catch (error) {
      return getNotifier().error(error.message||error.detail);
    }

    api.materials.create(
      {
        params: material
      }
    )
      .then(() => {
        getNotifier().success('Se agregó el material correctamente');
        onSuccess();
      })
      .catch(error => {
        getNotifier().error(error.message||error.detail);                
      });
  }, [active, api.materials, form, materialType, onSuccess, store]);

  const customLabelCreator = useCallback((value) => {
    return value.name + ' - ' + value.description;
  }, []);

  useEffect(() => {
    if (!store) {
      getStore();
    }
  }, [api, getStore, store]);

  return (
    <div className='AddMaterialToStore'>
      <div className='row'>
        <Card className='OrderStep1'>
          <div className='row'>
            <div className="col">
              <TideEntitySelect
                entity='catMaterialTypes'
                className='stores-select'
                placeholder='Selecciona el tipo de material'
                preload
                onChange={handleChange}
                autoSelect={false}
                labelCreator={customLabelCreator}
                value={materialType}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6">
              <TextInput
                label="Medidas"
                placeholder=""
                {...bindInput('sizes')}
              />
            </div>
            <div className="col col-md-6">
              <label htmlFor="active">
                ¿Activo?
              </label>
              <select
                  className="form-select"
                  value={active || ''}
                  onChange={(e) => handleChangeActive(e.target.value)}
                >
                  <option
                      className='font-bold'
                      key={0}
                      value=""
                  >
                    Seleccionar
                  </option>
                  <option
                      className='font-bold'
                      key={1}
                      value="1"
                  >
                      Si
                  </option>
                  <option
                      className='font-bold'
                      key={2}
                      value="2"
                  >
                      No
                  </option>
                </select>
            </div>
          </div>
                  
          <div className='d-flex justify-content-start align-items-center buttons-container'>
            <Button
              className={'btn-primary btn-outline'}
              onClick={handleAddMaterial}
            >
              Guardar
            </Button>
          </div>
        </Card>
      </div>
      </div>
  )
}
