import React, {useCallback, useContext, useEffect, useState} from 'react'
import { ApiContext } from '../../../../../services/api/api-config';
import { vendorWorkOrderSGroups } from '../../WorkOrderUtils';
import { getNotifier } from '../../../../../services/notifier';
import WorkOrderMaterials from '../WorkOrderMaterials/WorkOrderMaterials';
import Modal from '../../../../../components/Modal/Modal';
import CampaignFiles from '../CampaignFiles/CampaignFiles';
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPaperclip,
    faArrowDown,
    faArrowUp
} from '@fortawesome/free-solid-svg-icons';
import "./VendorWorkOrder.scss"
import useDebounce from '../../../../../hooks/useDebounce';

export default function VendorWorkOrder({vendor, campaign}) {

  const api = useContext(ApiContext);
  const [workOrder, setWorkOrder] = useState([])
  const [first, setFirst] = useState(true)
  const [showStores, setShowStores] = useState(false);
  const [showCampaignFilesModal, setShowCampaignFilesModal] = useState(false)
  const [order, setOrder] = useState({})
  
  /**
   * Get work orders by vendor ID
   */
  const getWorkOrders = useCallback(() => {
    api.vendorWorkOrders.get(
      {
        params: {
          'catVendor.id': vendor.id,
          'campaign.id': campaign,
          sGroups: vendorWorkOrderSGroups,
        }
      }
    ).then(response => {
      let workOrdersDetail = [];
      response.forEach((element) => {
        workOrdersDetail.push(
          {
            id: element.id,
            store: element.store,
            materials: element.materials,
            schedules: element.schedules,
            status: element.status,
            show: false
          }
        );
      });
      setWorkOrder(workOrdersDetail);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders, campaign, vendor]);

  /**
   * Handle show stores
   */
  const handleClicked = useCallback(() => {
    setShowStores(!showStores);
  }, [showStores]);

  /**
   * Handle show store details
   */
  const handleStoreClick = useCallback((workOrderId) => {
    let workOrdersTemp = [...workOrder];
    const theIndex = workOrdersTemp.findIndex((order) => order.id === workOrderId);
    workOrdersTemp[theIndex].show = !workOrdersTemp[theIndex].show;
    setWorkOrder(workOrdersTemp);
  }, [workOrder]);

  /**
   * Update work order
   */
  const updateWorkOrder = useCallback((theWorkOrder) => {
    api.vendorWorkOrders.update({
      id: theWorkOrder.id,
      params: {
        materials: theWorkOrder.materials,
        schedules: theWorkOrder.schedules ? theWorkOrder.schedules : ' ',
        status: theWorkOrder.status ? theWorkOrder.status : ' '
      }
    }).then(response => {
      getNotifier().success('Actualizado correctamente');
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.vendorWorkOrders]);

  /**
   * Handle update specifications
   */
  const handleUpdateSpecifications = useCallback((value, material, workOrderId) => {
    let newWorkOrders = [...workOrder];
    const workOrderIndex = newWorkOrders.findIndex(order => order.id === workOrderId);
    const materialIndex = newWorkOrders[workOrderIndex].materials.findIndex(theMaterial => theMaterial.id === material.id);
    newWorkOrders[workOrderIndex].materials[materialIndex].specifications = value;
    setWorkOrder(newWorkOrders);
    updateWorkOrder(newWorkOrders[workOrderIndex]);
  }, [updateWorkOrder, workOrder]);

  /**
   * Handle update schedules
   */
  const handleUpdateSchedules = useCallback((value, order) => {
    let newWorkOrders = [...workOrder];
    const workOrderIndex = newWorkOrders.findIndex(theOrder => theOrder.id === order.id);
    newWorkOrders[workOrderIndex].schedules = value;
    updateWorkOrder(newWorkOrders[workOrderIndex]);
  }, [updateWorkOrder, workOrder]);

  /**
   * Debounce request
   */
  const debouncedRequest = useDebounce(() => {
    handleUpdateSchedules(order.schedules, order);
  });

  /**
   * Handle update Schedule
   */
  const updateSchedule = useCallback((value, order) => {
    let ordersTemp = [...workOrder];
    const theIndex = ordersTemp.findIndex(element => element.id === order.id);
    ordersTemp[theIndex].schedules = value;
    setWorkOrder(ordersTemp);
    setOrder(order);
    debouncedRequest();
  }, [debouncedRequest, workOrder]);

  /**
   * Handle update Status
   */
  const updateStatus = useCallback((value, order) => {
    let ordersTemp = [...workOrder];
    const theIndex = ordersTemp.findIndex(element => element.id === order.id);
    ordersTemp[theIndex].status = value;
    setWorkOrder(ordersTemp);
    setOrder(order);
    debouncedRequest();
  }, [debouncedRequest, workOrder]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getWorkOrders();
    }
  }, [first, getWorkOrders])  
  
  return (
    <div className='VendorWorkOrder'>
      {
        workOrder.length > 0  &&
        <div className={showStores ? 'vendor-row active' : 'vendor-row'}>
          <div
            className='row'
          >
            <div className='col-7'>
              <h5>
                {vendor.name}
              </h5>
            </div>
            <div
              className='col-2'
              onClick={() => setShowCampaignFilesModal(true)}
            >
              <FontAwesomeIcon
                icon={faPaperclip}
                className='cursor-pointer'
              />
            </div>
            <div  className='col-2'>
              {
                `${workOrder.length} ODT`
              }
            </div>
            <div  className='col-1' onClick={handleClicked}>
              {
                !showStores &&
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className='cursor-pointer'
                />
              }
              {
                showStores &&
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className='cursor-pointer'
                />
              }
            </div>
          </div>
          {
            showStores &&
            _.map(workOrder, (order) => (
              <div
                className='row store-row bg-white'
                key={order.id}
              >
                <div className="col grid-cols-4">
                  <div>
                    {order.store.name}
                  </div>
                  <div className='form-group p-2'>
                    <label
                        htmlFor="status"
                        className='mr-2'
                    >
                      Status:
                    </label>
                    <select
                        id="status"
                        value={order.status}
                        onChange={(e) => updateStatus(e.target.value, order)}
                        className="form-control"
                    >
                      <option value="En revisión">En revisión</option>
                      <option value="Aprobado y en producción">Aprobado y en producción</option>
                      <option value="Entregado y finalizado">Entregado y finalizado</option>
                      <option value="Incompleto">Incompleto</option>
                    </select>
                  </div>
                  <div className='form-group p-2'>
                    <label
                      htmlFor="schedules"
                      className='mr-2'
                    >
                      Horario: 
                    </label>
                    <input
                      id="schedules"
                      type="text"
                      value={order.schedules}
                      onChange={(e) => updateSchedule(e.target.value, order)}
                      className="form-control"
                    />
                  </div>
                  <div
                    onClick={() => handleStoreClick(order.id)}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      <span className='mr-2'>
                        {order.materials.length} {order.materials.length > 1 ? 'Materiales' : 'Material'}
                      </span>
                    </div>
                    <div>
                      {
                        !order.show &&
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className='cursor-pointer'
                        />
                      }
                      {
                        order.show &&
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          className='cursor-pointer'
                        />
                      }
                    </div>
                  </div>
                </div>
                {
                  order.show &&
                  <WorkOrderMaterials
                    workOrder={order}
                    key={order.id}
                    handleUpdateSpecifications={handleUpdateSpecifications}
                  ></WorkOrderMaterials>
                }
              </div>
            ))
          }
        </div>
      }
      {
        showCampaignFilesModal &&
        <Modal
          title="Archivos de la campaña"
          onClose={() => setShowCampaignFilesModal(false)}
        >
          <CampaignFiles
            campaign={campaign}
          ></CampaignFiles>
        </Modal>
      }
    </div>
  )
}
