import React, {useEffect, useState, useCallback} from 'react'
import { useContext } from 'react';
import { getNotifier } from '../../../../../../services/notifier';
import { ApiContext } from '../../../../../../services/api/api-config';
import _ from 'lodash';
import { storeMaterialSGroups } from '../../../CampaignUtils'; 
import Button from '../../../../../../components/molecules/Button/Button';
import "./MaterialsModal.scss";

export default function MaterialsModal({quote, onClose}) {
  const api = useContext(ApiContext);
  const [campaignDetail, setCampaignDetail] = useState(null)
  const [details, setDetails] = useState([]);
  const [firstTime, setFirstTime] = useState(true)

  /**
   * Get material info and create the material List array
   */
  const getMaterials = useCallback((materials) => {
    let materialList = [];
    materials.forEach(material => {
      api.materials.get({id: material.id.toString(), params: {sGroups: storeMaterialSGroups}}).then(materialResponse => {
        materialList.push({
          id: material.id,
          name: materialResponse.catMaterialType.name,
          description: materialResponse.catMaterialType.description,
          quantity: material.quantity,
          amount: material.amount,
          vendorAmount: material.vendorAmount,
          specifications: material.specifications,
          vendor: material.vendor
        });
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    });
    setTimeout(() => {
      setDetails(materialList);
    }, 500);
  }, [api.materials, setDetails]);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      api.campaignDetails.get({
        params: {
          'campaign.id': quote.campaign.id,
          'store.id': quote.store.id
        }
      }).then(detailsResponse => {
        setCampaignDetail(detailsResponse[0]);
        getMaterials(detailsResponse[0].materials);
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api, details, firstTime, getMaterials, quote, setDetails])

  /**
   * Handle change quantity for material
   */
  const changeQuantity = useCallback((id, quantity) => {
    const quantityFindIndex = details.findIndex((detail => detail.id === id));
    let detailsTemp = details;
    detailsTemp[quantityFindIndex].quantity = quantity;
    setDetails(detailsTemp);
  }, [details]);

  /**
   * Handle change amount for material
   */
  const changeAmount = useCallback((id, amount) => {
    const amountFindIndex = details.findIndex((detail => detail.id === id));
    let detailsTemp = details;
    detailsTemp[amountFindIndex].amount = amount;
    setDetails(detailsTemp);
  }, [details]);

  /**
   * Handle change vendor amount for material
   */
  const changeVendorAmount = useCallback((id, amount) => {
    const amountFindIndex = details.findIndex((detail => detail.id === id));
    let detailsTemp = details;
    detailsTemp[amountFindIndex].vendorAmount = amount;
    setDetails(detailsTemp);
  }, [details]);

  /**
   * Handle change specifications for material
   */
  const changeSpecifications = useCallback((id, specification) => {
    const specificationFindIndex = details.findIndex((detail => detail.id === id));
    let detailsTemp = details;
    detailsTemp[specificationFindIndex].specifications = specification;
    setDetails(detailsTemp);
  }, [details]);

  /**
   * Handle save materials and update quote amount
   */
  const handleSaveMaterials = useCallback(() => {
    let clientAmount = 0;
    details.forEach(detail => {
      clientAmount += parseFloat(detail.amount);
    });
    const params = {
      materials: details
    };
    api.campaignDetails.update(
      {
        id: campaignDetail.id,
        params
      }
    ).catch(
      error => getNotifier().error(error.detail || error.message)
    );
    const quoteParams = {
      id: quote.id,
      amount: clientAmount.toString()
    };
    api.quotes.update(
      {id: quote.id, params: quoteParams}
    ).then(quoteResponse => {
      onClose();
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api, campaignDetail, details, onClose, quote]);
  
  return (
    <div className='MaterialsModal'>
      {_.map(details, (detail) => (
        <div 
          className="row material-row"
          key={detail.id}
        >
          <div className="col-12">
            <span className='name'>
              {detail.name}
            </span>
            <br />
            <span className='description'>
              {detail.description}
            </span>
          </div>
          <div className="col-12 col-lg-3">
            <label htmlFor={`quantity${detail.id}`}>
              Cantidad
            </label>
            <input
              type="number"
              min={1}
              max={999999}
              id={`quantity${detail.id}`}
              defaultValue={detail.quantity}
              onChange={(e) => changeQuantity(detail.id, e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-1">&nbsp;</div>
          <div className="col-12 col-lg-3">
            <label htmlFor={`amount${detail.id}`}>
              Costo Cliente
            </label>
            <input
              type="number"
              min={1}
              max={999999}
              id={`amount${detail.id}`}
              defaultValue={detail.amount}
              onChange={(e) => changeAmount(detail.id, e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-1">&nbsp;</div>
          <div className="col-12 col-lg-3">
            <label htmlFor={`vendorAmount${detail.id}`}>
              Costo Proveedor
            </label>
            <input
              type="number"
              min={1}
              max={999999}
              id={`vendorAmount${detail.id}`}
              defaultValue={detail.vendorAmount}
              onChange={(e) => changeVendorAmount(detail.id, e.target.value)}
            />
          </div>
          <div className="col-12">
            <label htmlFor={`specifications${detail.id}`}>
              Especificaciones
            </label>
            <br />
            <textarea
              name=""
              id=""
              defaultValue={detail.specifications}
              onChange={(e) => changeSpecifications(detail.id, e.target.value)}
            ></textarea>
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col">
        <Button 
          className={`btn-primary btnNextStep`}
          onClick={handleSaveMaterials}
        >
          Guardar
        </Button>
        </div>
      </div>
    </div>
  )
}
