import React, { useCallback, useEffect, useState, useContext } from 'react'
import "./Reports.scss"
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu'
import TopBar from '../../../components/Dashboard/TopBar/TopBar'
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import { moneyFormatter } from '../../../services/numberUtils'
import { ApiContext } from '../../../services/api/api-config'

export default function Reports() {

  const [initDate, setInitDate] = useState(moment().startOf('month').format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));
  const [first, setFirst] = useState(true);
  const [reportData, setReportData] = useState({})

  const api = useContext(ApiContext);

  const updateInitDate = useCallback((e) => {
    setInitDate(e.target.value);
  }, []);

  const updateEndDate = useCallback((e) => {
    setEndDate(e.target.value);
  }, []);

  const getGeneralReport = useCallback(() => {
    api.reports.getGeneralReport({
      params: {
        initDate, endDate
      }
    }).then(response => {
      setReportData(response);
    }).catch(error => {
      console.log('error: ', error);
    });
  }, [api.reports, endDate, initDate]);

  const downloadCampaigns = useCallback(() => {
    window.location.href = api.reports.getReportCampaignExcel(initDate, endDate);
  }, [api.reports, endDate, initDate]);

  const downloadMaterialsInstalled = useCallback(() => {
    window.location.href = api.reports.getReportMaterialsExcel(initDate, endDate);
  }, [api.reports, endDate, initDate]);

  const downloadBuyOrders = useCallback(() => {
    window.location.href = api.reports.getReportWorkOrdersExcel(initDate, endDate);
  }, [api.reports, endDate, initDate]);

  const downloadQuotes = useCallback(() => {
    window.location.href = api.reports.getReportWorkOrdersAmountsExcel(initDate, endDate);
  }, [api.reports, endDate, initDate]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      getGeneralReport();
    }
  }, [first, getGeneralReport])
  

  return (
    <div className='Reports'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              Reporte General de la operación
            </SectionTitle>
          </div>
          <div className="row mt-4">
            <div className="col">
              <div>
                Selecciona las fechas para que se muestre la información
              </div>
              <div className='d-flex gap-3 mt-2'>
                <div className='d-flex flex-column col-3'>
                  <div className='text-left'>
                    Fecha de inicio
                  </div>
                  <input
                    type="date"
                    value={initDate}
                    onChange={(e) => updateInitDate(e)}
                    className='date-input'
                  />
                </div>
                <div className='d-flex flex-column col-3'>
                  <div className='text-left'>
                    Fecha Final
                  </div>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => updateEndDate(e)}
                    className='date-input'
                  />
                </div>
                <div className='col-3'>
                  <button
                    onClick={getGeneralReport}
                    className='Button basic mt-3'
                  >
                    Buscar
                  </button>
                </div>
              </div>
              <div className='report-content'>
                <div className='d-flex report-item justify-content-between'>
                  <div className='col-9'>
                    Campañas
                  </div>
                  <div className='d-flex justify-content-between col-3'>
                    <div>
                      {reportData.campaigns || 0}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        className='excel-icon cursor-pointer'
                        onClick={downloadCampaigns}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex report-item justify-content-between'>
                  <div className='col-9'>
                    Materiales Instalados
                  </div>
                  <div className='d-flex justify-content-between col-3'>
                    <div>
                      {reportData.materialsInstalled || 0}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        className='excel-icon cursor-pointer'
                        onClick={downloadMaterialsInstalled}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex report-item justify-content-between'>
                  <div className='col-9'>
                    Órdenes de compra
                  </div>
                  <div className='d-flex justify-content-between col-3'>
                    <div>
                      {reportData.vendorWorkOrders || 0}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        className='excel-icon cursor-pointer'
                        onClick={downloadBuyOrders}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex report-item justify-content-between'>
                  <div className='col-9'>
                    Montos de cotizaciones
                  </div>
                  <div className='d-flex justify-content-between col-3'>
                    <div>
                      ${moneyFormatter(reportData.workOrdersAmount || 0)}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        className='excel-icon cursor-pointer'
                        onClick={downloadQuotes}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
