import React, {useMemo, useCallback, useState, useContext } from 'react';
import { SectionTitle } from '../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../components/Dashboard/TopBar/TopBar';
import Button from '../../../components/molecules/Button/Button';
import Card from '../../../components/Dashboard/Card/Card';
import { SearchTextbox } from '../../../components/forms/SearchTextbox/SearchTextbox';
import useTideTable from "../../../components/utility/TideReactTable/useTideTable";
import TideReactTable from "../../../components/utility/TideReactTable/TideReactTable";
import useFormState from '../../../hooks/useFormState';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../services/routes/appRoutes';
import Modal from '../../../components/Modal/Modal';
import { getStoreTableColumns, storeSGroups, getStoreClientTableColumns, storeClientSGroups } from './StoreUtils';
import "./Store.scss";
import { ApiContext } from '../../../services/api/api-config';
import { getNotifier } from '../../../services/notifier';
import {SecurityContext} from "../../../services/SecurityManager";

export default function Store() {
  const security = useContext(SecurityContext);
  const [showingDeleteModalConfirm, setShowingDeleteModalConfirm] = useState();
  const [idToDelete, setIdToDelete] = useState(0);
  const {form, setForm} = useFormState({});
  const navigate = useNavigate();
  const customProp = 'storeList';

  const api = useContext(ApiContext);

  //Client ROLE should not see the installation amount
  const onClientRole = security.isClient();

  /**
   * Handle new user click
   */
  const handleNewStore = useCallback( () => navigate(paths.storeForm), [navigate]);

  /**
   * Show modal on remove click
   */
  const onRemoveClick = useCallback((id) => {
    setIdToDelete(id);
    setShowingDeleteModalConfirm(true);
  }, []);

  /**
   * Get store table columns
   */
  const storeTableColumns = useMemo(() => getStoreTableColumns({onRemoveClick}), [onRemoveClick]);
  const storeClientTableColumns = useMemo(() => getStoreClientTableColumns({onRemoveClick}), [onRemoveClick]);

  /**
   * Request filters
   */
  const requestFilters = useMemo(() => ({
    sGroups: storeSGroups,
    search: form.email
  }), [form.email]);

  const requestClientFilters = useMemo(() => ({
    sGroups: storeClientSGroups,
    search: form.email
  }), [form.email]);

  const requestOptions = useMemo(() => ({ customProp }), []);

  /**
   * Create stores tide table
   */
  const table = useTideTable({
    entity: 'stores',
    columns: onClientRole ? storeClientTableColumns : storeTableColumns,
    requestFilters: onClientRole ? requestClientFilters : requestFilters,
    requestOptions
  });

  /**
   * Handle remove confirm
   */
  const handleRemove = useCallback(() => {
    if (idToDelete > 0) {
      api.stores.delete(
        {id: idToDelete}
      ).then(response => {
        setIdToDelete(0);
        setShowingDeleteModalConfirm(false);
        getNotifier().success('Registro eliminado correctamente');
      })
      .catch(() => {
        getNotifier().error('Ocurrió un error al eliminar el registro');
        setIdToDelete(0);
      });
    }
  }, [api.stores, idToDelete]);

  /**
   * Close modal on cancel
   */
  const closeModal = useCallback(() => {
    setIdToDelete(false);
    setShowingDeleteModalConfirm(false);
  }, []);

  return (
    <div className='Store'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              TIENDAS
            </SectionTitle>
            <Button 
              className={'submit btnNewProducer'} 
              type='button' 
              onClick={handleNewStore}
              id="add-new-store"
            >
              + Agregar Tienda
            </Button>
          </div>

          <div className="row mt-3">
            <Card>
              <div className='d-flex justify-content-between align-items-center gap-3 mt-3 mb-3'>
                <SearchTextbox
                    placeholder="Búsqueda"
                    value={form.email}
                    onChange={e => setForm({email: e.target.value})}
                />
              </div>
              <TideReactTable 
                  {...table.tableProps}
                  className="users-table"
              />
              {
                showingDeleteModalConfirm &&
                <Modal
                  title="Confirmar Eliminación"
                  mainButtonText="Confirmar"
                  mainButtonColor='danger'
                  mainButtonAction={handleRemove}
                  secondaryButtonText={'Cancelar'}
                  secondaryButtonAction={() => {
                      setShowingDeleteModalConfirm(false);
                      setIdToDelete(0);
                  }}
                  onClose={closeModal}
                >
                  <div className='GrowerChangeStatusButtons__modal-content'>
                    <div className='GrowerChangeStatusButtons__modal-content-title'>
                      ¿Está seguro de eliminar el registro?
                    </div>
                  </div>
                </Modal>
              }
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
