import Dashboard from "../../scenes/Dashboard/Dashboard";
import CatMaterialStatus from "../../scenes/Logged/Catalogs/CatMaterialStatus/CatMaterialStatus";
import CatMaterialStatusForm from "../../scenes/Logged/Catalogs/CatMaterialStatus/CatMaterialStatusForm/CatMaterialStatusForm";
import CatMaterialType from "../../scenes/Logged/Catalogs/CatMaterialType/CatMaterialType";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHome,
    faCog,
    faUserCircle,
    faUser,
    faShoppingBag,
    faGlobe,
    faThList,
    faWrench,
    faStore,
    faFile,
    faLineChart
} from '@fortawesome/free-solid-svg-icons';

import i18n from "i18next";
import CatMaterialTypeForm from "../../scenes/Logged/Catalogs/CatMaterialType/CatMaterialTypeForm/CatMaterialTypeForm";
import Users from "../../scenes/Logged/Users/Users";
import CatVendor from "../../scenes/Logged/Catalogs/CatVendor/CatVendor";
import CatVendorForm from "../../scenes/Logged/Catalogs/CatVendor/CatVendorForm/CatVendorForm";
import Store from "../../scenes/Logged/Store/Store";
import StoreForm from "../../scenes/Logged/Store/StoreForm/StoreForm";
import Campaign from "../../scenes/Logged/Campaign/Campaign";
import CampaignForm from "../../scenes/Logged/Campaign/CampaignForm/CampaignForm";
import StoreMaterials from "../../scenes/Logged/Store/components/StoreMaterials/StoreMaterials";
import CampaignDetails from "../../scenes/Logged/Campaign/CampaignDetails/CampaignDetails";
import CampaignFiles from "../../scenes/Logged/Campaign/CampaignFiles/CampaignFiles";
import Quote from "../../scenes/Logged/Quote/Quote";
import QuoteDetails from "../../scenes/Logged/Quote/components/QuoteDetails/QuoteDetails";
import CampaignQuotes from "../../scenes/Logged/Campaign/CampaignQuotes/CampaignQuotes";
import WorkOrder from "../../scenes/Logged/WorkOrder/WorkOrder";
import WorkOrderDetails from "../../scenes/Logged/WorkOrder/components/WorkOrderDetails/WorkOrderDetails";
import CatStoreGroup from "../../scenes/Logged/Catalogs/CatStoreGroup/CatStoreGroup";
import CatStoreGroupForm from "../../scenes/Logged/Catalogs/CatStoreGroup/CatStoreGroupForm/CatStoreGroupForm";
import Evidences from "../../scenes/Logged/Evidences/Evidences";
import EvidenceForm from "../../scenes/Logged/Evidences/EvidenceForm/EvidenceForm";
import VendorWorkOrder from "../../scenes/Logged/Vendor/VendorWorkOrder/VendorWorkOrder";
import Reports from "../../scenes/Logged/Reports/Reports";
import StoreAlertReport from "../../scenes/Logged/Reports/components/StoreAlert/StoreAlertReport/StoreAlertReport";
import StoreAlertExpired from "../../scenes/Logged/Reports/components/StoreAlert/StoreAlertExpired/StoreAlertExpired";
// import { ROLE_GROWER, ROLE_WHOLESALE, ROLE_MIAMERKATO_ADMIN, SUPER_ADMIN } from "../models/userUtils";

export const paths={
  dashboard:'/dashboard',
  logOut: '/logout',
  catMaterialStatus: '/catalog/material-status',
  catMaterialStatusForm: '/catalog/material-status/form',
  catMaterialStatusFormEdit: '/catalog/material-status/form/:id/edit',
  catMaterialType: '/catalog/material-type',
  catMaterialTypeForm: '/catalog/material-type/form',
  catMaterialTypeFormEdit: '/catalog/material-type/form/:id/edit',
  catVendor: '/catalog/vendor',
  catVendorForm: '/catalog/vendor/form',
  catVendorFormEdit: '/catalog/vendor/form/:id/edit',
  users: '/users',
  store: '/store',
  storeForm: '/store/form',
  storeFormEdit: '/store/form/:id/edit',
  campaign: '/campaign',
  campaignForm: '/campaign/form',
  campaignFormEdit: '/campaign/form/:id/edit',
  storeMaterials: '/store/materials/:id/details',
  campaignDetails: '/campaign/details/:id',
  campaignFiles: '/campaign/files/:id',
  campaignQuotes: '/campaign/quotes/:id',
  quote: '/quote',
  quoteDetails: '/quote/details/:id',
  workOrder: '/work-order',
  workOrderDetails: '/work-order/details/:id',
  catStoreGroup: '/catalog/store-group',
  catStoreGroupForm: '/catalog/store-group/form',
  catStoreGroupEdit: '/catalog/store-group/:id/edit',
  evidences: '/evidences',
  evidenceForm: '/evidences/form',
  vendorWorkOrder: '/workOrder',
  reports: '/reports',
  reportStoreAlert: '/reports/store-alert',
  reportStoreAlertExpired: '/reports/store-alert-expired',
  reportGeneral: '/reports/general'
};

const getAppRoutes =(securityManager) => {
  let routes = [];
    
  if (securityManager.canSeeEntity() && (securityManager.role === 'SUPER_ADMIN' || securityManager.role === 'ROLE_GENERAL_ADMIN')) {
    // Dashboard
    routes.push(
      {
        path: paths.dashboard, 
        name: 'Home', 
        text: i18n.t('Home'),
        Component: Dashboard, 
        icon: <FontAwesomeIcon icon={faHome} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faHome} className='menu-icon-active'/>, 
        alerts: 0
      }
    );
    // Campañas
    routes.push(
      {
        path: paths.campaign, 
        name: 'campaign', 
        text: 'Campañas',
        Component: Campaign, 
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.campaignForm, 
        name: 'campaignForm', 
        text: 'Campaña Form',
        Component: CampaignForm, 
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.campaignFormEdit,
      name: 'campaignFormEdit',
      text: 'Editar Campaña',
      Component: CampaignForm,
      icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
      alerts: 0,
      hidden: true
    });
    routes.push(
      {
        path: paths.campaignQuotes, 
        name: 'campaignQuotes', 
        text: 'Cotizaciones de la campaña',
        Component: CampaignQuotes, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    routes.push(
      {
        path: paths.campaignDetails, 
        name: 'campaignDetails', 
        text: 'Detalle de Campaña',
        Component: CampaignDetails, 
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.campaignFiles,
      name: 'campaignFiles',
      text: 'Agregar Archivos a Campaña',
      Component: CampaignFiles,
      icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
      alerts: 0,
      hidden: true
    });
    

    // Cotizaciones
    routes.push(
      {
        path: paths.quote, 
        name: 'Quote', 
        text: 'Cotizaciones',
        Component: Quote, 
        icon: <FontAwesomeIcon icon={faThList} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faThList} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.quoteDetails, 
        name: 'quoteDetails', 
        text: 'Detalle de cotización',
        Component: QuoteDetails, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );

    // Órdenes de trabajo
    routes.push(
      {
        path: paths.workOrder, 
        name: 'workOrder', 
        text: 'Órdenes de trabajo',
        Component: WorkOrder, 
        icon: <FontAwesomeIcon icon={faWrench} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faWrench} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.workOrderDetails, 
        name: 'workOrderDetails', 
        text: 'Detalle órdenes de trabajo',
        Component: WorkOrderDetails, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );

    // Evidencias (Testigos)
    routes.push(
      {
        path: paths.evidences, 
        name: 'Evidences', 
        text: 'Evidencias',
        Component: Evidences, 
        icon: <FontAwesomeIcon icon={faFile} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faFile} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.evidenceForm, 
        name: 'evidenceForm', 
        text: 'EVidencia Form',
        Component: EvidenceForm, 
        icon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );

    // Tiendas
    routes.push(
      {
        path: paths.store, 
        name: 'store', 
        text: 'Tiendas',
        Component: Store, 
        icon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.storeForm, 
        name: 'storeForm', 
        text: 'Tienda Form',
        Component: StoreForm, 
        icon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.storeFormEdit,
      name: 'storeFormEdit',
      text: 'Editar Tienda',
      Component: StoreForm,
      icon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon-active'/>,
      alerts: 0,
      hidden: true
    });
    
    routes.push({
      path: paths.storeMaterials,
      name: 'storeMaterials',
      text: 'Materiales',
      Component: StoreMaterials,
      icon: <FontAwesomeIcon icon={faHome} className='menu-icon'/>,
      activeIcon: <FontAwesomeIcon icon={faHome} className='menu-icon'/>,
      alerts: 0,
      hidden: true
    });

    // Proveedores
    routes.push(
      {
        path: paths.catVendor, 
        name: 'vendor', 
        text: 'Proveedores',
        Component: CatVendor, 
        icon: <FontAwesomeIcon icon={faUserCircle} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faUserCircle} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.catVendorForm, 
        name: 'catVendorForm', 
        text: 'Proveedor form',
        Component: CatVendorForm, 
        icon: <FontAwesomeIcon icon={faUserCircle} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faUserCircle} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.catVendorFormEdit,
      name: 'catVendorFormEdit',
      text: 'Editar Proveedor',
      Component: CatVendorForm,
      icon: <FontAwesomeIcon icon={faUserCircle} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faUserCircle} className='menu-icon-active'/>,
      alerts: 0,
      hidden: true
    });

    // Usuarios
    routes.push({
      path: paths.users,
      name: 'Users',
      text: 'Usuarios',
      Component: Users,
      icon: <FontAwesomeIcon icon={faUser} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faUser} className='menu-icon-active'/>,
      alerts: 0,
      hidden: false
    });

    // Catálogos
    routes.push({
      path: '#',
      name: 'Catalogs',
      text: 'Catálogos',
      Component: Users,
      icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>,
      alerts: 0,
      hidden: false,
      hasSubmenu: true,
      submenus: [
        {
          path: paths.catStoreGroup, 
          name: 'storeGroup', 
          text: 'Cadena de Tiendas',
          Component: CatStoreGroup, 
          icon: <FontAwesomeIcon icon={faStore} className='menu-icon'/>, 
          activeIcon: <FontAwesomeIcon icon={faStore} className='menu-icon-active'/>, 
          alerts: 0,
          isSubmenu: true
        },
        {
          path: paths.catMaterialStatus, 
          name: 'materialStatus', 
          text: 'Estatus de Materiales',
          Component: CatMaterialStatus, 
          icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
          activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
          alerts: 0,
          isSubmenu: true
        },
        {
          path: paths.catMaterialType, 
          name: 'materialType', 
          text: 'Tipos de materiales',
          Component: CatMaterialType, 
          icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
          activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
          alerts: 0,
          isSubmenu: true
        }
      ]
    });

    // Catálogos - Estatus de Materiales
    routes.push(
      {
        path: paths.catMaterialStatus, 
        name: 'materialStatus', 
        text: 'Estatus de Materiales',
        Component: CatMaterialStatus, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    routes.push(
      {
        path: paths.catMaterialStatusForm, 
        name: 'catMaterialStatusForm', 
        text: 'Material estatus form',
        Component: CatMaterialStatusForm, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.catMaterialStatusFormEdit,
      name: 'catMaterialStatusFormEdit',
      text: 'Editar Material status',
      Component: CatMaterialStatusForm,
      icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
      alerts: 0,
      hidden: true
    });

    // Catálogo - Tipo de Materiales
    routes.push(
      {
        path: paths.catMaterialType, 
        name: 'materialType', 
        text: 'Tipos de materiales',
        Component: CatMaterialType, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    routes.push(
      {
        path: paths.catMaterialTypeForm, 
        name: 'catMaterialTypeForm', 
        text: 'Material type form',
        Component: CatMaterialTypeForm, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.catMaterialTypeFormEdit,
      name: 'catMaterialTypeFormEdit',
      text: 'Editar Material type',
      Component: CatMaterialTypeForm,
      icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
      alerts: 0,
      hidden: true
    });

    // Catálogo Cadena de Tiendas
    routes.push(
      {
        path: paths.catStoreGroup, 
        name: 'storeGroup', 
        text: 'Cadena de Tiendas',
        Component: CatStoreGroup, 
        icon: <FontAwesomeIcon icon={faStore} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faStore} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    routes.push(
      {
        path: paths.catStoreGroupForm, 
        name: 'CatStoreGroupForm', 
        text: 'Cadena de Tiendas',
        Component: CatStoreGroupForm, 
        icon: <FontAwesomeIcon icon={faStore} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faStore} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.catStoreGroupEdit,
      name: 'catStoreGroupEdit',
      text: 'Editar grupo de tiendas',
      Component: CatStoreGroupForm,
      icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
      alerts: 0,
      hidden: true
    });

    // Reporte - Alert Store
    routes.push({
      path: paths.reportStoreAlert,
      name: 'reportStoreAlert',
      text: 'Alerta de Tienda',
      Component: StoreAlertReport,
      icon: <FontAwesomeIcon icon={faLineChart} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faLineChart} className='menu-icon-active'/>, 
      alerts: 0,
      hidden: true
    });
    routes.push({
      path: paths.reportStoreAlertExpired,
      name: 'reportStoreAlertExpired',
      text: 'Alerta de Tienda - Materiales Expirados',
      Component: StoreAlertExpired,
      icon: <FontAwesomeIcon icon={faLineChart} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faLineChart} className='menu-icon-active'/>, 
      alerts: 0,
      hidden: true
    });

    routes.push({
      path: paths.reportGeneral,
      name: 'reportGeneral',
      text: 'General',
      Component: Reports,
      icon: <FontAwesomeIcon icon={faLineChart} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faLineChart} className='menu-icon-active'/>, 
      alerts: 0,
      hidden: true
    });

    // Reportes
    routes.push({
      path: '#',
      name: 'Reports',
      text: 'Reportes',
      Component: Reports,
      icon: <FontAwesomeIcon icon={faLineChart} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faLineChart} className='menu-icon-active'/>,
      alerts: 0,
      hidden: false,
      hasSubmenu: true,
      submenus: [
        {
          path: paths.reportStoreAlert, 
          name: 'reportStoreAlert', 
          text: 'Alerta de Tiendas',
          Component: StoreAlertReport, 
          icon: <FontAwesomeIcon icon={faLineChart} className='menu-icon'/>, 
          activeIcon: <FontAwesomeIcon icon={faLineChart} className='menu-icon-active'/>, 
          alerts: 0,
          isSubmenu: true
        },
        {
          path: paths.reportGeneral, 
          name: 'reportGeneral', 
          text: 'General',
          Component: Reports, 
          icon: <FontAwesomeIcon icon={faLineChart} className='menu-icon'/>, 
          activeIcon: <FontAwesomeIcon icon={faLineChart} className='menu-icon-active'/>, 
          alerts: 0,
          isSubmenu: true
        }
      ]
    });
    
  }

  if (securityManager.canSeeEntity() && (securityManager.role === 'ROLE_VENDOR')) {
    // Campañas
    routes.push(
      {
        path: paths.campaign,
        name: 'campaign',
        text: 'Campañas',
        Component: Campaign,
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>,
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    // Detalles de campañas
    routes.push(
      {
        path: paths.campaignDetails,
        name: 'campaignDetails',
        text: 'Detalle de Campaña',
        Component: CampaignDetails,
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>,
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    // Cotizaciones
    routes.push(
      {
        path: paths.quote,
        name: 'Quote',
        text: 'Cotizaciones',
        Component: Quote,
        icon: <FontAwesomeIcon icon={faThList} className='menu-icon'/>,
        activeIcon: <FontAwesomeIcon icon={faThList} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.quoteDetails,
        name: 'quoteDetails',
        text: 'Detalle de cotización',
        Component: QuoteDetails,
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>,
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    // Órdenes de trabajo
    routes.push(
      {
        path: paths.vendorWorkOrder,
        name: 'vendorWorkOrder',
        text: 'Órdenes de trabajo',
        Component: VendorWorkOrder,
        icon: <FontAwesomeIcon icon={faWrench} className='menu-icon'/>,
        activeIcon: <FontAwesomeIcon icon={faWrench} className='menu-icon-active'/>,
        alerts: 0
      }
    );

  }

  if (securityManager.canSeeEntity() && (securityManager.role === 'ROLE_CLIENT')) {
    // Dashboard
    routes.push(
      {
        path: paths.dashboard, 
        name: 'Home', 
        text: i18n.t('Home'),
        Component: Dashboard, 
        icon: <FontAwesomeIcon icon={faHome} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faHome} className='menu-icon-active'/>, 
        alerts: 0
      }
    );
    // Campañas
    routes.push(
      {
        path: paths.campaign, 
        name: 'campaign', 
        text: 'Campañas',
        Component: Campaign, 
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.campaignForm, 
        name: 'campaignForm', 
        text: 'Campaña Form',
        Component: CampaignForm, 
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.campaignFormEdit,
      name: 'campaignFormEdit',
      text: 'Editar Campaña',
      Component: CampaignForm,
      icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
      alerts: 0,
      hidden: true
    });
    // Detalles de campañas
    routes.push(
      {
        path: paths.campaignDetails, 
        name: 'campaignDetails', 
        text: 'Detalle de Campaña',
        Component: CampaignDetails, 
        icon: <FontAwesomeIcon icon={faGlobe} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faGlobe} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    // Cotizaciones
    routes.push(
      {
        path: paths.quote, 
        name: 'Quote', 
        text: 'Cotizaciones',
        Component: Quote, 
        icon: <FontAwesomeIcon icon={faThList} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faThList} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.quoteDetails, 
        name: 'quoteDetails', 
        text: 'Detalle de cotización',
        Component: QuoteDetails, 
        icon: <FontAwesomeIcon icon={faCog} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faCog} className='menu-icon-active'/>, 
        alerts: 0,
        hidden: true
      }
    );
    // Tiendas
    routes.push(
      {
        path: paths.store, 
        name: 'store', 
        text: 'Tiendas',
        Component: Store, 
        icon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon-active'/>,
        alerts: 0
      }
    );
    routes.push(
      {
        path: paths.storeForm, 
        name: 'storeForm', 
        text: 'Tienda Form',
        Component: StoreForm, 
        icon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon-active'/>,
        alerts: 0,
        hidden: true
      }
    );
    routes.push({
      path: paths.storeFormEdit,
      name: 'storeFormEdit',
      text: 'Editar Tienda',
      Component: StoreForm,
      icon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon'/>, 
      activeIcon: <FontAwesomeIcon icon={faShoppingBag} className='menu-icon-active'/>,
      alerts: 0,
      hidden: true
    });
    
    routes.push({
      path: paths.storeMaterials,
      name: 'storeMaterials',
      text: 'Materiales',
      Component: StoreMaterials,
      icon: <FontAwesomeIcon icon={faHome} className='menu-icon'/>,
      activeIcon: <FontAwesomeIcon icon={faHome} className='menu-icon'/>,
      alerts: 0,
      hidden: true
    });

    // Evidencias (Testigos)
    routes.push(
      {
        path: paths.evidences, 
        name: 'Evidences', 
        text: 'Evidencias',
        Component: Evidences, 
        icon: <FontAwesomeIcon icon={faFile} className='menu-icon'/>, 
        activeIcon: <FontAwesomeIcon icon={faFile} className='menu-icon-active'/>,
        alerts: 0
      }
    );
  }

  

  // Reporte - Alert Store

  return routes;
};

export default getAppRoutes;
