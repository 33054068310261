import React, {useCallback, useState, useContext, useEffect } from 'react';
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle';
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../components/Dashboard/TopBar/TopBar';
import Card from '../../../../components/Dashboard/Card/Card';
import { useParams } from 'react-router-dom';
import { getNotifier } from '../../../../services/notifier';
import { quoteSGroups } from '../../Quote/QuoteUtils';
import _ from 'lodash';
import "./CampaignQuotes.scss";
import { ApiContext } from '../../../../services/api/api-config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEye
} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/molecules/Button/Button';
import MaterialsModal from './components/MaterialsModal/MaterialsModal';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../services/routes/appRoutes';

export default function CampaignQuotes() {

  const { id } = useParams();
  const [quotes, setQuotes] = useState([])
  const [showQuoteModal, setShowQuoteModal] = useState(false)
  const [quoteSelected, setQuoteSelected] = useState(null)
  const api = useContext(ApiContext);
  const navigate = useNavigate()

  /**
   * Handle show quote modal
   */
  const showQuote = useCallback((quote) => {
    setShowQuoteModal(true);
    setQuoteSelected(quote);
  }, []);

  useEffect(() => {
    api.quotes.get({params: {'campaign.id': id, sGroups: quoteSGroups}}).then(response => {
      setQuotes(response);
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api, id, setQuotes])

  return (
    <div className='CampaignQuotes'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <SectionTitle className="m-0 p-0">
              Cotizaciones de la campaña por tienda
            </SectionTitle>
            <Button
              className={'btn-primary btn-outline'}
              onClick={() => navigate(paths.campaign)}
              design={'clear'}
            >
              Volver
            </Button>
          </div>

          <div className="row mt-3">
            <Card>
              {
                quotes.length === 0 &&
                <div className='d-flex justify-content-center'>
                  <h2>No hay cotizaciones</h2>
                </div>
              }
              {_.map(quotes, (quote) => (
                <div
                  className="col-12 d-flex justify-content-between mt-2 store-row"
                  key={quote.id}
                >
                  <div className='store-name'>
                    {quote.store.name}
                  </div>
                  <div className='store-icon'>
                    <FontAwesomeIcon
                      icon={faEye}
                      className='menu-icon cursor-pointer'
                      onClick={() => showQuote(quote)}
                      title="Ver Materiales"
                    />
                  </div>
                </div>
              ))}
            </Card>
          </div>
          {
            showQuoteModal &&
            <Modal
              title="Materiales"
              onClose={() => setShowQuoteModal(false)}
            >
              <div className='GrowerChangeStatusButtons__modal-content'>
                <div className='GrowerChangeStatusButtons__modal-content-title'>
                  <MaterialsModal
                    quote={quoteSelected}
                    onClose={() => setShowQuoteModal(false)}
                  ></MaterialsModal>
                </div>
              </div>
            </Modal>
          }
        </div>
      </div>
    </div>
  )
}
