import React from 'react';
import './Pagination.scss';
import {ReactComponent as NextPageIcon} from '../../../../../assets/images/icons/next-page.svg';
import {ReactComponent as BackPageIcon} from '../../../../../assets/images/icons/back-page.svg';

const defaultPageSizeOptions = [10,20,50,100];

const Pagination = ({reactTable, totalItems, pageSizeOptions=defaultPageSizeOptions}) => {
    const delta = 2; // number of page buttons to show on each side of the current page
    const pages = reactTable.pageCount;
    const pageIndex = reactTable.state.pageIndex + 0;
    const pageSize = reactTable.state.pageSize;
    const existsPrev = pageIndex > 0;
    const existsNext = pageIndex < (pages-1);

    const handlePageSizeChange = (e)=>{
        reactTable.setPageSize(Number(e.target.value));
    };

    let highestRowShown = pageIndex*pageSize+pageSize;
    if(highestRowShown > totalItems)
        highestRowShown = totalItems;

    if(!pageSizeOptions.includes( parseInt(reactTable.state.pageSize) ))
        pageSizeOptions = [ reactTable.state.pageSize, ...pageSizeOptions];

    const getRangeWithDots = () => {
        let current = pageIndex, last = pages-1, range = [];
        let rangeWithDots = [], l, left = current - delta, right = current + delta + 1;

        // visible range of pages
        for (let i = 0; i <= last; i++) {
            if (i >= (last-delta) || (i >= left && i < right)) {
                range.push(i);
            }
        }

        // add dots
        for (const i of range){
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        // if not exists dots in range
        if (!rangeWithDots.find(r => r==='...') && pages>1) {
            // dots on the left side
            if (rangeWithDots[0] !== 0) {
                //rangeWithDots.unshift(0);
                if (rangeWithDots[1] !== 1) {
                    rangeWithDots.unshift('...');
                }
            }
        }
        
        return rangeWithDots;
    }

    return (
        <div className={'TideReactTable-Pagination'}>
            <div className='page-size-block'>
                <span>Filas por página</span>
                <select value={pageSize} onChange={handlePageSizeChange} className='page-select'>
                    {pageSizeOptions.map(opt => <option value={opt} key={opt}>{opt}</option>)}
                </select>
            </div>

            <button className='pagination-btn pag-ctrl' disabled={!existsPrev}
                    onClick={()=>reactTable.gotoPage(pageIndex-1)}>
                <PageButton className={ existsPrev ? `pageButton` : `pageButton pageButton--disabled`}>
                    <BackPageIcon />
                </PageButton>
            </button>   

            {getRangeWithDots().map((i, page)=>{
                if( i!=='...' ){
                    return <button className='pagination-btn pag-ctrl'
                                    onClick={()=>reactTable.gotoPage(i)} key={i}>
                                <PageButton className={ (i===pageIndex) ? `pageButton pageButton--page pageButton--active` : `pageButton pageButton--page`} key={i}>
                                    {i+1}
                                </PageButton>
                            </button>
                } else {
                    return <button className='pagination-btn pag-ctrl' key={i}>
                            <PageButton className="pageButton pageButton--page pageButton--triangle" key={i}>
                                ...
                            </PageButton>
                        </button>
                }
            })}
            
            <button className='pagination-btn pag-ctrl'  disabled={!existsNext}
                    onClick={()=>reactTable.gotoPage(pageIndex+1)}>
                <PageButton className={ existsNext ? `pageButton` : `pageButton pageButton--disabled`}>
                    <NextPageIcon />
                </PageButton>
            </button>
        </div>
    );
};

const PageButton = ({children, ...props}) => {
    return <div {...props}>{children}</div>
}

export default Pagination;
