import React, {useState, useCallback, useContext, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { SideMenu } from '../../../../components/Dashboard/SideMenu/SideMenu';
import TopBar from '../../../../components/Dashboard/TopBar/TopBar';
import { SectionTitle } from '../../../../components/Dashboard/SectionTitle/SectionTitle';
import Button from '../../../../components/molecules/Button/Button';
import Card from '../../../../components/Dashboard/Card/Card';
import { paths } from '../../../../services/routes/appRoutes';
import TideEntitySelect from '../../../../components/utility/TideEntitySelect/TideEntitySelect';
import StoresByGroup from './components/StoresByGroup/StoresByGroup';
import { getNotifier } from '../../../../services/notifier';
import _ from "lodash";
import "./CampaignDetails.scss";
import { ApiContext } from '../../../../services/api/api-config';
import Modal from '../../../../components/Modal/Modal';
import AddMaterial from './components/AddMaterial/AddMaterial';
import AddStore from './components/AddStore/AddStore';
import {SecurityContext} from "../../../../services/SecurityManager";

export default function CampaignDetails() {

  const { id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [showAddStoreModal, setShowAddStoreModal] = useState(false);
  const [storeGroupsSelected, setStoreGroupsSelected] = useState([]);
  const [showQuoteButton, setShowQuoteButton] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const navigate = useNavigate();
  const security = useContext(SecurityContext);

  const api = useContext(ApiContext);

  const additionalFilters = {
    sGroups: ['store_read', 'store_read_store_group', 'store_group_read']
  };

  /**
   * Load store groups
   */
  const loadStoreGroups = useCallback(() => {
    if (storeGroupsSelected.length === 0) {
      api.storeGroups.get({
        params: {
          sGroups: [
            'store_group_read'
          ]
        }
      }).then(responseStoreGroups => {
        setStoreGroupsSelected(responseStoreGroups);
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.storeGroups, storeGroupsSelected.length]);

  /**
   * Get campaign Details
   */
  const campaignWithDetails = useCallback(() => {
    if (storeGroupsSelected.length === 0) {
      api.campaignDetails.get({
        params: {
          'campaign.id': id,
          sGroups: [
            'campaign_detail_read',
            'campaign_detail_read_store',
            'store_read',
            'store_read_store_group',
            'store_group_read'
          ]
        }
      }).then(response => {
        if (response.length > 0) {
          setShowQuoteButton(true);
          let storeGroupList = [];
          response.forEach(element => {
            const exists = storeGroupList.find(theStoreGroup => theStoreGroup.id === element.store.storeGroup.id);
            if (!exists) {
              element.store.storeGroup.hasDetails = true;
              storeGroupList.push(element.store.storeGroup);
            }
          });
          setStoreGroupsSelected(storeGroupList);
        } else {
          loadStoreGroups();
        }
      }).catch(
        error => getNotifier().error(error.detail || error.message)
      );
    }
  }, [api.campaignDetails, id, loadStoreGroups, storeGroupsSelected.length]);

  /**
   * Handle add material type to show modal
   */
  const onAddMaterialType = useCallback(() => {
    setShowAddMaterialModal(true);
  }, []);

  /**
   * Handle add store to show modal
   */
  const onAddStore = useCallback(() => {
    setShowAddStoreModal(true);
  }, []);

  /**
   * Handle select store group
   */
  const handleSelectStoreGroup = useCallback((storeGroup) => {
    let storeGroupsTemp = [...storeGroupsSelected];
    const exists = storeGroupsTemp.find(theStoreGroup => theStoreGroup.id ===  storeGroup.id);
    if (!exists) {
      storeGroup.hasDetails = false;
      storeGroupsTemp.push(storeGroup);
      setStoreGroupsSelected(storeGroupsTemp);
    }
  }, [storeGroupsSelected]);

  /**
   * Handle create quote (To Do)
   */
  const handleContinue = useCallback(() => {
    navigate(paths.campaignFiles.replace(':id', id))
  }, [id, navigate]);

  /**
   * Get campaign info
   */
  const getCampaignInfo = useCallback(() => {
    api.campaigns.get({id}).then(responseCampaign => {
      setCampaign(responseCampaign);
      campaignWithDetails();
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaigns, campaignWithDetails, id]);

  /**
   * Delete registers for store group in campaign details
   */
  const deleteStoreGroupInCampaign = useCallback(() => {
    api.campaignDetails.get({
      params: {
        'campaign.id': campaign.id,
        'store.storeGroup.id': idToDelete
      }
    }).then(responseDetails => {
      responseDetails.forEach(detail => {
        api.campaignDetails.delete({id: detail.id}).then(() => {
          getNotifier().success('Eliminado correctamente')
        }).catch(
          error => getNotifier().error(error.detail || error.message)
        );
      });
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
    setIdToDelete(0);
  }, [api.campaignDetails, campaign, idToDelete]);

  /**
   * Remove store group in list
   */
  const removeStoreGroup = useCallback(() => {
    let storeGroupsTemp = [...storeGroupsSelected];
    const theIndex = storeGroupsTemp.findIndex(theStoreGroup => theStoreGroup.id ===  idToDelete);
    storeGroupsTemp.splice(theIndex, 1);
    setStoreGroupsSelected(storeGroupsTemp);
    deleteStoreGroupInCampaign();
  }, [deleteStoreGroupInCampaign, idToDelete, storeGroupsSelected]);

  /**
   * Handle on remove store group
   */
  const onRemoveStoreGroup = useCallback((storeGroup) => {
    setIdToDelete(storeGroup.id);
  }, []);

  /**
   * Handle new store added
   */
  const newStoreAdded = useCallback(() => {
    setShowAddStoreModal(false);
    const currentStoreGroups = [...storeGroupsSelected];
    setStoreGroupsSelected(currentStoreGroups);
  }, [storeGroupsSelected]);
  
  //Don't show buttons for adding for ROLE_CLIENT and ROLE_VENDOR
  const onClientOrVendor = security.isClient() || security.isVendor();

  const notifyClient = useCallback(() => {
    api.campaigns.notifyClient({id: campaign.id}).then(() => {
      getNotifier().success('Cotización generada correctamente')
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaigns, campaign]);

  const handleCreateOrUpdateQuotes = useCallback(() => {
    api.campaigns.createQuote({id: campaign.id}).then(() => {
      notifyClient();
    }).catch(
      error => getNotifier().error(error.detail || error.message)
    );
  }, [api.campaigns, campaign, notifyClient]);

  const goToQuotes = useCallback(() => {
    navigate(paths.quoteDetails.replace(":id", id));
  }, [id, navigate]);

  useEffect(() => {
    if (!campaign) {
      getCampaignInfo();
    }
  }, [campaign, getCampaignInfo])
  
  return (
    <div className='CampaignDetails'>
      <SideMenu />
      <div className='right-content'>
        <TopBar />
        <div className='main-content'>
          <div className='col d-flex justify-content-between align-items-center gap-3 mt-4'>
            <div>
              <SectionTitle className="m-0 p-0">
                DETALLES DE LA CAMPAÑA
              </SectionTitle>
              <div className='subtitle'>
                Seleccionar tiendas y materiales
              </div>
            </div>
            <Button
              className={'btn-primary btn-outline'}
              onClick={() => window.location.href = paths.campaign }
              design={'clear'}
            >
              Volver
            </Button>
          </div>
          <div className="row mt-3">
            <Card>
              <div className='d-flex justify-content-between'>
                {
                  campaign &&
                  <h3>{campaign.name}</h3>
                }
                {!onClientOrVendor && (
                  <div className='d-flex gap-3'>

                    <Button
                      className={`btn-primary btnNextStep`}
                      design="clear"
                      onClick={onAddMaterialType}
                    >
                      + Nuevo Tipo de Material
                    </Button>
                    <Button
                      className={`btn-primary btnNextStep`}
                      design="clear"
                      onClick={onAddStore}
                    >
                      + Nueva Tienda
                    </Button>
                  </div>
                )}
              </div>
                <div className="row mt-4">
                  <div className="col">
                    <TideEntitySelect
                      entity='storeGroups'
                      className='stores-select'
                      placeholder='Selecciona la cadena de tiendas'
                      preload
                      onChange={handleSelectStoreGroup}
                      autoSelect={false}
                      filterBy="search"
                      additionalFilters={additionalFilters}
                    />
                  </div>
                </div>
              <div className="row">&nbsp;</div>
              <div>
                {
                  _.map(storeGroupsSelected, (storeGroup) => (
                    <StoresByGroup
                      hasDetails={storeGroup.hasDetails}
                      storeGroup={storeGroup}
                      key={storeGroup.id}
                      campaignId={campaign?.id}
                      removeStoreGroup={onRemoveStoreGroup}
                    ></StoresByGroup>
                  ))
                }
              </div>
              <div className="row">
                <div className="col mt-4">
                  {
                    showQuoteButton && !onClientOrVendor &&
                    <div className='d-flex justify-content-between'>
                      <div>
                        <Button
                          className={`btn-secondary btnNextStep`}
                          onClick={handleContinue}
                          design="secondary"
                        >
                          Agregar Archivos
                        </Button>
                      </div>
                      <div>
                        <Button 
                          className={`btn-secondary btnNextStep`}
                          onClick={handleCreateOrUpdateQuotes}
                          design="secondary"
                        >
                          Generar Cotizaciones
                        </Button>
                      </div>
                    </div>
                  }
                </div>
              </div>
              {
                showAddMaterialModal &&
                <Modal
                  title="Agregar Tipo de Material"
                  onClose={() => setShowAddMaterialModal(false)}
                >
                  <div className='GrowerChangeStatusButtons__modal-content'>
                    <div className='GrowerChangeStatusButtons__modal-content-title'>
                      <AddMaterial
                        onSuccess={() => setShowAddMaterialModal(false)}
                        storeId={id}
                      ></AddMaterial>
                    </div>
                  </div>
                </Modal>
              }
              {
                showAddStoreModal &&
                <Modal
                  title="Agregar Tienda"
                  onClose={() => setShowAddStoreModal(false)}
                >
                  <div className='GrowerChangeStatusButtons__modal-content'>
                    <div className='GrowerChangeStatusButtons__modal-content-title'>
                      <AddStore
                        onSuccess={(newStore) => {
                          newStoreAdded(newStore);
                        }}
                      ></AddStore>
                    </div>
                  </div>
                </Modal>
              }
              {
                idToDelete > 0 &&
                <Modal
                  title="Eliminar Grupo de Tienda"
                  onClose={() => setIdToDelete(0)}
                >
                  <div className='GrowerChangeStatusButtons__modal-content'>
                    <div className='GrowerChangeStatusButtons__modal-content-title'>
                      ¿Está seguro de Eliminar las Tiendas de este grupo?
                      <div className='mt-4'>
                        <Button 
                          className={`btn-primary btnNextStep`}
                          onClick={removeStoreGroup}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </div>
                  </div>
                </Modal>
              }
            </Card>
          </div>
          <div className="row mt-3">
            <Button 
              className={`btn-primary btnNextStep`}
              onClick={goToQuotes}
              design="clear"
            >
              Ir a la cotización
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
