import React, {useContext, useCallback} from 'react';
import "./ResetPassword.scss";
import {paths} from "../../../services/routes/notLoggedRoutes";
import {Link, useLocation} from "react-router-dom";
import useFormState from '../../../hooks/useFormState';
import {ApiContext} from '../../../services/api/api-config';
import useBoolean from '../../../hooks/useBoolean';
import HalfScreenLayout from "../../../components/layout/HalfScreenLayout/HalfScreenLayout";
import background from './../../../assets/images/backgrounds/Intersectlogin.jpg';
import PasswordInput from "../../../components/forms/PasswordInput/PasswordInput";
import Button from "../../../components/molecules/Button/Button";
import {useTranslation} from "react-i18next";
import logoPumaMostacho from '../../../assets/images/logos/logo_puma_mstch.png';

const ResetPassword = () => {
  const {form, handleInputChange, setForm} = useFormState({});
  const api = useContext(ApiContext);
  const {t} = useTranslation();
  const [loading, loadingOn, loadingOff] = useBoolean();
  const queryString = useLocation().search;
  const [changedPassword, setChangedPassword] = useBoolean();

  const resetPassword = useCallback((e) => {
      e.preventDefault();
      loadingOn();
      const token = new URLSearchParams(queryString).get("_token");
      api.users.resetPassword({params: {token: token, password: form.pass}})
        .then(() => {
          setChangedPassword();
          loadingOff();
          setForm({});
        });
  }, [api, form, queryString, setChangedPassword, loadingOn, loadingOff, setForm]);

  return (
    <HalfScreenLayout
      className='ResetPassword'
      rightBackground={background}
    >
      {
        [
          <div className='form-container text-center'>
            <div className="login-signin">
              <div className='row'>
                <div>
                  <img src={logoPumaMostacho} alt="" className='logo-img'/>
                </div>
              </div>
              {
                changedPassword &&
                <div className="registerResponse mb-4 text-success">
                  <h4>
                    Tu contraseña se ha cambiado correctamente, da click en el siguiente enlace para iniciar sesión
                  </h4>
                    <Link
                      to={paths.login}
                      className='links'
                    >
                      <h3 className='text-info mt-5 mb-5'>
                        Iniciar Sesión
                      </h3>
                    </Link>
                </div>
              }
              {
                !changedPassword &&
                <>
                  <div className="mb-20">
                    <h1 className='title text-center'>
                      Recupera tu contraseña
                    </h1>
                    <div className="subtitle">
                      Ingresa una nueva contraseña:
                    </div>
                  </div>
                  <form
                    className="form"
                    id="kt_login_signin_form"
                    onSubmit={resetPassword}
                  >
                    <div className="form-group mb-5">
                      <PasswordInput
                        label="Escribe tu nueva contraseña"
                        placeholder="Nueva contraseña"
                        onChange={handleInputChange('pass')}
                        value={form.pass || ''} 
                      />
                    </div>
                    <div className="form-group mb-5">
                      <PasswordInput
                        label="Confirma tu nueva contraseña"
                        placeholder="Confirma la nueva contraseña"
                        onChange={handleInputChange('confirmation')}
                        value={form.confirmation || ''} 
                      />
                    </div>
                    <Button
                      className={'submit'}
                      type='submit'
                      disabled={!form.pass || !form.confirmation || (form.pass !== form.confirmation) || loading}
                      design="secondary"
                    >
                      Enviar
                    </Button>
                  </form>
                </>
              }
            </div>
          </div>,
          <p
            key={'text'}
            className={'text-block'}
          >
            { t("We-believe-we-can-login-text") }
          </p>
        ]
      }
    </HalfScreenLayout>
  );
}

export default ResetPassword;