import { Link } from "react-router-dom";
import { paths } from "../../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faEdit
} from '@fortawesome/free-solid-svg-icons';


export const getCatVendorTableColumns = (
    {onRemoveClick}
  ) => [
    {
        Header: 'ID',
        accessor: vendor => 
        <span
            className='ActionSee d-flex justify-content-center align-items-center' 
        >
            {vendor.id}
        </span>
    },
    {
        Header: 'NOMBRE',
        accessor: vendor =>
            <span
                className='ActionSee d-flex justify-content-center align-items-center' 
            >
                {vendor.name}
            </span>
    },
    {
        Header: 'ACCIONES',
        accessor: vendor => 
        <span
            className='ActionSee d-flex justify-content-center align-items-center' 
        >
            <Link
                to={ paths.catVendorFormEdit.replace(":id", vendor.id) }
                className="m-2"
                title="Editar"
            >
                <FontAwesomeIcon icon={faEdit}/>
            </Link>
            <span
                onClick={() => {
                    onRemoveClick(vendor.id)
                }} 
                className="m-2 error cursor-pointer"
                title="Eliminar"
            >
                <FontAwesomeIcon icon={faTrash}/>
            </span>
        </span>
    }
];

export const catVendorTableSGroups = [
    'cat_vendor_read'
];