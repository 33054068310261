import { Link } from "react-router-dom";
import { paths } from "../../../../services/routes/appRoutes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit
} from '@fortawesome/free-solid-svg-icons';

export const getCatStoreGroupTableColumns = (
  {onRemoveClick}
) =>[
  {
    Header: 'ID',
    accessor: materialStatus => 
      <div className='text-center'>
        {materialStatus.id}
      </div>
  },
  {
    Header: 'NOMBRE',
    accessor: materialStatus => 
      <div className='text-center'>
        {materialStatus.name}
      </div>
  },
  {
    Header: 'ACCIONES',
    accessor: storeGroup => 
    <span
      className='ActionSee d-flex justify-content-center align-items-center' 
    >
      <Link
        to={ paths.catStoreGroupEdit.replace(":id", storeGroup.id) }
        className="m-2"
        title="Editar"
      >
        <FontAwesomeIcon icon={faEdit}/>
      </Link>
      <span
        onClick={() => {
            onRemoveClick(storeGroup.id)
        }} 
        className="m-2 error cursor-pointer"
        title="Eliminar"
      >
          <FontAwesomeIcon icon={faTrash}/>
      </span>
    </span>
  }
];

export const catStoreGroupTableSGroups = [
    'store_group_read'
];